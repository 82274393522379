.banner-container {
    background-image: url("https://hoc10.monkeyuni.net/upload/hoc10/thumb/Artboard_1_copy.png");
    background-repeat: round;
    min-height: 775px;
    /*text-align: center;*/
    margin: 0;
    padding: 0;
    /*font-family: 'Muli Black', sans-serif;*/
    font-family: "Muli Black", sans-serif;
}
.countdown {
    /*text-align: center;*/
    /*margin-top: 16px;*/
    line-height: 40px;
}

.styled .item-time {
    display: inline-block;
    margin-left: 10px;
    font-size: 61px;
    font-weight: 700;
    /*text-align: center;*/
    height: 100px;
    text-shadow: none;
    vertical-align: middle;
    color: #ffffff;
    margin: 200px -1px 10px 0;
    height: auto;
    background: rgba(0, 0, 0, 0);
    width: 136px;
    /*border: 1px solid #EEEEEE;*/
}

.book-appraisal-header {
    font-size: 32px;
    font-weight: bold;
    color: #5d4141;
    padding-top:30px
}

.book-appraisal-item {
    font-size: 25px;
    /*font-weight: bold;*/
    color: #5d4141;
}
