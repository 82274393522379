.OnePercentIntro {
  p {
    width: 50%;
    height: 50%;
    display: block;
  }

  p:nth-child(1) {
    border-right: 1px solid #eaeced;
    border-bottom: 1px solid #eaeced;
  }
  p:nth-child(2) {
    border-bottom: 1px solid #eaeced;
  }
  p:nth-child(3) {
    border-right: 1px solid #eaeced;
  }
}

@media (max-width: 668px) {
  .OnePercentIntro p {
    padding-bottom: 2px;
  }
}
