@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}
@media (max-width: 1300px) {
  header {
    .logo {
      margin-right: 14px;
    }
    .btn_search {
      margin: 0 16px;
    }
    .navbar {
      padding: 16px 0;
    }
    .login {
      .btn_register {
        width: 85px;
        height: 32px;
        margin-right: 5px;
      }
    }
  }
  .navbar-nav {
    > li {
      margin-right: 15px;
    }
  }
  .hoc10-slider {
    width: 100%;
    min-height: auto;
    padding: 50px 20px 40px 20px;
    .col-left,
    .col-right {
      width: 48%;
    }
    .col-left {
      margin-right: 4%;
    }
    .video {
      width: 100%;
      height: auto;
    }
  }
  .program .header {
    background-size: contain;
  }
  .hoc10-access {
    .box-access .img-decor {
      max-width: 45%;
      top: initial;
      bottom: 0;
      right: 5%;
    }
  }
}

@media (max-width: 1180px) {
  .hoc10-plan {
    .timeline {
      > * + *::before {
        width: 200px;
      }
      li:nth-child(2) {
        margin-left: 104px;
        &::before {
          width: 160px;
        }
      }
      li:nth-child(3) {
        margin-left: 58px;
      }
      li:nth-child(4) {
        margin-left: 22px;
      }
      li:nth-child(5) {
        margin-left: 36px;
      }
    }
    .statistical {
      padding: 43px 34px 33px 34px;
    }
  }
  .hoc10-feature {
    &--1 {
      .feature {
        margin: 0 5% 0 0;
      }
    }
    &--2 {
      .feature {
        margin: 0 0 0 5%;
      }
    }
    .feature {
      width: 45%;
    }
    .img-wrapper {
      width: 50%;
    }
  }
  .hoc10-register {
    .img-wrapper {
      &.lg {
        width: 340px;
      }
    }
  }
  .hoc10-intro {
    .intro {
      max-width: 750px;
      &__content {
        width: 40%;
        padding: 20px;
        left: -94px;
        bottom: initial;
        top: 40px;
      }
    }
  }
  .hoc10-newpaper {
    .slick-arrow {
      top: 20px;
    }
    .slick-next {
      right: 0;
    }
    .slick-prev {
      left: 0;
    }
  }
  .hoc10-team {
    .slick-next {
      right: 0px;
    }
    .slick-prev {
      left: 0px;
    }
  }
  .hoc10-bookaccess,
  .hoc10-banner-register {
    .img-decor {
      left: initial;
      right: 0;
      max-width: 55%;
    }
    .img-decor--cs3 {
      width: 510px;
      left: 460px;
    }
  }
  .hoc10-reader {
    .reader {
      width: calc(100% - 300px);
      padding: 20px;
    }
    .box-act {
      max-height: calc(100% - 40px);
      margin: 20px 0;
    }
  }
  .hoc10-intro-feature {
    .feature-list {
      justify-content: space-between;
      li {
        width: 320px;
      }
      li:nth-child(odd) {
        margin-right: initial;
      }
    }
  }
  .hoc10-test {
    .box-act {
      left: initial;
    }
    .box-question {
      max-width: calc(100% - 0px);
      margin-left: auto;
      padding: 16px 0 63px 0;
      position: relative;
    }
    .main-test {
      .box-question {
        max-width: calc(100% - 255px);
      }
    }
    .answer-list {
      width: calc(100% - 270px);
    }
  }
  .hoc10-guide {
    .support {
      justify-content: space-between;
      &__content,
      &__page {
        width: calc(100% - 380px);
      }
    }
  }
  .hoc10-target {
    .target {
      .content {
        max-width: 500px;
      }
      .icon {
        width: 170px;
      }
      .icon::after {
        width: 80px;
      }
    }
  }
  .hoc10-object {
    .object {
      &__item {
        max-width: 315px;
      }
    }
  }
  .hoc10-type-sgk {
    .sgk {
      &__img {
        width: 650px;
      }
      &__type {
        margin: 77px 0 0 25px;
      }
    }
  }
  .hoc10-lesson {
  }
  .hoc10-slideshow {
    .sidebar {
      width: 200px;
    }
    .main {
      &.is-hide {
        .content {
          width: 600px;
        }
      }
      .content {
        width: 645px;
      }
    }
    .list-slide {
      .img-wrapper {
        max-width: 72%;
      }
    }
  }

  .container {
    max-width: 100%;
    padding: 0 24px;
  }
  .decor-vd::after {
    top: -70px;
  }
  footer {
    .middle {
      .col1 {
        margin-right: 5%;
        width: 20%;
      }
      .col2,
      .col3 {
        margin-right: 5%;
        width: 15%;
      }
      .col4 {
        margin-right: 0;
        width: 35%;
      }
    }
  }

  .video {
    &__play {
      top: 40%;
      left: 40%;
    }
  }
}

@media (max-width: 1100px) {
  header {
    .btn-register {
      width: 96px;
      height: 28px;
      background: var(--orange);
      margin-right: 37px;
      border-radius: 6px;
      font: 12px var(--SVN-GilroyMedium);
      color: #fff;
      text-align: center;
    }
    .box-search {
      display: none;
      margin-top: 32px;
      max-width: 100%;
      position: relative;
      right: initial;
      .close {
        display: none;
      }
    }
    .navbar {
      padding: 0;
    }
    .navbar .container {
      padding: 0 16px;
    }
    .login .text a {
      display: block;
      text-align: right;
    }
    .progress-register > * + *::before {
      width: 126px;
    }
  }
  .navbar-toggler {
    display: block !important;
    width: 18px;
    height: 14px;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 9px;
    background: none;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    span {
      display: block;
      width: 18px;
      height: 2px;
      background: #2a292c;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.3s;
    }
    span:before,
    span:after {
      content: "";
      display: block;
      width: 18px;
      height: 2px;
      background: #2a292c;
      position: absolute;
      border-radius: 2px;
    }
    span:before {
      top: -7px;
    }
    span:after {
      width: 18px;
      bottom: -7px;
    }
  }
  .navbar-toggler.active {
    span {
      width: 18px;
      transform: rotate(45deg);
    }
    span:after {
      width: 21px;
    }
    span:before {
      top: 0;
      transform: rotate(90deg);
    }
    span:after {
      opacity: 0;
    }
  }
  .navbar {
    height: 48px;
    box-shadow: inset 0px -1px 0px #eaeced;
    .close {
      display: block;
    }
    .brand img {
      max-width: 175px;
    }
    &.scroll {
      padding: 12px 14px;
      .brand img {
        max-width: 175px;
      }
    }
    &.active {
      .navbar-collapse {
        left: 0;
      }
    }
    .close {
      display: block;
    }
  }
  .navbar-collapse {
    width: 100%;
    max-height: calc(100% - 48px);
    height: auto;
    display: block;
    box-shadow: 24px 24px 40px rgba(0, 0, 0, 0.04);
    padding: 0 16px;
    background: #fff;
    position: fixed;
    left: -100%;
    top: 48px;
    transition: left 0.2s linear;
    z-index: 999;
    overflow: auto;
    ul {
      overflow: auto;
    }
    .bottom {
      padding: 16px 0;
      justify-content: center;
      .language {
        width: 48%;
        margin: 0 2% 0 0;
        max-width: 164px;
        .dropdown-toggle::after {
          margin-left: auto;
        }
        .dropdown-menu {
          top: initial;
          left: 0;
          bottom: 45px;
        }
        button {
          width: 100%;
          height: 40px;
          padding: 0 12px;
          border: 1px solid #eaeced;
          border-radius: 6px;
        }
      }
      .btn-login {
        height: 40px;
        width: 48%;
        max-width: 164px;
        margin: 0 0 0 2%;
        border: 1px solid #ff7707;
        border-radius: 6px;
        font-family: var(--SVN-GilroyBold);
        color: var(--orange);
      }
    }
    .language {
      margin: 32px 0 0 0;
    }
    .btn_search,
    .btn_register {
      display: none;
    }
    .close {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/close.svg");
      position: absolute;
      top: 26px;
      right: 16px;
      opacity: 1;
    }
  }
  .navbar-nav {
    padding: 0;
    > li {
      display: block;
      padding: 16px 0;
      margin-right: 0;
      border-bottom: 1px solid #f4f5f6;
    }
    > li:nth-child(2) {
      position: relative;
    }
    > li > a {
      // font: 16px/24px var(--SVN-GilroyBold);
      padding: 0;
    }
    > li > a.active {
      color: var(--red);
    }
    // > li > .activated {
    //   padding: 7px 13px;
    //   width: 144px;
    // }
    > li > a::after {
      left: 0;
      transform: translateX(0);
      bottom: 7px;
    }
    > li:hover {
      .sub-menu,
      .sub-menu.product {
        display: none;
      }
      .show-menu {
        background: url("#{$img-path}/arr2.svg") no-repeat center center;
      }
      a {
        color: var(--dark);
        font-weight: 500;
      }
      a:after {
        display: none;
      }
    }
    > li.active {
      > a {
        color: var(--orange);
      }
      .sub-menu {
        display: block;
        background: transparent;
        &.product {
          display: flex;
        }
      }
      .show-menu {
        background: url("#{$img-path}/arr_up.svg") no-repeat center center;
      }
    }
    a:hover:before,
    a.active:before {
      width: 50px;
      bottom: 0;
    }
    .show-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/arr2.svg") no-repeat center center;
      position: absolute;
      right: 0;
      top: 15px;
      transition: 0.4s;
    }
    .sub-menu {
      width: auto;
      display: none;
      padding: 16px 0 0 24px;
      background: none;
      box-shadow: none;
      position: relative;
      top: 0;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      > * + * {
        margin-top: 16px;
      }
      .icon {
        margin-right: 8px;
      }
      li:last-child {
        padding-bottom: 0;
      }
      li {
        padding: 0;
      }
      li a {
        padding: 0;
        font-size: 14px;
        line-height: 20px;
      }
      &:before {
        display: none;
      }
    }
  }
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }

  //main
  main {
    padding-top: 48px;
  }
  .language {
    .dropdown-menu {
      top: 20px;
    }
  }
  .hoc10-slider {
    .col-left {
      margin-right: 2%;
    }
    h1,
    h2 {
      font-size: 40px;
      line-height: 50px;
      span {
        width: 213px;
        height: 64px;
        margin-left: -10px;
      }
    }
    .btn-discover {
      width: 180px;
      height: 50px;
      margin-top: 20px;
    }
    .slick-dots {
      margin-top: 30px;
    }
  }
  .hoc10-access {
    .box-access {
      height: 240px;
      .content {
        padding: 27px 0 0 35px;
      }
      .content::after {
        top: 20px;
        left: 294px;
      }
    }
  }
  .hoc10-feedback,
  .hoc10-posts {
    padding: 60px 0;
  }
  .hoc10-contact {
    .tab li {
      padding: 24px 14px;
    }
    .tab-content {
      .phone,
      .email {
        margin-right: 15px;
      }
    }
  }
  .hoc10-slideshow {
    .header {
      padding: 20px 0;
      height: auto;
    }
    .main {
      padding-bottom: 50px;
      height: auto;
      justify-content: center;
      align-items: flex-start;
      &.is-hide {
        .content {
          width: 100%;
        }
        .box-act {
          margin-left: 0;
        }
      }
      .content {
        order: 1;
        width: 100%;
        height: auto;
        padding: 24px;
      }
      .box-act {
        order: 2;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        border: 1px solid #eaeced;
        button {
          border-top: none;
          border-left: 1px solid #eaeced;
        }
        button:first-child {
          border-left: none;
        }
      }
      .sidebar {
        order: 3;
        background: #fff;
        &::after {
          display: none;
        }
      }
      .box-video {
        order: 3;
        width: 100%;
        margin-top: 24px;
        border: none;
        margin-left: 0;
      }
      .sidebar {
        width: 100%;
        height: auto;
        border: none;
        padding: 0;
      }
      h3 {
        margin-bottom: 18px;
      }
      h4 {
        margin-bottom: 12px;
      }
    }
    .list-slide {
      display: flex;
      margin-top: 35px;
      background: #fff;
      padding-bottom: 30px;
      &::-webkit-scrollbar {
        height: 4px;
      }
      .active {
        &::before {
          height: 4px;
          width: 97px;
          bottom: -8px;
          left: 5px;
          top: initial;
          transform: translateY(0);
        }
      }
      li:first-child {
        padding-top: 0;
      }
      li {
        padding: 0 4px;
        flex-direction: column-reverse;
      }
      .img-wrapper {
        width: 97px;
        max-width: 100%;
      }
    }
    .number {
      margin-top: 6px;
    }
    .box-btn {
      margin: 0 0 0 auto;
      button {
        background: #fffbf7;
        border: 1px solid #ff7707;
        color: var(--orange);
      }
      .icon {
        filter: brightness(1);
      }
      .icon-download {
        background: url("#{$img-path}/icon-download1.svg") no-repeat;
      }
      .icon-share {
        background: url("#{$img-path}/icon-share1.svg") no-repeat;
      }
      .icon-play {
        background: url("#{$img-path}/icon-play4.svg") no-repeat;
      }
    }
    .video-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      .video {
        width: 33.3%;
        padding: 8px 15px;
      }
    }
    .box-filter {
      margin-bottom: 8px;
      justify-content: space-between;
      .search-form,
      .box-select {
        width: 31%;
        margin: 0;
      }
    }
    .btn-hide,
    .btn-show {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

  //uikit
  .m-show-flex {
    display: flex;
  }
  .m-hidden {
    display: none;
  }
}

@media (max-width: 844px) {
  .video {
    &__play {
      top: 30%;
      left: 50%;
    }
  }
}

@media (max-width: 820px) {
  .hoc10-slider {
    width: calc(100% - 48px);
    margin: 0 auto;
    padding: 64px 0 40px;
    h1,
    h2 {
      margin-bottom: 24px;
      font-size: 54px;
      line-height: 64px;
      span {
        width: 253px;
        height: 73px;
      }
    }
    .col-left,
    .col-right {
      width: 100%;
    }
    .item {
      flex-direction: column-reverse;
    }
    .video {
      max-width: 83.5%;
      margin: 0 auto;
      z-index: 2;
      position: relative;
    }
    .col-left {
      margin-right: 0;
      margin-top: 56px;
      text-align: center;
    }
    .slider-home {
      position: relative;
      &::after {
        content: "";
        width: 307px;
        height: 464px;
        // background: url("#{$img-path}/decor-intro-tb.svg") no-repeat 0 0 /
        //   contain;
        position: absolute;
        right: 0;
        top: -56px;
        z-index: -1;
      }
    }
    p {
      margin: 0 auto;
      max-width: 476px;
    }
    .btn-discover {
      width: 200px;
      height: 60px;
      margin: 24px auto 0;
    }
    .slick-dots {
      margin-top: 56px;
    }
  }
  .hoc10-plan {
    .container {
      padding: 0;
    }
    .plan {
      padding-top: 195px;
      margin-top: -142px;
    }
    .header {
      margin: 0 auto 48px;
    }
    .timeline {
      padding: 0 0 30px 0;
      > * + *::before {
        width: 132%;
        transform: rotateY(0);
        top: 67px;
      }
      li {
        align-items: flex-start;
      }
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        margin-left: 10%;
        &::before {
          width: 132%;
        }
      }
      .class {
        display: block;
        > * + *::before {
          display: none;
        }
        span {
          display: block;
          text-align: center;
        }
      }
      .icon {
        margin-top: 16px;
      }
    }
    .statistical {
      margin-top: 40px;
      padding: 32px 48px;
      border-radius: 0;
      .item {
        display: block;
      }
      .icon {
        width: 48px;
        margin-bottom: 16px;
      }
      .number {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
        font-family: var(--SVN-GilroyRegular);
      }
    }
    &--cs1 {
      padding: 64px 0;
      .plan {
        padding-top: 0;
        margin-top: 0;
      }
      .timeline {
        padding-bottom: 0;
      }
    }
  }
  .hoc10-newpaper {
    padding: 64px 0;
    .header {
      max-width: 320px;
    }
    h2.title {
      font-size: 24px;
      line-height: 32px;
    }
    .newpaper-list {
      padding-top: 40px;
      background: none;
      &::after {
        width: 4px;
        height: 75%;
        left: 31px;
      }
    }
    .slick-slide {
      display: flex !important;
      padding: 0 0 40px;
      &:first-child {
        padding-top: 8px;
      }
    }
    .year {
      width: 53px;
      margin-left: 4px;
    }
    .content {
      width: calc(100% - 53px);
      padding: 0 0 0 24px;
    }
    .link,
    p {
      padding-right: 0;
    }
  }
  .hoc10-setting {
    padding: 24px 0 80px;
    .setting {
      max-width: 720px;
    }
  }
  .hoc10-access {
    .box-access {
      display: flex;
      align-items: center;

      .btn_access {
        width: 120px;
        height: 48px;
        border-radius: 6px;
      }
      .content {
        padding: 44px 0 0 24px;
        &::after {
          top: 135px;
          left: 204px;
        }
      }
      .img-decor {
        max-width: 54%;
        bottom: -8px;
        right: 0;
      }
    }
  }
  .hoc10-feature {
    &--1 {
      .flex {
        align-items: center;
      }
    }
    &--2 {
      padding: 64px 0;
      .feature {
        padding-top: 48px;
        margin-left: 0;
      }
    }
    .feature {
      width: 50%;
      margin: 0;
    }
    .img-wrapper {
      width: 50%;
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .hoc10-register {
    min-height: 588px;
    .img-wrapper {
      &.sm {
        top: 64px;
        left: 0px;
      }
      &.lg {
        width: 400px;
        top: 190px;
        bottom: initial;
        &::before {
          left: 100px;
          top: -77px;
        }
      }
    }
    .content {
      padding-top: 324px;
    }
    .post-list::after {
      top: 480px;
      right: 295px;
    }
  }
  .program {
    padding: 64px 0 88px;
    .header {
      max-width: 475px;
      margin: 0 auto 48px;
      background: none;
      position: relative;
      &::after {
        content: "";
        width: 616px;
        height: 168px;
        background: url("#{$img-path}/bg-title1-tb.svg") no-repeat 0 0 / contain;
        position: absolute;
        top: -30px;
        left: -40px;
      }
    }
    &__list {
      .item {
        padding: 0 16.5px;
        h4 {
          min-height: 56px;
        }
        p {
          min-height: 88px;
        }
        &__icon {
          margin-bottom: 28px;
        }
      }
    }
  }

  //intro
  .hoc10-object {
    padding: 64px 0 72px;
    .object {
      width: 100%;
      height: auto;
      &::after {
        width: 564px;
        height: 325px;
        background: url("#{$img-path}/decor-object-tb.svg") no-repeat 0 0 /
          contain;
        top: 110px;
        left: 60px;
      }
      &__avt {
        top: 175px;
      }
      &__item {
        max-width: 352px;
        margin-bottom: 0;
        &:nth-child(1) {
          margin-bottom: 340px;
        }
      }
    }
  }
  .hoc10-intro {
    background: url("#{$img-path}/bg-intro-m.svg") no-repeat 0 0/100%;
    .box-author {
      margin: 0 -39px 0;
      padding-top: 16px;
      h1 {
        margin-bottom: 58px;
      }
    }
    .intro {
      max-width: 100%;
      &::after {
        width: 758px;
        height: 257px;
        background: url("#{$img-path}/decor-intro1-tb.svg") no-repeat;
        right: 0;
        top: 427px;
        left: initial;
      }
      &__content {
        padding: 32px 24px;
        position: relative;
        top: 0;
        left: 0;
        margin: -40px auto 0;
        width: calc(100% - 48px);
      }
      &__book {
        position: relative;
        right: 0;
        bottom: 0;
        z-index: 2;
        margin: 36px auto 0;
        width: 255px;
      }
    }
    .text-intro {
      padding-bottom: 42px;
    }
    &--2 {
      .text-intro {
        p {
          max-width: 600px;
          margin: 0 auto;
        }
      }
    }
  }
  .hoc10-intro-feature {
    padding: 64px 0;
    .logo-h10 {
      top: 177px;
      width: 342px;
      height: 367px;
      border: none;
      border-radius: 0;
      background: url("#{$img-path}/bg-circle.svg") no-repeat 0 0 / contain;
    }
    .feature-list {
      li {
        width: calc(100% - 7.5px);
        padding: 13px 16px;
        margin-bottom: 8px;
      }
      li:nth-child(2) {
        margin-bottom: 357px;
      }
      li::after {
        width: 68px;
        height: 105px;
        background: url("#{$img-path}/line7-m.svg") no-repeat 0 0 / contain;
        display: none;
      }
      li:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
  .hoc10-vepic {
    padding: 72px 0;
    .intro-list {
      margin-top: 64px;
      padding-top: 40px;
      justify-content: center;
      .img-wrapper {
        width: 80px;
      }
      .col-lg-4 {
        margin-bottom: 48px;
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .video::before {
      bottom: -30px;
      left: mc70px;
    }
    .btn-viewmore {
      margin-top: 0;
    }
  }
  .hoc10-team--ex {
    padding: 64px 0;
    .header p {
      margin: 7px auto 0;
      max-width: 474px;
    }
  }
  .hoc10-team--exp {
    .item {
      padding: 56px 0 32px;
    }
  }
  .hoc10-onepercent {
    padding: 72px 0;
    .intro {
      margin-bottom: 40px;
    }
    .team {
      align-items: flex-start;
      h2.title {
        &-pc {
          display: none;
        }
        &-mobile {
          display: flex;
          span {
            margin-left: 5px;
          }
          span::after {
            margin: 2px 0 0 0;
          }
        }
      }
    }
    .btn-viewmore {
      margin: 32px 0 0;
    }
    .container::after {
      width: 163px;
      height: 409px;
      background: url("#{$img-path}/decor-one-tb.svg") no-repeat;
      top: initial;
      bottom: 30px;
      left: 520px;
    }
  }
  .hoc10-type-sgk {
    padding: 0 0 72px;
    .header {
      padding-top: 64px;
      margin-bottom: 64px;
    }
    .sgk {
      &__img {
        max-width: 100%;
        margin: 0 auto;
      }
      &__type {
        margin: 40px auto 0;
      }
    }
  }
  .hoc10-feature-sgk {
    padding: 64px 0;
    .header {
      margin-bottom: 48px;
    }
    .featured {
      margin-bottom: 40px;
    }
    .video {
      margin-top: 40px;
    }
  }
  .hoc10-program {
    padding: 64px 0;
    .subject {
      width: 33.33%;
      padding: 12px 7.5px;
    }
    .hoc10-form input {
      max-width: 500px;
    }
  }
  .hoc10-intro-sgk {
    padding-bottom: 16px;
    .header {
      padding: 65px 0 45px;
    }
    .video {
      padding: 18px;
      max-width: 598px;
    }
    .container::after {
      width: 737px;
      height: 461px;
      background: url("#{$img-path}/decor-gt4-tb.svg") no-repeat 0 0 / contain;
      left: 30px;
      top: -20px;
    }
    .intro-list {
      margin-top: 56px;
      padding-top: 0;
      border-top: none;
      .col-lg-4 {
        margin-bottom: 48px;
      }
    }
  }
  .hoc10-posts {
    .header {
      margin-bottom: 32px;
    }
    &.bg {
      padding: 64px 0;
    }
  }
  .hoc10-bookaccess,
  .hoc10-banner-register {
    min-height: 250px;
    .container {
      min-height: 250px;
    }
    .img-decor {
      right: 0;
      max-width: 51%;
      &::after {
        right: 45px;
        top: -5px;
      }
      img {
        z-index: 2;
        position: relative;
      }
    }
    .img-decor--cs1 {
      left: 50%;
      bottom: -5px;
    }
    .img-decor--cs3 {
      left: initial;
    }
    .content {
      max-width: 310px;
      padding: 58px 0 0 15px;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 32px;
      font-family: var(--SVN-GilroyBold);
      span {
        font-size: 36px;
        line-height: 48px;
      }
      &::after {
        @include line8;
        top: 31px;
        position: absolute;
      }
    }
    .btn-access {
      width: 200px;
      height: 44px;
      &::after {
        top: -12px;
      }
    }
    .btn-login {
      width: 164px;
      height: 44px;
    }
  }
  .hoc10-banner-register {
    .img-decor--cs2 {
      max-width: 57%;
      left: 46%;
      bottom: -23%;
    }
  }
  .hoc10-team {
    padding: 64px 0;
  }
  .hoc10-target {
    padding: 48px 0 64px;
    .target-list::after {
      display: none;
    }
    .target {
      max-width: 600px;
      margin: 0 auto 48px;
      .icon {
        width: 48px;
        margin: 0 auto 16px;
      }
      .icon::after {
        display: none;
      }
      .content {
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
    &::after,
    &::before {
      content: "";
      display: block;
      margin: 0 auto;
    }
    &::before {
      width: 240px;
      height: 217px;
      margin-bottom: 40px;
      background: url("#{$img-path}/img-dc4.svg") no-repeat 0 0 / contain;
    }
    &::after {
      width: 212px;
      height: 222px;
      background: url("#{$img-path}/img-dc4.svg") no-repeat 0 0 / contain;
    }
  }
  .hoc10-intro-video {
    padding: 48px 0 96px;
    .text {
      max-width: 100%;
    }
    .video {
      max-width: 598px;
      margin: 80px auto 0;
      &::after {
        width: 684px;
        height: 416px;
        background: url("#{$img-path}/decor-vd-tb.svg") no-repeat 0 0 / contain;
      }
    }
  }
  .hoc10-sgk {
    padding: 64px 0;
    margin-top: 64px;
    .container {
      max-width: 100%;
    }
    .item {
      padding: 50px 7.5px;
      .bg-sh {
        padding: 16px;
      }
    }
    .slick-next {
      right: -15px;
    }
    .slick-prev {
      left: -15px;
    }
  }
  .intro-list {
    p {
      max-width: 475px;
      font-size: 16px;
    }
  }

  //onepercent
  .onepercent-value {
    padding: 56px 0 120px;
    .text {
      max-width: 598px;
      margin: 0 auto 64px;
      text-align: center;
    }
    .img-wrapper {
      max-width: 596px;
      margin: 0 auto;
    }
  }
  .onepercent-develop {
    padding: 64px 0;
    .project {
      &.monkey {
        margin-bottom: 56px;
        flex-direction: column-reverse;
        .project__logo {
          margin-top: 40px;
        }
        h4 {
          margin-bottom: 12px;
        }
      }
      &.hoc10 {
        flex-direction: column-reverse;
        .project__logo {
          margin-top: 32px;
        }
      }
      &__content {
        width: 100%;
        max-width: 598px;
        margin: 0 auto;
        padding-left: 0;
        text-align: center;
      }
      &__logo {
        margin: 32px auto 0;
      }
      p {
        max-width: 100%;
        font-size: 15px;
      }
    }
  }
  .onepercent-office {
    padding: 56px 0 96px;
    .img-wrapper {
      max-width: 511px;
      margin-top: 24px;
    }
  }

  //register
  .box-register {
    margin-top: 24px;
    .btn-next {
      // max-width: 475px;
      width: 650px;
    }
    .object {
      width: calc(45% - 16px);
    }
  }

  //tusach
  .hoc10-search {
    .header,
    .search-form {
      max-width: 598px;
    }
    .header::after {
      width: 486px;
      height: 102px;
      background: url("#{$img-path}/decor-ts-tb.svg") no-repeat 0 0 / contain;
      left: 50px;
    }
  }
  .result {
    .post {
      margin-bottom: 16px;
      &__content {
        padding: 12px;
      }
      .btn-read {
        position: relative;
        width: 100%;
        margin-top: 8px;
        top: 0;
        right: 0;
        background: var(--orange);
        color: #fff;
      }
    }
    .post-training {
      .post__thumb {
        height: 172px;
      }
    }
    .fil {
      &.fil--search {
        width: 100%;
      }
      &.fil--sort {
        width: 100%;
        margin-top: 6px;
      }
      &__item {
        padding: 6px 4px;
      }
    }
  }

  //student
  .hoc10-ft {
    &--1,
    &--t1 {
      padding: 48px 0 80px;
      .feature-list {
        li {
          width: 50%;
          margin-bottom: 56px;
        }
        li:nth-child(2),
        li:nth-child(3) {
          h4 {
            max-width: 215px;
          }
        }
        h4 {
          max-width: 255px;
          margin: 0 auto;
        }
      }
      .video {
        margin-top: 50px;
        max-width: 720px;
        padding: 22px;
      }
    }
    &--2,
    &--t2 {
      padding: 64px 0;
      .img-wrapper {
        margin: 38px auto 0;
        max-width: 100%;
      }
      .col-lg-7::before {
        top: 0;
      }
    }
    &--3,
    &--t3 {
      padding: 64px 0;
      .flex-lc {
        flex-direction: column-reverse;
      }
      .img-wrapper {
        max-width: 100%;
        margin: 59px auto 0;
        &::after {
          left: -45px;
          bottom: -37px;
        }
      }
    }
    &--4,
    &--t4 {
      padding: 64px 0;
      .video {
        max-width: 100%;
        margin: 43px auto 0;
        img {
          width: 100%;
        }
      }
      .decor-vd::after {
        right: -20%;
      }
      .statistical {
        margin-top: 66px;
        .number {
          font-size: 32px;
          line-height: 36px;
        }
        .text {
          font-size: 18px;
          line-height: 26px;
        }
        .icon {
          margin-right: 16px;
        }
      }
    }
  }

  //luyentap
  .hoc10-overview {
    padding: 64px 0;
    .box-video {
      max-width: 100%;
      margin-top: 80px;
      &::after {
        width: 126%;
        height: 126%;
        top: -14%;
        right: -20%;
      }
      .video {
        max-width: 100%;
      }
    }
  }
  .hoc10-library {
    padding: 64px 0 12px;
    .library-list {
      margin-top: 60px;
    }
    .library-list.slider {
      margin: 48px 0 0;
    }
    .slick-next {
      right: -10px;
    }
    .slick-prev {
      left: -10px;
    }
  }
  .hoc10-practice-assigned,
  .hoc10-practice-ex {
    padding: 64px 0;
    .header {
      margin-bottom: 29px;
    }
    .practice-list {
      padding: 0 4px;
    }
    .col-lg-4 {
      padding: 0 7.5px;
    }
  }
  .practice {
    padding: 16px;
    &__img {
      height: 110px;
    }
    &__content {
      padding-top: 16px;
    }
    h3 {
      margin-bottom: 2px;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
    .progress {
      margin: 4px 0 16px;
    }
    .time {
      font-size: 10px;
      line-height: 16px;
      background-position: 0 0;
    }
    .btn-pr {
      width: 113px;
    }
  }

  //reader
  .hoc10-reader {
    .header {
      padding: 0 24px;
      .btn-back {
        span {
          display: none;
        }
        i {
          margin-right: 0;
        }
      }
      .time {
        font-size: 12px;
        line-height: 18px;
      }
      .time p {
        margin-right: 4px;
      }
      .time span {
        display: none;
      }
    }
    .sidebar {
      height: calc(100vh - 112px);
      position: fixed;
      top: 56px;
      left: -230px;
      transition: left 0.2s linear;
      z-index: 2;
      &.show {
        left: 0;
      }
    }
    .main {
      display: block;
    }
    .reader {
      width: 100%;
      height: calc(100% - 56px);
      padding: 24px 24px 37px;
    }
    .box-act {
      > * + * {
        margin: 0 0 0 8px;
      }
      margin: 0;
      max-width: 536px;
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      height: 56px;
      &::-webkit-scrollbar {
        height: 3px;
      }
    }
    .footer {
      .act {
        &--1 {
          width: auto;
          padding-left: 16px;
          border-right: none;
          .btn-menu {
            margin-right: 16px;
          }
        }
        &--3 {
          padding: 0;
          border-right: none;
          border-left: none;
          margin-left: auto;
          .btn-reset {
            margin-left: 16px;
          }
        }
        &--2 {
          width: auto;
          margin-left: auto;
        }
        &--4 {
          padding: 0 16px 0 16px;
        }
        &__tl {
          width: 180px;
          height: 60px;
          padding: 12px;
          display: none;
          background: url("#{$img-path}/bg-tl.svg") no-repeat;
          position: absolute;
          right: -8px;
          top: -53px;
          &.show {
            display: flex;
          }
        }
        .scale .value {
          width: 60px;
          color: var(--black);
        }
      }
      .btn-setting {
        display: block;
      }
    }
  }

  .box-login {
    margin: 24px 0 32px;
    .login-list {
      margin: 0 -8px;
      a {
        width: 148px;
        margin: 0 8px;
        font-size: 12px;
      }
      .icon {
        margin-right: 2px;
      }
    }
  }
  .form-h10 {
    .box-btn {
      a,
      button {
        max-width: calc(50% - 8px);
      }
    }
    .text {
      max-width: 404px;
      margin-top: 24px;
    }
  }
  .hoc10-contact,
  .hoc10-information {
    .col-3 {
      padding-right: 8px;
      max-width: 25%;
    }
    .col-9 {
      padding-left: 8px;
      max-width: 78%;
    }
  }
  .hoc10-contact {
    padding-bottom: 40px;
    .tab-content {
      padding: 30px 24px;
      .phone,
      .email {
        margin: 0 0 7px 0;
        width: 100%;
        max-width: 210px;
      }
      .email {
        margin-left: 23px;
      }
    }
  }
  .hoc10-tab {
    li {
      padding: 24px 19px;
    }
    .active {
      font-family: var(--SVN-GilroyBold);
    }
  }

  .form-h10 {
    /*   .form-row .form-group{width: 100%;}*/
    .box-btn-fw {
      margin-top: 8px;
      a,
      button {
        width: calc(50% - 8px);
      }
    }
    .btn-submit {
      width: 100%;
    }
    &.mw-535 {
      max-width: 475px;
    }
  }
  .modal-success {
    .modal-content {
      padding: 24px 16px;
      .text {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }
  }
  .modal-login {
    .modal-content {
      padding: 24px 16px;
      overflow: scroll;
      height: 900px;
      .title {
        margin-bottom: 16px;
      }
    }
    form {
      .register {
        margin: 16px 0;
      }
    }
    .close {
      top: 16px;
      right: 16px;
    }
  }

  //luyentap
  .hoc10-title {
    padding-bottom: 64px;
    .header p {
      max-width: 438px;
    }
    .subject {
      &__icon {
        width: 84px;
        height: 84px;
        img {
          width: 47px;
        }
      }
      &__info {
        width: calc(100% - 84px);
        padding-left: 24px;
      }
      h1.title {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        max-width: 226px;
      }
    }
    .subject--cs1 {
      h5 {
        margin-bottom: 8px;
      }
      h1.title {
        margin-bottom: 16px;
        font-size: 36px;
        line-height: 48px;
      }
      p {
        max-width: 300px;
      }
      .subject__info {
        width: calc(100% - 152px);
      }
      .subject__icon {
        width: 152px;
        height: 152px;
        img {
          width: 92px;
        }
      }
    }
    .img-wrapper {
      width: 148px;
      bottom: 0;
      right: 80px;
    }
  }
  .hoc10-practice-list {
    padding: 23px 0 64px;
    .table-of-content {
      .header {
        padding: 18px 16px;
      }
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
      ul {
        li {
          padding: 16px;
        }
        h4,
        .percent {
          font-size: 14px;
          line-height: 22px;
        }
        .progress {
          margin-top: 8px;
        }
        h4,
        .title {
          &::before {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .chapter,
  .lesson {
    .header {
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  .chapter {
    .header {
      padding: 12px 16px;
    }
    .question {
      padding: 16px;
      li {
        width: 100%;
      }
    }
  }
  .lesson {
    .header {
      h3 {
        max-width: calc(100% - 110px);
      }
      .btn-result,
      .btn-play {
        width: 103px;
        height: 32px;
      }
    }
    .Knowledge {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #eaeced;
      h4 {
        flex-basis: 100%;
      }
      a {
        margin: 0 6% 0 0;
      }
    }
    .excrise {
      li {
        padding: 4px 8px;
      }
      a {
        padding: 9px 32px 9px 8px;
        font-size: 12px;
        line-height: 18px;
        border: 1px solid #eaeced;
      }
      .icon-pen {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .finish::after {
        width: 16px;
        height: 16px;
        right: 11px;
      }
    }
    .Knowledge,
    .excrise {
      width: 100%;
      padding: 16px;
    }
  }

  //teacher
  .hoc10-new-training {
    padding: 64px 0;
    .news {
      &__thumb {
        margin-bottom: 50px;
      }
      .btn-viewall {
        width: 178px;
        height: 40px;
      }
    }
  }
  .hoc10-doc-training,
  .hoc10-check-training {
    padding: 64px 0;
  }
  .hoc10-check-training {
    .header {
      margin-bottom: 24px;
    }
    .row {
      flex-direction: column-reverse;
    }
    .content {
      max-width: 100%;
      margin-left: 0;
      p {
        font-size: 18px;
        line-height: 26px;
        color: #252527;
      }
    }
    .box-time {
      margin-left: 0;
      .btn-play {
        margin-top: 32px;
      }
    }
    .img-wrapper {
      margin: 40px auto 0;
    }
  }
  .hoc10-doc-training {
    .result .col-lg-4 {
      padding: 0 8px;
    }
  }

  //test
  .hoc10-test {
    .question,
    .answer {
      width: 100%;
    }
    .question {
      padding: 20px 20px 0;
      border-right: none;
      &__content {
        max-width: 100%;
        margin-bottom: 16px;
      }
      &__img {
        display: none;
      }
    }
    .answer {
      padding: 0 20px 20px;
      &__list {
        max-width: 326px;
      }
      .box-btn {
        margin-top: 45px;
        justify-content: flex-start;
      }
    }
    .box-question {
      max-width: calc(100% - 0px);
      .img-wrapper {
        display: block;
        margin: 15px auto 0;
      }
    }
    .check {
      left: initial;
      right: 0;
      transform: translateX(0);
      &::after {
        left: initial;
        transform: translateX(0);
        right: 15px;
      }
    }
    .main-test {
      padding: 0;
      .question,
      .answer {
        width: 100%;
      }
      .question {
        padding: 24px 24px 8px;
      }
      .box-question {
        max-width: calc(100% - 230px);
        padding-left: 15px;
      }
    }
    .list-question {
      width: 230px;
    }
    .sidebar {
      width: 230px;
    }
    .answer-list {
      width: calc(100% - 245px);
      .item {
        margin-bottom: 16px;
      }
      .box-answer {
        li {
          max-width: 100%;
          margin: 7px 0;
        }
      }
    }
    .box-qs-list {
      ul {
        margin: 0 -7px;
        li {
          margin: 5px;
        }
      }
    }
  }
  .hoc10-library-test {
    padding: 64px 0 40px;
    .header {
      margin-bottom: 32px;
    }
    .item {
      padding: 16px;
      margin-bottom: 24px;
    }
    h3 {
      font-size: 18px;
      line-height: 26px;
    }
    a {
      font-size: 14px;
      line-height: 22px;
    }
    .btn-viewmore {
      width: 24px;
      height: 24px;
      right: 20px;
    }
  }
  .hoc10-background-decor {
    .img-wrapper {
      max-width: 100%;
      margin: 0 auto;
      img {
        margin-left: 40px;
      }
    }
  }
  .hoc10-practice-recently {
    padding: 64px 0 24px;
    .header {
      margin-bottom: 32px;
    }
  }
  .hoc10-homework {
    padding: 24px 0 64px;
  }
  .practice {
    margin-bottom: 24px;
  }

  //hotro
  .hoc10-question {
    padding: 64px 0 52px;
    .header {
      margin-bottom: 8px;
    }
    .item {
      margin: 8px 0;
      width: calc(50% - 8px);
      padding: 16px;
    }
  }
  .hoc10-guide {
    padding: 64px 0;
    .accordion {
      padding: 0 16px;
    }
    .support {
      justify-content: space-between;
      &__list {
        width: 290px;
        h3 {
          padding: 16px;
        }
      }
      &__content,
      &__page {
        width: calc(100% - 306px);
      }
    }
    .tab-content {
      padding: 24px 16px;
    }
    .pagedetail {
      h3 {
        padding: 20px 16px;
      }
      article {
        padding: 16px 16px 8px;
      }
      .feedback {
        padding: 16px;
        p {
          width: 100%;
          margin: 0 0 16px;
        }
      }
    }
  }
  .hoc10-contactus {
    padding: 64px 0 52px;
    .btn-contact {
      margin-bottom: 28px;
    }
    ul {
      max-width: 440px;
      margin: 0 auto;
      justify-content: space-between;
      li {
        width: 40%;
        margin: 12px 0;
      }
    }
  }
  .hoc10-news {
    .col-lg-8 {
      padding-right: 8px;
    }
    .col-lg-4 {
      padding-left: 8px;
    }
    .post--highlight {
      .post__thumb {
        height: 235px;
        margin-bottom: 12px;
      }
      .post__content {
        h3 a {
          font-size: 20px;
          line-height: 28px;
        }
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }
      }
    }
    .readmany {
      h4 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .article {
      h1.title {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 28px;
      }
      p {
        margin-bottom: 16px;
      }
      figure {
      }
      article {
        margin-top: 16px;
      }
    }
    .related {
      padding-top: 48px;
      margin-top: 48px;
    }
  }
  .post-ty3 {
    h3 a {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .post-ty2 {
    .post__thumb {
      width: 145px;
    }
    .post__content {
      width: calc(100% - 145px);
      padding-left: 12px;
    }
    h3 {
      margin-bottom: 4px;
    }
    h3 a {
      font-size: 14px;
      line-height: 20px;
    }
    p {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .hoc10-blog {
    .blog-highlight {
      padding-bottom: 35px;
      margin-bottom: 26px;
      .post__thumb,
      .post__content {
        width: 100%;
        padding-left: 0;
      }
      .post__thumb {
        margin-bottom: 33px;
      }
      .post .btn-viewmore {
        margin-top: 24px;
      }
    }
    .tab-slider-blog {
      padding-right: 0;
      a {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .blog-list {
      padding: 24px 0 35px;
      .post {
        margin-bottom: 16px;
      }
    }
  }
  .hoc10-blog-detail {
    .summary {
      padding-bottom: 32px;
      margin-bottom: 16px;
      &__img {
        width: 100%;
      }
      &__content {
        width: 100%;
        padding: 16px 0 0;
        .category {
          margin-bottom: 8px;
        }
      }
    }
    .post-detail {
      padding-bottom: 24px;
      .col-left,
      .col-right,
      .col-middle {
        max-width: 100%;
        width: 100%;
      }
      .col-left,
      .col-right {
        position: relative;
        top: 0;
      }
      .col-right {
        padding-top: 24px;
        border-top: 1px solid #e9e9e9;
      }
      .col-middle {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #e9e9e9;
      }
      article {
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
    .share {
      margin-left: auto;
    }
    .menu-post {
      h4 {
        margin-bottom: 10px;
        padding-bottom: 16px;
      }
      li {
        padding: 6px 0;
      }
    }
    .email-register {
      width: 100%;
      margin: 24px auto 36px;
      background: url("#{$img-path}/bg-register1.svg") no-repeat 0 0 / contain;
      form {
        max-width: 475px;
        margin: 0 auto;
      }
      h4 {
        max-width: 375px;
        margin: 0 auto 20px;
        text-align: center;
      }
      h4::after {
        width: 77px;
      }
    }
    .related-post {
      h2.title {
        margin-bottom: 24px;
      }
      .post {
        margin-bottom: 16px;
      }
    }
  }
  .post-blog {
    .category {
      margin-bottom: 8px;
    }
  }

  //baigiang
  .hoc10-lesson {
    padding: 64px 0 88px;
    .lesson-list {
      margin: 0 -10px;
    }
    .item {
      padding: 24px 10px 40px;
    }
    .slick-arrow {
      transform: translateY(0);
      top: initial;
      bottom: -24px;
    }
    .slick-next {
      right: 10px;
    }
    .slick-prev {
      left: 10px;
      transform: translateY(0) rotate(180deg);
    }
  }
  .hoc10-lesson-library {
    .header {
      h2.title {
        margin-bottom: 24px;
      }
    }
    .box-select {
      margin-right: 12px;
    }
    .search-form {
      width: calc(100% - 344px);
    }
    .col-md-6 {
      padding: 0 10px;
    }
    .row {
      margin: 0 -10px;
    }
  }

  //timkiem
  .hoc10-page-search {
    .box-404 {
      p {
        max-width: 700px;
      }
    }
  }

  .post-ty4 {
    margin-bottom: 16px;
    .post__content {
      padding: 12px;
    }
    .btn-read {
      position: relative;
      width: 100%;
      margin-top: 8px;
      top: 0;
      right: 0;
      background: var(--orange);
      color: #fff;
    }
  }

  footer {
    .info {
      padding: 40px 0;
      .col-left {
        margin-bottom: 32px;
      }
      .col-left,
      .col-right {
        width: 100%;
        padding: 0;
      }
      .col-right {
        > * + * {
          margin-top: 12px;
        }
        p {
          font-size: 14px;
        }
      }
      h2 {
        margin-bottom: 8px;
        line-height: 28px;
      }
      .icon-phone {
        margin-right: 8px;
      }
      .hotline a {
        font: 20px/28px var(--SVN-GilroyBold);
      }
    }
    .logo-white {
      margin: 40px 0;
      width: 160px;
    }
    .icon-phone {
      background: url("#{$img-path}/icon-phone.svg") no-repeat 0 0 / contain;
    }
    .middle {
      align-items: flex-start;
      .menu {
        margin-bottom: 40px;
        > * + * {
          margin-top: 18px;
        }
      }
      h3 {
        line-height: 22px;
      }
      li a {
        line-height: 22px;
      }
      .col1,
      .col2 {
        margin: 0;
      }
      .col1 {
        width: 33.3%;
        order: 1;
      }
      .col3 {
        width: 33.3%;
      }
      .col2 {
        width: 33.3%;
        order: 3;
        .col-group {
          width: 100%;
        }
      }
      .col4 {
        width: 100%;
        margin: 0;
        order: 4;
      }
      .col3 {
        margin-right: 0;
        order: 2;
      }
    }
    .service {
      p {
        margin-bottom: 12px;
      }
      .box-btn {
        margin-bottom: 12px;
        > * + * {
          margin-left: 16px;
        }
        a {
          // padding: 11px 19px;
        }
      }
    }
    .bottom {
      margin-top: 40px;
      padding: 0 16px;
      .container {
        padding: 24px 0;
        border-top: 1px solid #2a404f;
      }
      .copyright {
        max-width: 63%;
      }
    }
  }

  //uikit
  .decor-h1::after {
    width: 682px;
    height: 112px;
    background: url("#{$img-path}/decor-hs1.svg") no-repeat 0 0 / contain;
    left: 20px;
    top: -5px;
  }
  .decor-h2::after {
    width: 651px;
    height: 175px;
    background: url("#{$img-path}/bg-intro1-tb.svg") no-repeat 0 0 / contain;
    left: 30px;
  }
  .decor-h3::after {
    width: 725px;
    height: 175px;
    background: url("#{$img-path}/decor-gt1-tb.svg") no-repeat 0 0 / contain;
    left: -10px;
    top: 0;
  }
  .decor-vd::after {
    width: 126%;
    height: 126%;
    top: -14%;
    right: -20%;
  }
  .txt-5 {
    font-size: 14px;
    line-height: 22px;
  }
  .txt-2 {
    font-size: 18px;
    line-height: 26px;
  }

  .video {
    &__play {
      top: 40%;
      left: 40%;
    }
  }
}

@media (max-width: 668px) {
  @mixin decorintrom {
    width: 375px;
    height: 344px;
    background: url("#{$img-path}/intro-gt-m.svg") no-repeat 0 0 / contain;
  }
  .container {
    padding: 0 16px;
  }
  header {
    .login .text a {
      font-size: 12px;
      line-height: 18px;
    }
    .logo-m {
      display: block;
      margin-right: 35px;
    }
    .logo-pc {
      display: none;
    }
    .progress-register {
      > * + *::before {
        width: 46px;
      }
      span {
        width: 20px;
        height: 20px;
        font-size: 12px;
        border-width: 1.5px;
      }
    }
    .login {
      .text span {
        display: none;
      }
    }
    &.sh .navbar {
      height: 48px;
    }
  }
  .hoc10-slider {
    width: calc(100% - 32px);
    padding: 42px 24px 32px;
    background: url("#{$img-path}/bg-slider-m.svg") no-repeat 0 0/100% 100%;
    border-radius: 12px;
    .slider-home::after {
      display: none;
    }
    .item {
      flex-direction: column;
    }
    .col-left,
    .col-right {
      width: 100%;
      margin-right: 0;
    }
    .col-left {
      margin: 0 0 50px 0;
      text-align: left;
    }
    h1,
    h2 {
      max-width: 287px;
      margin-bottom: 7px;
      font-size: 36px;
      line-height: 48px;
      span {
        width: 175px;
        height: 57px;
        margin: 2px 0 0 -10px;
        line-height: 1.2;
      }
    }
    .btn-discover {
      width: 160px;
      height: 40px;
      margin: 32px 0 0 0;
    }

    .video {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 11.6px;
      &__play {
        width: 64px;
      }
    }
    .slick-dots {
      margin-top: 30px;
    }
  }
  .program {
    padding: 40px 0;
    .header {
      margin-bottom: 32px;
      background: url("#{$img-path}/bg-title1-m.svg") no-repeat top center;
      background-size: contain;
      &::after {
        display: none;
      }
    }
    h2.title {
      max-width: 284px;
      margin: 0 auto;
      span::after {
        width: 86px;
        bottom: -6px;
      }
    }
    &__list {
      margin: 0;
      .item {
        width: 100%;
        max-width: 300px;
        padding: 0;
        margin: 0 auto 40px;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
        &__icon {
          width: 72px;
          margin: 0 auto 16px;
        }
        h4 {
          min-height: auto;
        }
        p {
          min-height: auto;
        }
      }
      .btn_viewmore {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .hoc10-access {
    .box-access {
      height: auto;
      display: flex;
      flex-direction: column;

      .content {
        width: 100%;
        padding: 16px 0 26px;
      }
      h3 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 32px;
        max-width: 170px;
      }
      .btn_access {
        width: 100%;
        height: 40px;
        font-size: 14px;
        border-radius: 6px;
      }
      .img-decor {
        width: 100%;
        margin-bottom: -10px;
        position: relative;
        max-width: 100%;
        top: 0;
        right: 0;
      }
      .content::after {
        width: 56px;
        height: 51px;
        background: url("#{$img-path}/decor4-m.svg") no-repeat;
        top: 28px;
        left: 194px;
      }
    }
  }
  .hoc10-plan {
    .header {
      max-width: 295px;
    }
    .statistical {
      .item {
        display: flex;
      }
    }
    &--cs1 {
      padding: 48px 0;
      .header {
        max-width: 345px;
      }
      .plan {
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
  .hoc10-plan {
    .container {
      padding: 0;
    }
    .plan {
      padding-top: 119px;
      margin-top: -72px;
    }
    .header {
      margin: 0 auto 32px;
    }
    .timeline {
      padding: 0 32px;
      background: url("#{$img-path}/decor5.svg") no-repeat 233px 70px;
      > * + * {
        margin-top: 50px;
      }
      > * + *::before {
        height: 50px;
        width: 4px;
        left: 30px;
        top: -50px;
        right: initial;
        transform: translateX(0);
      }
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        margin-left: 0;
        &::before {
          width: 4px;
        }
      }
      time,
      .class {
        position: absolute;
        left: 80px;
      }
      time {
        font-size: 12px;
        line-height: 18px;
        top: 10px;
      }
      .class {
        top: 32px;
        display: flex;
        > * + *::before {
          height: 12px;
          display: block;
        }
        span {
          font-size: 14px;
          line-height: 22px;
        }
        span:first-child {
          padding-left: 0;
        }
      }
      .icon {
        margin: 0;
      }
      li {
        width: 100%;
      }
      li:nth-child(2) {
        margin-left: 0;
        &::before {
          width: 4px;
        }
      }
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        margin-left: 0;
      }
    }
    .statistical {
      margin-top: 40px;
      padding: 32px 32px 8px;
      border-radius: 0;
      .item {
        width: 50%;
        margin-bottom: 24px;
      }
      .icon {
        width: 32px;
        margin-right: 8px;
      }
      .number {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
        font-family: var(--SVN-GilroyRegular);
      }
    }
    &--cs1 .plan {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .hoc10-feature {
    &--1 {
      .flex {
        flex-direction: column;
      }
    }
    &--2 {
      padding: 40px 0 48px;
      .feature {
        margin-left: 0;
      }
    }
    .feature {
      width: 100%;
      margin-right: 0;
      .icon {
        width: 36px;
        height: 36px;
      }
      .icon img {
        width: 20px;
      }
    }
    h2.title {
      max-width: 320px;
      margin-bottom: 20px;
    }
    .img-wrapper {
      width: 300px;
      margin: 0 auto;
    }
    .btn-pr {
      width: 100%;
      height: 40px;
      margin-top: 24px;
      font-size: 14px;
    }
    .line8::after {
      width: 97px;
      height: 6px;
      background: url("#{$img-path}/line8-m.svg") no-repeat 0 0 / contain;
      bottom: -5px;
    }
  }
  .hoc10-register {
    padding-bottom: 48px;
    .content {
      padding-top: 48px;
      h3 {
        margin-bottom: 17px;
      }
      h3::after {
        width: 180px;
        margin-top: 1px;
      }
      .btn_register {
        width: 100%;
        height: 48px;
        font-size: 16px;
        border-radius: 6px;
      }
    }
    .img-wrapper {
      background: #fff;
      border-radius: 12px;
      position: relative;
      &.sm {
        margin: 32px 0 0 0;
        top: initial;
        left: 0;
      }
      &.lg {
        width: 360px;
        padding: 15px;
        margin: 76px 0 0 auto;
        bottom: 0;
        right: -60px;
        top: initial;
        img {
          z-index: 2;
          position: relative;
        }
        &::before {
          top: -49px;
          left: -52px;
        }
      }
    }
  }
  .hoc10-feedback {
    padding: 48px 0 64px;
    .header {
      margin: 0 auto 4px;
      max-width: 270px;
    }
    h2.title::after {
      width: 120px;
      margin-top: 0;
    }
    .feedback-list {
      margin: 0 -10px;
    }
    .feedback {
      &__content {
        padding: 32px 16px 30px;
      }
    }
    .slick-arrow {
      bottom: -16px;
    }
    .slick-dots {
      margin-top: 6px;
    }
  }
  .hoc10-posts {
    padding: 48px 0;
    &.bg {
      padding: 48px 0 18px;
      h3 a {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .header {
      margin-bottom: 24px;
      .btn_viewmore {
        display: none;
      }
    }
    .post-list::after {
      right: -36px;
      top: 390px;
    }
    .btn_viewall {
      width: 100%;
      height: 40px;
      display: flex;
    }
  }
  .hoc10-setting {
    padding: 32px 0;
    .title {
      padding: 16px;
      font-size: 24px;
      line-height: 32px;
    }
    .setting {
      &__email {
        padding: 16px;
        p {
          margin-bottom: 8px;
        }
        .text {
          width: calc(100% - 60px);
        }
      }
      &__password {
        padding: 16px;
      }
      h4 {
        font-size: 20px;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .hoc10-newpaper {
    padding: 48px 0 48px;
    margin-top: 16px;
    .container::after {
      width: 343px;
      height: 107px;
      background: url("#{$img-path}/decor-intro2-m.svg") no-repeat 0 0 / contain;
      left: 32px;
      top: -10px;
    }
    h2.title {
      text-align: center;
    }
    .newpaper-list {
      background: none;
      &::after {
        height: 80%;
      }
    }
  }
  .hoc10-bookaccess,
  .hoc10-banner-register {
    min-height: auto;
    .container {
      min-height: auto;
    }
    .content {
      padding: 70px 0 0 12px;
    }
    h3 {
      font-size: 24px;
      line-height: 32px;
    }
    h3 span {
      font-size: 24px;
      line-height: 32px;
    }
    h3::after {
      width: 172px;
      height: 9px;
      top: 30px;
    }
    .btn-access {
      width: 178px;
      height: 40px;
      border-radius: 6px;
      font-size: 14px;
      &::after {
        display: none;
      }
    }
    .btn-login {
      width: 133px;
      height: 40px;
      border-radius: 6px;
      font-size: 14px;
    }
    .img-decor {
      width: 707px;
      position: relative;
      bottom: 0;
      left: 0;
      max-width: 100%;
      margin: 50px auto 0;
      &::after {
        width: 65px;
        height: 65px;
        top: -7px;
        right: 25px;
      }
    }
    &--cs1 {
      min-height: 444px;
      background: var(--orange) url("#{$img-path}/bg-cta-m.svg") no-repeat 0 0 /
        cover;
      .content {
        padding: 48px 0 0 12px;
      }
      h3 {
        margin-bottom: 16px;
        max-width: 316px;
      }
      h3::after {
        width: 264px;
        height: 15px;
        top: 26px;
        background: url("#{$img-path}/line4.svg") no-repeat 0 0 / contain;
      }
    }
  }
  .hoc10-banner-register {
    .img-decor--cs2 {
      max-width: 117%;
      margin: 20px 0 -15% 0;
    }
    .content {
      .btn-access {
        width: 178px;
        height: 40px;
      }
    }
  }
  .hoc10-team {
    padding: 64px 0;
    .bg-sh {
      padding: 68px 20px 24px;
    }
    .slick-dots {
      margin-top: -20px;
    }
    .slick-arrow {
      top: initial;
      bottom: -15px;
      transform: translateY(0);
    }
    .slick-prev {
      left: 15px;
      transform: translateY(0) rotate(180deg);
    }
    .slick-next {
      right: 15px;
    }
  }
  .hoc10-team--ex {
    .header {
      margin-bottom: 24px;
    }
    .slick-dots {
      display: flex !important;
      margin-top: -25px;
    }
    .btn-more {
      margin-top: 55px;
    }
  }
  .post {
    .time,
    .view {
      font-size: 12px;
      line-height: 18px;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  .post-ty1 {
    margin-bottom: 30px;
    &__thumb {
      height: 235px;
      margin-bottom: 16px;
    }
    h3 {
      margin: 4px 0 4px;
    }
  }
  .post-ty2,
  .post-ty3 {
    padding: 16px 0;
    p {
      display: none;
    }
    .post__thumb {
      width: 120px;
      height: auto;
    }
    .post__content {
      width: calc(100% - 120px);
    }
  }
  .post-ty4 {
    .post__thumb {
      height: 232px;
    }
    .post__content {
      padding: 12px;
      span {
        margin-bottom: 4px;
        color: var(--orange);
      }
    }
    &__category {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .post-blog {
    .post__content {
      padding: 20px;
    }
  }

  //intro
  .hoc10-intro {
    &.bg-gradient::after {
      height: 350px;
    }
    .box-author {
      margin: 0;
      padding-top: 8px;
      h1 {
        max-width: 251px;
        font-size: 36px;
        line-height: 48px;
        margin: 0 auto 24px;
      }
    }
    .intro {
      &::after {
        content: "";
        width: 395px;
        height: 358px;
        background: url("#{$img-path}/decor-intro1-m.svg") no-repeat 0 0 /
          contain;
        position: absolute;
        top: 250px;
        right: -43px;
        z-index: -1;
      }
      &__img {
        margin: 0 -15px;
      }
      &__content {
        width: 100%;
        margin-top: -24px;
        padding: 40px 24px;
      }
      h4 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .text-intro {
      padding: 8px 0 40px;
      max-width: 343px;
      margin: 0 auto;
      h1.title {
        max-width: 310px;
        margin: 0 auto 15px;
        font-size: 36px;
        line-height: 48px;
      }
      p {
        max-width: 320px;
      }
    }
    &--1 {
      .text-intro h1.title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .hoc10-object {
    padding: 40px 0;
    h2.title {
      font-size: 24px;
      line-height: 32px;
    }
    h2.title span::after {
      width: 97px;
    }
    .object {
      width: 100%;
      max-width: 360px;
      height: auto;
      margin-top: 50px;
      &::after {
        width: 354px;
        height: 406px;
        background: url("#{$img-path}/decor-object-m.svg") no-repeat 0 0 /
          contain;
        top: -10px;
        left: 0;
      }
      &__item {
        padding: 12px;
        max-width: 95%;
        min-height: 130px;
        &:nth-child(1) {
          margin-bottom: 235px;
        }
        &:nth-child(4) {
          min-height: 154px;
        }
      }
      &__avt {
        width: 200px;
        height: 200px;
        top: 145px;
        .avt:nth-child(1) {
        }
        .avt:nth-child(2) {
        }
        .avt:nth-child(3),
        .avt:nth-child(4) {
          top: 120px;
        }
        .avt:nth-child(4) {
        }
      }
      .ob {
        font-size: 10px;
        line-height: 18px;
        padding: 0 5px;
      }
      .avt {
        width: 64px;
        height: 64px;
        padding: 6.4px;
      }
      ul > * + * {
        margin-top: 4px;
      }
      ul {
        li {
          align-items: flex-start;
          font-size: 12px;
          line-height: 18px;
        }
        li::before {
          width: 3px;
          height: 3px;
          margin: 8px 8px 0;
        }
      }
    }
  }
  .hoc10-intro-sgk {
    padding: 0 0 0;
    .header {
      max-width: 310px;
      margin: 0 auto;
      padding: 35px 0 16px;
    }
    .container::after {
      width: 362px;
      height: 344px;
      background: url("#{$img-path}/decor-gt4-m.svg") no-repeat 0 0 / contain;
      position: absolute;
      top: 0;
      left: 0;
    }
    h2.title {
      font-size: 36px;
      line-height: 48px;
    }
    .intro-list {
      padding-top: 16px;
      margin-top: 16px;
      .img-wrapper {
        width: 80px;
      }
      .col-lg-4 {
        margin-bottom: 48px;
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .hoc10-feature-sgk {
    padding: 40px 0;
    .header {
      max-width: 312px;
      margin: 0 auto 32px;
    }
    .featured {
      .img-wrapper {
        width: 80px;
        margin-bottom: 24px;
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
      p {
        max-width: 300px;
        margin: 0 auto;
        font-size: 16px;
      }
    }
    .video {
      margin-bottom: 50px;
    }
  }
  .hoc10-type-sgk {
    padding: 0 0 40px;
    .header {
      margin-bottom: 40px;
      padding-top: 40px;
    }
    .container::after {
      width: 366px;
      height: 271px;
      background: url("#{$img-path}/decor-book-type-m.png") no-repeat 0 0 /
        contain;
      left: 9px;
      top: 112px;
    }
    .sgk {
      &__img img {
        margin-left: 15px;
      }
      &__type {
        width: 200px;
        height: 200px;
        span {
          width: 90px;
          height: 90px;
          font-size: 16px;
          line-height: 18px;
        }
        span:nth-child(1) {
          top: -50px;
        }
        span:nth-child(2) {
          top: 100px;
          left: -30px;
        }
        span:nth-child(3) {
          top: 100px;
          right: -30px;
        }
      }
    }
  }
  .hoc10-program {
    padding: 40px 0;
    .header {
      margin-bottom: 22px;
    }
    .hoc10-form {
      input {
        max-width: calc(100% - 132px);
      }
    }
    .subjects-list {
      margin: 0 -8px;
    }
    .subject {
      width: 50%;
      padding: 8px;
      &__img {
        height: 113px;
        margin-bottom: 8px;
      }
      &__name {
        font-size: 14px;
        line-height: 20px;
      }
      &__pdf {
        width: 16px;
        height: 16px;
        right: 16px;
        top: 16px;
      }
    }
  }
  .hoc10-intro-feature {
    padding: 16px 0 32px;
    .logo-h10 {
      width: 122px;
      height: 122px;
      top: 172px;
      background: none;
      border-radius: 50%;
      border: 1.5px solid #eaeced;
      img {
        max-width: 95px;
        display: block;
        margin: 0 auto;
      }
    }
    .feature-list {
      grid-template-columns: 1fr;
      li {
        width: 100%;
        height: 66px;
        padding: 13px 16px;
        margin-bottom: 8px;
      }
      li:nth-child(2) {
        margin-bottom: 154px;
      }
      li:nth-child(3) {
        margin-bottom: 24px;
      }
      li::after {
        display: none;
      }
      li:nth-child(odd) {
        margin-right: 0;
      }
      .img-wrapper {
        width: 40px;
      }
      .text {
        width: calc(100% - 40px);
        padding-left: 16px;
        font-size: 14px;
        line-height: 22px;
      }
      .status {
        left: 16px;
        padding: 2px 6px;
        top: -18px;
      }
    }
  }
  .hoc10-vepic {
    padding: 40px 0;
    .header {
      margin-bottom: 16px;
      h2.title {
        max-width: 252px;
        margin-bottom: 23px;
        font-size: 24px;
        line-height: 32px;
      }
      p {
        max-width: 201px;
        margin: 0 auto;
      }
    }
    .intro-list {
      margin-top: 0;
      padding-top: 40px;
      border-top: none;
      .img-wrapper {
        width: 80px;
      }
      .col-lg-4 {
        margin-bottom: 48px;
      }
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .video {
      padding: 8px;
      max-width: 87%;
      &__thumb {
        border-radius: 12px;
        overflow: hidden;
      }
      &__play {
        width: 90px;
      }
    }
    .btn-viewmore {
      margin-top: 0;
    }
  }
  .hoc10-team--ex {
    padding: 40px 0;
    .header p {
      margin: 7px auto 0;
      max-width: 300px;
    }
    h2.title {
      font-size: 24px;
      line-height: 32px;
      span::after {
        width: 94px;
      }
    }
  }
  .hoc10-onepercent {
    padding: 48px 0 40px;
    h2.title {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
    h2.title span::after {
      width: 140px;
    }
    .intro {
      margin-bottom: 48px;
      p {
        max-width: 321px;
        margin: 0 auto;
        text-align: center;
      }
    }
    .team {
      align-items: center;
      h2.title {
        &-pc {
          display: none;
        }
        &-mobile {
          display: flex;
          span {
            margin-left: 5px;
          }
          span::after {
            margin: 2px 0 0 0;
          }
        }
      }
    }
    .project {
      margin-top: 22px;
      justify-content: space-between;
      &__monkey {
        &--mobile {
          display: flex;
        }
        &--pc {
          display: none;
        }
      }
      &__hoc10 {
        img {
          max-width: 117px;
        }
      }
      > div {
        max-width: calc(50% - 7.5px);
        height: 98px;
        margin-right: 0;
      }
    }
    .btn-viewmore {
      margin: 24px auto 0;
    }
  }
  .hoc10-target {
    padding: 48px 0;
    .header {
      margin-bottom: 40px;
    }
  }
  .hoc10-intro-video {
    padding: 48px 0 100px;
    .video::after {
      width: 419px;
      height: 260px;
    }
  }
  .hoc10-sgk {
    padding: 60px 0;
    margin-top: 0;
    .item {
      padding: 32px 4px;
      .bg-sh {
        padding: 12px;
        box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.03);
      }
    }
    .line8::after {
      width: 128px;
    }
  }

  //onepercent
  .onepercent-intro {
    .text {
      padding: 8px 0 40px;
      h1 {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 12px;
      }
      p {
        max-width: 300px;
        font-size: 15px;
      }
    }
  }
  .onepercent-value {
    padding: 40px 0 48px;
    .text {
      margin-bottom: 32px;
      h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
      }
      p {
        max-width: 300px;
        margin: 0 auto;
        font-size: 15px;
        line-height: 22px;
      }
    }
    .img-wrapper {
      padding: 15.24px;
    }
  }
  .onepercent-develop {
    padding: 40px 0;
    .header {
      max-width: 262px;
      margin: 0 auto 16px;
      .line8::after {
        width: 141px;
        height: 8px;
        left: 93px;
        bottom: -5px;
      }
    }
    .project {
      &.monkey {
        margin-bottom: 24px;
        .project__logo {
          margin-top: 24px;
        }
      }
      &.hoc10 .project__logo {
        margin-top: 16px;
      }
      &__content {
        text-align: left;
      }
      &__logo {
        max-width: 100%;
        height: auto;
        margin-top: 24px;
        min-height: 194px;
        &--monkey {
          padding: 23px 27px;
          a:nth-child(1) {
            padding-bottom: 2px;
          }
          img {
            width: 70%;
          }
        }
        &--hoc10 img {
          width: 216px;
        }
      }
    }
  }
  .onepercent-office {
    padding: 40px 0;
    h2.title {
      font-size: 24px;
      line-height: 32px;
      max-width: 169px;
      margin: 0 auto 26px;
      text-align: center;
    }
    .address,
    .phone {
      font-size: 18px;
      line-height: 26px;
      p,
      a {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .img-wrapper {
      padding: 16px;
    }
  }

  //tusach
  .hoc10-search {
    padding: 10px 0 32px;
    .header {
      margin-bottom: 16px;
      &::after {
        width: 319px;
        height: 80px;
        background: url("#{$img-path}/decor-ts-m.svg") no-repeat;
        left: 30px;
      }
    }
    h1 {
      margin-bottom: 12px;
      font-size: 36px;
      line-height: 48px;
    }
    .search-form {
      max-width: calc(100% - 32px);
      input,
      button {
        width: 100%;
        margin-left: 0;
      }
      button {
        margin-top: 8px;
      }
      .search-popular {
        margin-top: 24px;
        text-align: center;
        justify-content: center;
        h5 {
          margin: 0;
          flex-basis: 100%;
        }
      }
    }
  }
  .hoc10-search-result {
    padding: 24px 0 60px;
    .filter {
      padding: 0;
      // display: none;
    }
  }
  .result {
    margin-top: 16px;

    &__header {
      margin-bottom: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaeced;
      h5 {
        margin-bottom: 11px;
      }
      .fil {
        &.fil--search,
        &.fil--sort {
          width: 100%;
        }
        &.fil--sort {
          order: 1;
          justify-content: flex-start;
        }
        &.fil--search {
          order: 2;
        }
        .select2-container {
          max-width: 47%;
        }
      }
      .fil--sort {
        margin-bottom: 24px;
        h4 {
          flex-basis: 100%;
          margin-bottom: 8px;
          color: var(--black);
        }
      }
      .btn_filter_m {
        display: flex !important;
        width: 50%;
        max-width: 163px;
        padding: 10px 15px;
        margin-left: 5%;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 6px;
        color: var(--orange);
        border: 1px solid #eaeced;
        .icon-filter {
          width: 16px;
          height: 16px;
          margin-right: 0;
          background: url("#{$img-path}/filter.svg") no-repeat;
        }
      }
    }
    &__list {
      padding: 0 7.5px;
      .col-lg-4 {
        padding: 0 5.5px;
      }
    }
    .post {
      margin-bottom: 24px;
      &__content {
        padding: 12px;
        span {
          margin-bottom: 4px;
          color: var(--orange);
        }
      }
      &__category {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .post-training {
      .post__thumb {
        height: 258px;
      }
      .post__content {
        span {
          color: #2a404f;
        }
      }
    }
  }
  .filter-fix-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0 38px;
    background: #fff;
    position: fixed;
    top: 0;
    left: -120%;
    height: 100vh;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s linear;
    z-index: 99;
    &.show {
      left: 0;
    }
    .logo {
      width: 112px;
      margin: 0 16px 24px;
    }
    h3 {
      padding: 0 16px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      color: var(--red);
    }
    .close {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/close1.svg") no-repeat;
      position: absolute;
      right: 16px;
      top: 26px;
    }
    .filter {
      flex: 1 1 auto;
      margin-top: 24px;
      padding: 24px 16px;
      box-shadow: none;
      border-top: 1px solid #e9e9e9;
    }
    .btn_apply {
      width: calc(100% - 32px);
      padding: 12px 0;
      margin: 8px auto 30px;
    }
  }

  //luyentap
  .hoc10-overview {
    padding: 40px 0;
    h2.title {
      max-width: 220px;
    }
    .box-video {
      margin-top: 40px;
    }
  }
  .hoc10-library {
    .library-list {
      margin: 40px -20px 0;
      a {
        margin: 0 20px 40px;
      }
      .icon {
        width: 140px;
        height: 140px;
      }
    }
    .library-list.slider {
      a {
        margin: 0 15px 40px;
      }
    }
    .slick-arrow {
      top: 50px;
    }
  }
  .hoc10-practice-assigned,
  .hoc10-practice-ex {
    padding: 40px 0 0;
  }
  .practice {
    &__img {
      height: 140px;
    }
  }
  .hoc10-title {
    padding: 8px 0 32px;
    .header {
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .img-wrapper {
      display: none;
    }
    .subject--cs1 {
      padding-top: 0;
      h1.title {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 32px;
      }
      p {
        max-width: 240px;
      }
      .subject__info {
        width: calc(100% - 84px);
      }
      .subject__icon {
        width: 84px;
        height: 84px;
        img {
          width: 48px;
        }
      }
    }
    .search-form {
      margin-top: 16px;
      input,
      .btn_submit {
        width: 100%;
      }
      .btn_submit {
        margin: 16px 0 0;
      }
    }
  }
  .hoc10-practice-list {
    padding: 32px 0 64px;
    .header {
      background: #eaeced;
      border-radius: 8px 8px 0px 0px;
      h3 {
        color: var(--gray);
      }
      span {
        background: #fff;
        padding: 3px 12px;
        border-radius: 9999px;
      }
    }
    h2.title {
      margin-bottom: 22px;
    }
    .table-of-content {
      margin-bottom: 20px;
      ul {
        li {
          padding: 24px 16px;
        }
        h4,
        .percent {
          font-size: 16px;
          line-height: 24px;
        }
        .progress {
          margin-top: 11px;
        }
        h4,
        .title {
          &::before {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .table-content {
    .header {
      padding: 18px 45px 18px 16px;
      h4 {
        font-size: 20px;
        line-height: 28px;
      }
      .btn-collapse {
        right: 16px;
      }
    }
    .item {
      margin-bottom: 16px;
    }
    .list {
      li {
        padding: 24px 16px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
      a {
        font-size: 0;
      }
    }
  }
  .chapter,
  .lesson {
    .header {
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .lesson {
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
    .header {
      padding: 22px 16px;
    }
    .Knowledge {
      a {
        width: 100%;
        margin: 0 0 24px 0;
      }
      a:last-child {
        margin-bottom: 0;
      }
    }
    .statistic > * + * {
      margin-left: 60px;
    }
  }

  //register
  .box-register {
    padding: 33px 0 80px;
    margin-top: 0;
    h1.title {
      font-size: 24px;
      line-height: 32px;
    }
    .box-choose {
      margin: 24px -8px 0;
    }
    .object {
      padding: 24px 9px;
      &__avt {
        width: 80px;
        height: 80px;
        padding: 8px;
      }
      &__name {
        font-size: 14px;
        line-height: 20px;
      }
      .checked {
        margin-top: 6px;
      }
    }
    .btn-next {
      margin-top: 24px;
      max-width: 100%;
      width: 320px;
    }
  }
  .box-login {
    margin: 16px 0;
    h4 {
      flex-wrap: nowrap;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 22px;
    }
    .login-list {
      margin: 0 -8px;
      a {
        margin: 0 8px;
        font-size: 12px;
      }
      .icon {
        margin-right: 2px;
      }
    }
  }
  .password-setting {
    .logo {
      padding: 32px 0 16px;
    }
    .box-reset {
      padding: 24px 16px;
      max-width: calc(100% - 32px);
    }
    .img-wrapper {
      width: 64px;
    }
    p {
      font: 14px/22px var(--SVN-GilroyMedium);
    }
  }

  //reader
  .hoc10-reader {
    .header {
      height: 48px;
      padding: 0 16px;
    }
    .main {
      height: calc(100vh - 96px);
    }
    .sidebar {
      top: 48px;
      height: calc(100vh - 96px);
    }
    .box-act {
      max-width: initial;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
    .footer {
      height: 48px;
      .act {
        &--2 {
          button {
            width: 32px;
            height: 32px;
          }
          .page-number {
            width: 48px;
            height: 36px;
            margin: 0 12px;
          }
        }
      }
    }
  }
  .hoc10-contact {
    .tab-content {
      padding: 20px 16px 16px;
      .phone,
      .email {
        padding: 8px;
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        justify-content: center;
      }
      .email {
        margin-left: 0;
      }
    }
    form {
      padding-top: 16px;
      margin-top: 9px;
    }
    article {
      h4 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .hoc10-contact,
  .hoc10-information {
    .col-3 {
      padding-right: 8px;
      max-width: 21%;
    }
    .col-9 {
      padding-right: 0;
      max-width: 78%;
    }
  }
  .hoc10-information {
    .form-h10 {
      .form-group {
        margin-bottom: 16px;
      }
    }
    .row {
      margin-right: -27px;
    }
    .box-upload .image-holder {
      width: 100px;
      height: 100px;
    }
    .upload {
      left: 115px;
      top: 30px;
      button {
        height: 40px;
        text-align: center;
      }
    }
  }
  .form-h10 {
    .form-row .form-group {
      width: 100%;
    }
    .form-group {
      margin-bottom: 16px;
    }
    .text {
      max-width: 311px;
      margin-top: 16px;
    }
  }

  .hoc10-tab {
    li {
      padding: 26px 18px;
    }
    span {
      display: none;
    }
    .icon {
      margin: 0 auto;
    }
  }
  .hoc10-tab-content {
    padding: 20px 16px 16px;
  }

  //student
  .hoc10-ft {
    padding: 40px 0;
    .btn-viewmore {
      width: 100%;
      margin-top: 20px;
    }
    .img-wrapper {
      padding: 12px;
    }
    &--1,
    &--t1 {
      .feature-list {
        li {
          margin-bottom: 24px;
        }
        .icon {
          width: 64px;
          margin-bottom: 12px;
        }
        h4 {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .video {
        margin-top: 16px;
        padding: 9px;
        &::after {
          width: 67px;
          height: 83px;
          left: -7px;
          bottom: -30px;
          top: initial;
        }
        &::before {
          width: 76px;
          height: 45px;
          right: -34px;
          top: -13px;
        }
      }
    }
    &--2,
    &--t2 {
      .img-wrapper {
        margin-top: 32px;
      }
      .col-lg-7::before {
        top: 16px;
        right: -80px;
      }
    }
    &--3,
    &--t3 {
      .img-wrapper {
        margin-top: 40px;
        &::before {
          width: 125px;
          height: 142px;
          top: -10px;
          right: -54px;
        }
        &::after {
          left: -60px;
          bottom: -22px;
          top: initial;
        }
      }
    }
    &--4,
    &--t4 {
      .statistical {
        > * + * {
          margin-top: 40px;
        }
        .icon {
          margin-right: 24px;
        }
        .item {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  //teacher
  .hoc10-new-training {
    padding: 24px 0 48px;
    h2.title {
      text-align: left;
    }
    .header {
      margin-bottom: 16px;
    }
    .row {
      flex-direction: column-reverse;
    }
    .news {
      &__list {
        p {
          margin-bottom: 8px;
          -webkit-line-clamp: 2;
        }
        .item {
          padding: 16px;
        }
        .active {
          p {
            -webkit-line-clamp: 5;
          }
          &::before {
            width: 8px;
          }
        }
      }
      &__thumb {
        margin: 35px 0 0;
        &::after {
          top: 40%;
        }
      }
      .img-wrapper {
        padding: 13.5px;
        margin-bottom: 12px;
      }
      .btn-viewall {
        width: 120px;
        height: 32px;
        margin-top: 23px;
      }
    }
  }
  .hoc10-doc-training,
  .hoc10-check-training {
    padding: 48px 0;
  }
  .hoc10-doc-training {
    .filter {
      display: none;
    }
    .box-search {
      padding: 16px;
      margin-bottom: 10px;
    }
    .search-form {
      .search-popular {
        justify-content: flex-start;
        h5 {
          margin-bottom: 0;
          flex-basis: 100%;
        }
      }
    }
    .pagination {
      margin-top: 8px;
    }
    .result .col-lg-4 {
      padding: 0 5px;
    }
  }
  .hoc10-check-training {
    .header {
      margin-bottom: 16px;
    }
    .content {
      h2 {
        font-size: 20px;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        color: #606e78;
      }
    }
    .box-time {
      &::after {
        left: 185px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
      .btn-play {
        width: 160px;
        height: 32px;
        margin-top: 2px;
        font-size: 12px;
      }
    }
    .img-wrapper {
      max-width: 90%;
    }
  }

  //thithu
  .hoc10-library-test {
    .item {
      margin-bottom: 16px;
    }
  }
  .hoc10-test {
    .header {
      height: 115px;
      padding: 20px 0;
      position: relative;
      background: #f4f5f6;
      .container {
        align-items: flex-start;
      }
      .text {
        bottom: 15px;
      }
      .text--cs1 {
        text-align: left;
        left: 16px;
        transform: translateX(0);
      }
      .title {
        font-size: 18px;
        line-height: 26px;
      }
      .btn-submit {
        width: 96px;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        position: absolute;
        right: 16px;
      }
      .time {
        font-size: 16px;
        line-height: 2px;
        position: absolute;
        margin-right: 0;
        right: 16px;
        bottom: 26px;
      }
    }
    .header--cs1 {
      height: 140px;
    }
    .header--finish {
      height: auto;
      padding: 24px;
      text-align: center;
      .title {
        font-size: 24px;
        line-height: 32px;
      }
      .text {
        bottom: initial;
        width: 100%;
        text-align: center !important;
      }
    }
    .footer {
      height: 135px;
      padding: 20px 0;
      position: relative;
      .container {
        align-items: flex-end;
      }
      .pagination {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -5px;
      }
    }
    .footer--cs1 {
      height: auto;
    }
    .box-act {
      top: 16px;
      transform: translateY(0);
    }
    .question {
      padding: 24px 16px 0;
    }
    .box-question {
      h4 {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        font-family: var(--SVN-GilroyRegular);
      }
      .img-wrapper {
        margin-top: 0;
      }
    }
    .answer {
      padding: 0 16px;
      justify-content: space-between;
      label {
        font-size: 13px;
      }
      .box-btn {
        margin-top: 32px;
        a {
          width: calc(50% - 8px);
        }
      }
    }
    .check {
      bottom: 50px;
    }
    .list-question {
      width: 100%;
      height: auto;
      h3 {
        padding: 18px 16px;
        border-top: 4px solid #eaeced;
      }
      .list {
        padding: 10px 4px;
        > * + * {
          margin-top: 0;
        }
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 6px 12px;
          align-items: center;
        }
        .icon {
          margin-left: 8px;
        }
      }
    }
    .main-test {
      .box-question {
        max-width: calc(100% - 32px);
        padding: 24px 16px;
        margin: 0 auto;
      }
      .question {
        padding: 0;
      }
      .answer {
        padding: 0;
      }
    }
    .sidebar,
    .answer-list {
      width: 100%;
    }
    .box-qs-list {
      ul {
        margin: 0 -16px;
        justify-content: center;
        li {
          margin: 8px 18px;
        }
      }
    }
    .sidebar {
      margin-bottom: 16px;
    }
    .main-result {
      padding-bottom: 48px;
    }
  }
  .hoc10-background-decor {
    padding-top: 40px;
    .img-wrapper {
      img {
        margin-left: 20px;
      }
    }
    .btn-login {
      width: 136px;
      height: 50px;
    }
  }
  .hoc10-homework {
    > * + * {
      margin-top: 8px;
    }
    .subject-type {
      margin-bottom: 16px;
    }
    .list-exam {
      padding: 16px;
      li {
        padding: 12px 95px 12px 46px;
      }
      li::before {
        width: 32px;
        height: 32px;
        left: 8px;
      }
      .btn-pr {
        width: 85px;
        height: 32px;
        right: 8px;
        font-size: 12px;
      }
      h4 {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 20px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .answer-list {
    .time {
      max-width: 50%;
      span {
        display: block;
      }
    }
    .item {
      &__header {
        padding: 13px 17px;
      }
      &__content {
        padding: 24px 16px 10px;
      }
      .content {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .box-answer {
      h4 {
        margin-bottom: 8px;
      }
    }
  }

  //hotro
  .hoc10-question {
    padding: 32px 0 24px;
    .question-list {
      &::after {
        bottom: 125px;
      }
      &::before {
        width: 58px;
        height: 58px;
        left: -5px;
        bottom: -10px;
        top: initial;
        right: initial;
      }
    }
    .item {
      width: 100%;
    }
  }
  .hoc10-guide {
    padding: 32px 0;
    h2.title.text-left {
      text-align: center;
    }
    .support {
      &__list {
        margin-bottom: 24px;
      }
      &__list,
      &__content,
      &__page {
        width: 100%;
      }
    }
    .accordion {
      .header {
        padding: 16px 0;
      }
    }
    .tab {
      li a {
        padding: 16px 0 18px;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .tab-content {
      padding: 16px 16px 8px;
      h4 {
        margin-bottom: 16px;
      }
    }
    .pagedetail {
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
      article {
        font-size: 14px;
        line-height: 22px;
        h4 {
          margin-bottom: 12px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .guide-list a {
      font-size: 14px;
      line-height: 22px;
    }
    .related {
      h3 {
        font-size: 16px;
        line-height: 24px;
        padding: 18px;
      }
      ul {
        li {
          padding: 16px 0;
        }
        a {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  .hoc10-contactus {
    padding: 57px 0 24px;
    min-height: auto;
    background-size: cover;
    &::after {
      content: "";
      width: 289px;
      height: 101px;
      background: url("#{$img-path}/line10.svg") no-repeat 0 0 / contain;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 34px;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    ul {
      li {
        align-items: center;
        margin: 8px 0;
      }
      a {
        font-size: 12px;
        line-height: 18px;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin: 2px 8px 0 0;
      }
    }
    .btn-contact {
      width: 187px;
      height: 40px;
      margin-bottom: 76px;
      font-size: 14px;
      border-radius: 6px;
      &::after {
        top: 5px;
        right: -65px;
      }
      &::before {
        width: 68px;
        height: 71px;
        left: -80px;
        top: -10px;
      }
    }
  }
  .hoc10-news {
    padding: 32px 0 48px;
    .col-lg-8 {
      padding-right: 12px;
    }
    .col-lg-4 {
      padding-left: 12px;
    }
    .post--highlight {
      margin-bottom: 10px;
    }
    .readmany {
      margin-top: 40px;
    }
    .author {
      font-size: 16px;
      line-height: 24px;
    }
    .quote {
      padding: 16px;
    }
    .related {
      padding-top: 16px;
      margin-top: 16px;
    }
  }
  .hoc10-blog {
    padding: 32px 0;
    .blog-highlight {
      .post {
        &__thumb {
          margin-bottom: 16px;
        }
        h3 a {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
    .blog-list {
      padding: 24px 0;
      .header {
        h4 {
          font-size: 20px;
          line-height: 28px;
        }
        .btn-view {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
  .hoc10-blog-detail {
    .summary {
      padding-bottom: 24px;
      &__content {
        h1 {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .menu-post {
      h4 {
        padding-left: 28px;
        font-size: 16px;
        line-height: 24px;
        background: none;
        position: relative;
      }
      h4::before {
        content: "";
        width: 20px;
        height: 20px;
        background: url("#{$img-path}/icon-mn.svg") no-repeat 0 0 / contain;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
    article p {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 22px;
    }
    .tag a {
      margin: 2px 4px;
      font-size: 12px;
      line-height: 18px;
      padding: 7px 8px;
    }
    .email-register {
      margin: 16px 0 8px;
      background: url("#{$img-path}/bg-register.svg") no-repeat 0 0 / cover;
      h4 {
        margin-bottom: 16px;
        text-align: left;
      }
      h4::after {
        width: 120px;
      }
    }
    .related-post {
      padding: 32px 0 24px;
    }
  }
  .email-register {
    margin-top: 21px;
  }

  //baigiang
  .hoc10-lesson {
    padding: 48px 0 96px;
    .header {
      margin-bottom: 20px;
      h2.title {
        max-width: 190px;
        text-align: left;
      }
    }
    .lesson-list {
      margin: 0;
    }
    .item {
      padding: 8px 0;
    }
    .post {
      padding: 20px;
    }
    .addnew {
      height: 284px;
    }
    .btn-viewall {
      width: 116px;
      height: 40px;
    }
    .slick-arrow {
      bottom: -48px;
    }
  }
  .hoc10-lesson-library {
    // padding: 48px 0;
    .header {
      margin-bottom: 16px;
      h2.title {
        margin-bottom: 16px;
      }
    }
    .box-filter {
      justify-content: space-between;
    }
    .box-select {
      width: calc(50% - 12px);
      margin: 0 0 16px 0;
    }
    .search-form {
      width: 100%;
    }
    .post {
      margin-bottom: 16px;
      &__thumb {
        padding: 5px 5px 0;
      }
      &__content {
        padding: 12px 20px 16px;
      }
    }
    .data {
      padding: 16px 20px;
    }
  }
  .search-form {
    .btn_submit {
      width: 106px;
    }
    input {
      width: calc(100% - 118px);
    }
  }
  .hoc10-slideshow {
    .header {
      padding: 15px 0 0;
    }
    .main {
      padding: 0 16px 30px;
      .content {
        padding: 24px 0 16px;
      }
      .box-video {
        order: 4;
        margin-top: 20px;
        padding: 16px;
        border: 1px solid #eaeced;
      }
      .btn-show {
        order: 3;
      }
      h3 {
        margin-bottom: 4px;
      }
      h4 {
        margin-bottom: 8px;
      }
    }
    .dropdown {
      display: block;
      position: absolute;
      right: 24px;
      top: 17px;
      &.show {
        .dropdown__menu {
          display: block;
        }
      }
      &__menu {
        width: 130px;
        margin-top: 0;
        display: none;
        background: #19252e;
        padding: 0 8px;
        position: absolute;
        right: -20px;
        > * + * {
          border-top: 1px solid #2a404f;
        }
        a {
          padding: 12px 0;
          color: #fff;
          font-size: 16px;
          line-height: 24px;
        }
        &::after {
          content: "";
          border-width: 8px;
          border-style: solid;
          border-color: transparent transparent #19252e transparent;
          position: absolute;
          right: 24px;
          top: -16px;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
      }
      .icon-download {
        background: url("#{$img-path}/icon-download3.svg") no-repeat;
      }
      .icon-share {
        background: url("#{$img-path}/icon-share3.svg") no-repeat;
      }
      button {
        width: 24px;
        height: 24px;
        background: url("#{$img-path}/icon-more.svg") no-repeat 0 0 / contain;
      }
    }
    .list-slide {
      margin-top: 24px;
      padding-bottom: 16px;
    }
    .slide-wrapper {
      padding: 14px;
    }
    .box-filter {
      .search-form,
      .box-select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .video-list {
      margin: 0 -5px;
      .video {
        width: 50%;
        padding: 4px;
      }
    }
    .box-btn {
      width: 100%;
      margin: 15px 0 0 0;
      padding: 20px 0;
      border-top: 1px solid #eaeced;
      justify-content: flex-start;
      flex-direction: row-reverse;
      > * + * {
        margin-right: 10px;
      }
      .btn-download,
      .btn-share {
        display: none;
      }
    }
    .btn-back {
      .text {
        display: none;
      }
      .tag {
        background: #fff;
        border: 1px solid #eaeced;
      }
    }
    .btn-hide,
    .btn-show {
      position: relative;
      margin-top: 20px;
    }
  }

  //timkiem
  .hoc10-page-search {
    .text {
      padding: 16px 0;
      font-size: 16px;
      line-height: 24px;
    }
    .search-result {
      .col-6 {
        padding: 0 6px;
      }
    }
    .box-404 {
      padding: 40px 0;
      p {
        font-size: 24px;
        line-height: 32px;
      }
      span {
        font-size: 16px;
        line-height: 24px;
      }
      .img-wrapper {
        max-width: 300px;
        margin-bottom: 48px;
      }
    }
  }

  //modal
  .modal-video {
    .modal-dialog {
      max-width: 96vw;
    }
    .modal-content {
      height: 60vw;
    }
  }
  .modal-select-time {
    .modal-header {
      padding: 16px;
      h5 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .modal-body {
      padding: 16px 16px 24px;
    }
    .box-select-time {
      margin-top: 12px;
      background: transparent;
      .item {
        width: 50%;
      }
      .item:nth-child(1),
      .item:nth-child(2) {
        background: #f4f5f6;
      }
      .item:nth-child(3),
      .item:nth-child(4) {
        margin-top: 16px;
        button {
          margin: 0 auto;
          height: auto;
        }
        .item__content {
          padding: 0;
        }
        .item__header {
          height: auto;
          margin-bottom: 4px;
          line-height: 22px;
          background: transparent;
          text-align: center;
          color: #2a404f;
        }
      }
    }
  }
  .modal-note {
    .close {
      width: 20px;
      height: 20px;
      right: 16px;
      top: 18px;
    }
    .modal-header {
      padding: 16px;
      h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 4px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .modal-body {
      padding: 16px 16px 0;
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .modal-footer {
      padding: 16px;
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    h4 {
      font-size: 18px;
      line-height: 26px;
    }
  }

  //footer
  footer {
    .info {
      padding: 40px 0;
    }
    .middle {
      .col1 {
        width: 50%;
      }
      .col3 {
        width: 50%;
      }
      .col2 {
        width: 100%;
        display: flex;
        .col-group {
          width: 50%;
        }
      }
    }
  }

  //icon
  .icon-arr {
    width: 20px;
    height: 20px;
  }
  .decor-h1::after {
    width: 365px;
    height: 108px;
    background: url("#{$img-path}/decor-hs2.svg") no-repeat 0 0 / contain;
    left: -16px;
    top: -35px;
  }
  .decor-h2::after {
    width: 347px;
    height: 204px;
    background: url("#{$img-path}/bg-intro1-m.svg") no-repeat 0 0 / contain;
    left: 0;
    top: -30px;
  }
  .decor-h3::after {
    @include decorintrom;
    left: -16px;
    top: -15px;
  }
  //uikit
  .header.title {
    padding: 10px 0 40px;
  }
  h1.title {
    font-size: 36px;
    line-height: 48px;
  }
  h2.title {
    font-size: 24px;
    line-height: 32px;
  }
  .btn-pr {
    border-radius: 6px;
    font-size: 14px;
  }
  .txt-1 {
    font-size: 16px;
    line-height: 22px;
  }
  .txt-2 {
    font-size: 18px;
    line-height: 26px;
  }
  .txt-3 {
    font-size: 24px;
    line-height: 32px;
  }
  .mt-100 {
    margin-top: 85px;
  }
  .mb-64 {
    margin-bottom: 57px;
  }
  .video-ty1 {
    padding: 9px;
    .video__thumb {
      border-radius: 12px;
      overflow: hidden;
    }
  }
  .line8::after {
    width: 110px;
    bottom: -8px;
  }
  .tab-pr {
    li {
      margin-bottom: 5px;
    }
    a {
      padding: 0 0 10px;
      width: 68px;
      font-size: 16px;
    }
  }
  .tab-pr--cs1 {
    padding: 15px 0;
  }
  .box-fix-right {
    right: 16px;
    bottom: 40px;
  }
  .video {
    &__play {
      top: 40%;
      left: 40%;
    }
  }
}
