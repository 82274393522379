.FIBMath__wrapper {
  height: 85%;
  overflow-y: auto;

  .answer-items__wrapper {
    align-items: center;
  }

  .content-editable {
    display: inline-block;
    width: auto;
    padding: 8px 24px;
    margin: 16px;
    border-radius: 5px;
    border: 2px solid #939598;
  }
  .content-editable:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
  }
}
