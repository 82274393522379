.calculator-modal__title {
  background: #e5f7ff;
  font-size: 24px;
}
.calculator-modal__body {
  margin: 10px;
}
.calculator-modal__action {
  padding-top: 10px;
  button {
    min-width: 100px;
  }
}

.calculator__list-math-suggestion {
  padding-top: 35px;
  overflow-x: scroll;

  .keypad-inner {
    height: 295px;
    width: 1080px;
    display: flex;

    .mq-math-mode {
      cursor: pointer;
    }

    .column.col5 {
      width: 220px;
    }
    .column.col4 {
      width: 176px;
    }
    .column.col3 {
      width: 132px;
    }
    .column {
      display: inline-block;
      margin-right: 18px;
    }
    .key {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      text-align: center;
      margin-right: 4px;
      margin-top: 4px;
      overflow: hidden;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      .image {
        width: 30px;
        height: 15px;
        margin-bottom: -5px;
      }
    }
    button {
      border: none;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.mq-editable-field {
  min-width: 448px;
  min-height: 65px;
  padding-top: 15px;
  padding-bottom: 5px;
}
.mq-root-block {
  padding: 6px !important;
}


.math-input__header-question {
  float: left;
  position: relative;
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  margin: 0 1rem 0.6rem 30px;

  .mathPix {
    ol li {
      margin-left: 35px;
    }
  }
}