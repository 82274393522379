.InputDate{
    input{
        position: relative;
        width:180%;
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 34px;
    }
    img{
        position:absolute;
        pointer-events: none;
        top: 45%; 
        right: 10%;
        }

    @media screen and (max-width: 768px){
        input {
            width: 120%;
        }
        img {
            left: 45%;
            top: 60%;
        }
    }
 
}