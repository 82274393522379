$fontSizeContent: 16px;

:root {
  --black: #19252e;
  --gray: #2a404f;
  --gray1: #606e78;
  --hover: #d30d1a;
  --orange: #ff7707;
  --SVN-GilroyLight: SVN-GilroyLight, sans-serif;
  --SVN-GilroyBold: SVN-GilroyBold, sans-serif;
  --SVN-GilroyMedium: SVN-GilroyMedium, sans-serif;
  --SVN-GilroyRegular: SVN-GilroyRegular, sans-serif;
}
body {
  width: 100%;
  background: #fff;
  font-family: var(--SVN-GilroyRegular);
  font-size: $fontSizeContent;
  color: var(--gray1);
  line-height: 1.4;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-track {
    background: #ccc;
  }
}
html {
  scroll-behavior: smooth;
}

body.modal-open {
  overflow: inherit;
  padding-right: 0 !important;
}
.footer {
  a {
    font-size: 16px;
  }
}
a {
  transition: 0.15s;
  color: var(--dark);
  font-size: $fontSizeContent;
}
a:hover {
  text-decoration: none;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  margin-bottom: 0;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: SVN-GilroyBold;
  src: url("#{$font-path}/Gilroy-Bold1.otf");
}
@font-face {
  font-family: SVN-GilroyLight;
  src: url("#{$font-path}/Gilroy-Light1.otf");
}
@font-face {
  font-family: SVN-GilroyRegular;
  src: url("#{$font-path}/Gilroy-Regular1.otf");
}
@font-face {
  font-family: SVN-GilroyMedium;
  src: url("#{$font-path}/Gilroy-Medium1.otf");
}

@mixin line1 {
  content: "";
  width: 266px;
  height: 12px;
  background: url("#{$img-path}/line4.svg") no-repeat 0 0 / contain;
}
@mixin line5 {
  content: "";
  width: 156px;
  height: 14px;
  background: url("#{$img-path}/line5.svg") no-repeat 0 0 / contain;
}
@mixin line8 {
  content: "";
  width: 161px;
  height: 8px;
  background: url("#{$img-path}/line8.svg") no-repeat 0 0 / contain;
}
@mixin line9 {
  content: "";
  width: 100px;
  height: 8px;
  background: url("#{$img-path}/line9.svg") no-repeat 0 0 / contain;
}
@mixin decor1 {
  content: "";
  width: 129px;
  height: 129px;
  background: url("#{$img-path}/decor2.svg") no-repeat 0 0 / contain;
  position: absolute;
}
@mixin decor3 {
  content: "";
  width: 129px;
  height: 129px;
  background: url("#{$img-path}/decor3.svg") no-repeat 0 0 / contain;
  position: absolute;
}
@mixin quote {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  margin-bottom: 16px;
  background: url("#{$img-path}/icon-quote.svg") no-repeat 0 0 / contain;
}
@mixin decointro {
  content: "";
  width: 1111px;
  height: 126px;
  background: url("#{$img-path}/bg-intro2.svg") no-repeat 0 0 / contain;
  position: absolute;
  top: -12px;
  left: -37px;
}
@mixin decor7 {
  content: "";
  width: 114px;
  height: 80px;
  background: url("#{$img-path}/decor7.svg") no-repeat 0 0 / contain;
  position: absolute;
  z-index: -1;
}
@mixin decor8 {
  content: "";
  width: 184px;
  height: 198px;
  background: url("#{$img-path}/decor8.svg") no-repeat 0 0 / contain;
  position: absolute;
  z-index: -1;
}
@mixin decor9 {
  content: "";
  width: 98px;
  height: 76px;
  background: url("#{$img-path}/decor9.svg") no-repeat 0 0 / contain;
  position: absolute;
  z-index: -1;
}
@mixin decor6 {
  content: "";
  width: 129px;
  height: 129px;
  background: url("#{$img-path}/decor6.svg") no-repeat 0 0 / contain;
  position: absolute;
}
@mixin decor11 {
  content: "";
  width: 119px;
  height: 85px;
  background: url("#{$img-path}/decor11.svg") no-repeat 0 0 / contain;
  position: absolute;
  z-index: -1;
}
@mixin arrowl {
  content: "";
  width: 62px;
  height: 52px;
  background: url("#{$img-path}/arrow-l.svg") no-repeat;
  position: absolute;
  top: 0;
  right: -82px;
}
// ---------- header --------- //
header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  &.scroll,
  &.sh {
    box-shadow: 8px 8px 16px rgba(167, 167, 167, 0.11);
  }
  &.sh .navbar {
    height: 72px;
  }
  .logo {
    width: 104px;
    margin-right: 32px;
  }
  .logo-m {
    display: none;
  }
  .btn_search {
    width: 20px;
    height: 20px;
    margin: 0 24px 0 95px;
    background: url("#{$img-path}/icon-search.svg") no-repeat 0 0 / contain;
    display: block;
    font-size: 0;
  }
  .box-search {
    width: 100%;
    display: none;
    max-width: 710px;
    position: absolute;
    right: 300px;
    z-index: 10;
    overflow: hidden;
    &.active {
      display: block;
    }
    /*margin-top: 32px;*/
    input {
      width: 100%;
      height: 40px;
      padding: 0 16px 0 42px;
      background: #f7f7f7 url("#{$img-path}/icon-search.svg") no-repeat 16px
        center;
      border-radius: 6px;
      border: none;
    }
    .close {
      width: 16px;
      height: 16px;
      display: block;
      background: url("#{$img-path}/close1.svg") no-repeat;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
  .login {
    &__after {
      display: none;
    }
    .btn_register {
      width: 108px;
      height: 40px;
      margin-right: 16px;
      font-family: var(--SVN-GilroyBold);
      border-radius: 8px;
    }
    .btn_login {
      font-family: var(--SVN-GilroyBold);
      line-height: 22px;
      color: #252527;
      &:hover {
        color: var(--orange);
      }
    }
    .dropdown {
      width: 175px;
      padding: 10px 16px;
      position: absolute;
      right: 0;
      top: 50px;
      display: none;
      &.show {
        display: block;
      }
      a {
        padding: 6px 0;
        line-height: 20px;
        font-size: 13px;
      }
      a:hover {
        color: var(--orange);
      }
      .icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
      .icon-pencil {
        background: url("#{$img-path}/icon-edit.svg") no-repeat;
      }
      .icon-setting {
        background: url("#{$img-path}/icon-setting.svg") no-repeat;
      }
      .icon-logout {
        background: url("#{$img-path}/icon-out.svg") no-repeat;
      }
    }
    .text {
      line-height: 22px;
      color: #19252e;
      a {
        font-family: var(--SVN-GilroyBold);
        color: var(--orange);
      }
    }
  }
  .avatar {
    cursor: pointer;
    &__img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }
    &__name {
      padding-right: 28px;
      background: url("#{$img-path}/icon-arrdown.svg") no-repeat right center;
      font-family: var(--SVN-GilroyMedium);
      line-height: 20px;
      color: var(--black);
    }
  }
  .progress-register {
    > * + *::before {
      content: "";
      width: 215px;
      height: 2px;
      display: block;
      background: #eaeced;
    }
    .step {
      display: flex;
      align-items: center;
    }
    .finish span {
      background: var(--orange) url("#{$img-path}/icon-check1.svg") no-repeat
        center center;
      border: none;
      font-size: 0;
    }
    .finish::before {
      background: var(--orange);
    }
    .active {
      span {
        border-color: var(--orange);
        color: var(--orange);
      }
      &::before {
        background: var(--orange);
      }
    }
    span {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #eaeced;
      color: #aab3b9;
    }
  }
}
.navbar-toggler {
  display: none;
}
.navbar {
  width: 100%;
  height: 72px;
  margin: 0 auto;
  align-items: center;
  padding: 16px 15px;
  transition: 0.5s;
  z-index: 99;
  .container {
    max-width: 1326px;
  }
  .close {
    display: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 1;
  }
  .close:hover {
    color: #fff !important;
  }
}
.navbar-nav {
  margin-left: auto;
  > li {
    display: flex;
    align-items: center;
    margin-right: 28px;
    position: relative;
  }
  > li > a {
    display: block;
    padding: 26px 0;
    color: var(--black);
    font: calc($fontSizeContent / 20px) var(--SVN-GilroyMedium);
    font-size: calc($fontSizeContent + 2px);
    position: relative;
  }
  > li:hover {
    > a {
      color: var(--orange);
    }
    .show-menu {
      background: url("#{$img-path}/arr-down1.svg") no-repeat;
    }
  }
  > li > a.active {
    color: var(--orange);
  }
  > li:hover .sub-menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  > li > .activated {
    display: flex;
    padding: 4px 8px;
    border: 1px solid #ff7707;
    border-radius: 4px;
    color: var(--orange);
    font: calc($fontSizeContent/20px) var(--SVN-GilroyBold);
    .icon {
      margin-right: 4px;
      width: 12px;
      height: 12px;
      background: url("#{$img-path}/icon-key.svg") no-repeat 0 0 / contain;
    }
  }
  
  span {
    position: relative;
    z-index: 2;
  }
  .sub-menu {
    width: 220px;
    padding: 10px 14px;
    display: none;
    position: absolute;
    left: 0;
    top: 68px;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    transition: 0.2s linear;
    .icon {
      width: 16px;
      margin-right: 6px;
    }
    li a {
      display: flex;
      align-items: center;
      padding: 6px 0;
      font: calc($fontSizeContent/20px) var(--SVN-GilroyMedium);
      font-size: 17px;
      color: var(--gray);
      &:hover {
        color: var(--orange);
      }
    }
  }
  .show-menu {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    display: block;
    background: url("#{$img-path}/arr-down.svg") no-repeat 0 0 / contain;
    cursor: pointer;
  }
}
h1 {
  margin: 0;
  display: inline-block;
}

//main
main {
  padding-top: 72px;
  padding-bottom: 12px;
}
.language {
  img {
    width: 20px;
    margin-right: 8px;
  }
  button,
  a {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: var(--SVN-GilroyMedium);
    line-height: 20px;
  }
  .dropdown-toggle::after {
    // width: 11px; height: 6px; display: block;
    // background: url("#{$img-path}/arr.svg") no-repeat 0 0/contain;
    margin-left: 10px;
    //border: none;
  }
  .dropdown-menu {
    border: none;
    min-width: 10rem;
    box-shadow: 1px 4px 24px rgba(209, 209, 209, 0.25);
    top: 35px;
    left: -10px;
    padding: 5px 0;
    a {
      padding: 10px;
    }
  }
}
.box-fix-right {
  position: fixed;
  right: 32px;
  bottom: 80px;
  z-index: 9;
  a {
    width: 56px;
    height: 56px;
    margin: 7px 0;
    background: #2a404f;
    border-radius: 50%;
    position: relative;
    font-size: 0;
    &:hover {
      background: var(--orange);
    }
  }
  .btn-edit {
    background: var(--orange);
  }
  .backtop {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    position: relative;
    z-index: -1;
    &.show {
      opacity: 1;
      visibility: visible;
      z-index: 9;
    }
  }
  .icon {
    margin-right: 0;
    width: 24px;
    height: 24px;
  }
  .icon-chat {
    background: url("#{$img-path}/icon-mess.svg") no-repeat;
  }
  .icon-messenger {
    background: url("#{$img-path}/icon-mss.svg") no-repeat;
  }
  .icon-qs {
    background: url("#{$img-path}/icon-qs.svg") no-repeat;
  }
  .icon-arrup {
    background: url("#{$img-path}/icon-arr-up.svg") no-repeat;
  }
}
.hoc10-title {
  padding: 0 0 64px;
  .header {
    h1,
    p {
      position: relative;
      z-index: 2;
    }
    p {
      max-width: 478px;
      margin: 0 auto;
      text-align: center;
      font: 16px/24px var(--SVN-GilroyMedium);
      font-size: 18px;
      color: var(--gray);
    }
  }
  h1.title {
    display: block;
    text-align: center;
  }
  .subject {
    position: relative;
    z-index: 2;
    &__icon {
      background: #feeecd;
      width: 144px;
      height: 144px;
      border-radius: 50%;
    }
    &__info {
      width: calc(100% - 144px);
      padding-left: 45px;
    }
    h1.title {
      text-align: left;
      margin-bottom: 10px;
    }
    h1 span {
      font-family: var(--SVN-GilroyMedium);
    }
    h5 {
      color: var(--gray);
      margin-bottom: 4px;
    }
    p {
      line-height: 22px;
    }
    .btn-sub {
      width: 120px;
      height: 32px;
      margin-top: 12px;
      border-radius: 6px;
      font-size: 12px;
    }
    &--cs1 {
      padding-top: 30px;
      .subject__info {
        padding-left: 0;
      }
    }
  }
  .img-wrapper {
    position: absolute;
    bottom: -22px;
    right: 90px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .search-form {
    margin-top: 24px;
  }
}
.hoc10-slider {
  width: 1296px;
  margin: 0 auto;
  padding: 104px 104px 40px 93px;
  min-height: 500px;
  background: url("#{$img-path}/bg-slider.svg") no-repeat 0 0 / cover;
  border-radius: 24px;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: none;
    background: url("#{$img-path}/home-dc1.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .slider-home {
    z-index: 2;
  }
  .item {
    display: flex !important;
    overflow: hidden;
  }
  .col-left {
    width: 430px;
    margin-right: 60px;
  }
  h1,
  h2 {
    max-width: 500px;
    margin-bottom: 13px;
    font: 54px/64px var(--SVN-GilroyBold);
    color: var(--gray);
    span {
      width: 253px;
      height: 73px;
      display: inline-block;
      margin: 2px 0 0 -15px;
      background: url("#{$img-path}/frame1.svg") no-repeat 0 0 / contain;
      color: var(--orange);
      text-align: center;
    }
  }
  p {
    max-width: 409px;
  }
  .btn-discover {
    width: 200px;
    height: 60px;
    margin-top: 40px;
  }
  .video {
    width: 608px;
    height: 363px;
    padding: 24px;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      .video__play {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &__thumb {
      border-radius: 8px;
    }
    &__play {
      @include center;
      transition: 0.3s;
    }
  }
  .slick-next {
    right: -122px;
  }
  .slick-prev {
    left: -112px;
  }
  .slick-dots {
    margin-top: 53px;
    li {
      margin: 0 6px;
    }
    button {
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        width: 6px;
        height: 6px;
        display: block;
        background: rgba(#fd7607, 0.5);
        border-radius: 50%;
        @include center;
      }
    }
    .slick-active button {
      background: #fff;
      border: 1px solid var(--orange);
      &::after {
        background: rgba(#fd7607, 1);
      }
    }
  }
}
.program {
  padding: 40px 0 123px;
  .header {
    margin-bottom: 72px;
    background: url("#{$img-path}/bg-title1.svg") no-repeat center 8px;
  }
  .title span::after {
    content: "";
    width: 112px;
    height: 10px;
    background: url("#{$img-path}/line.svg") no-repeat 0 0 / contain;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -3px;
  }
  &__list {
    margin: 0 -27.5px;
    .item {
      width: 33.333%;
      padding: 0 27.5px;
      &__icon {
        width: 96px;
        margin-bottom: 32px;
      }
      p {
        margin: 8px 0 12px;
        min-height: 66px;
        line-height: 22px;
        text-align: justify;
      }
    }
    .btn_viewmore {
      font: 16px/24px var(--SVN-GilroyBold);
      color: var(--orange);
    }
  }
}
.hoc10-access {
  .container::after {
    content: "";
    width: 129px;
    height: 129px;
    background: url("#{$img-path}/decor1.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: -40px;
    left: -25px;
    z-index: -1;
  }
  .box-access {
    border-radius: 12px;
    min-height: 320px;
    overflow: hidden;
    .content {
      padding: 67px 0 0 65px;
      position: relative;
      &::after {
        content: "";
        width: 93px;
        height: 95px;
        background: url("#{$img-path}/decor4.svg");
        position: absolute;
        top: 72px;
        left: 344px;
      }
    }
    h3 {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 48px;
      color: var(--gray);
    }
    h3 span {
      display: block;
      font-family: var(--SVN-GilroyBold);
    }
    .btn_access {
      width: 136px;
      height: 60px;
      border-radius: 12px;
      font-size: 18px;
    }
    .img-decor {
      width: 509px;
      position: absolute;
      top: 35px;
      right: 130px;
    }
  }
}
.hoc10-plan {
  .container {
    max-width: 1326px;
  }
  h2.title {
    font-family: var(--SVN-GilroyRegular);
    span {
      font-family: var(--SVN-GilroyBold);
    }
  }
  .header {
    padding: 22px 0 8px;
    background: url("#{$img-path}/bg-title2.svg") no-repeat center center;
  }
  .plan {
    margin-top: -85px;
    padding-top: 80px;
    background: #fffbf7;
    border-radius: 24px 24px 0px 0px;
  }
  .timeline {
    padding: 88px 0 104px;
    justify-content: center;
    > * + *::before {
      content: "";
      height: 4px;
      width: 293px;
      background: rgba(#ff7707, 0.2);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 64px;
    }
    li {
      display: flex;
      align-items: center;
      position: relative;
      &:nth-child(2)::before {
        width: 220px;
      }
      &:nth-child(2) {
        margin-left: 164px;
      }
      &:nth-child(3) {
        margin-left: 118px;
      }
      &:nth-child(4) {
        margin-left: 82px;
      }
      &:nth-child(5) {
        margin-left: 86px;
      }
    }
    .active {
      &::before {
        background: var(--orange);
      }
      .icon {
        background: #fff2e6;
      }
      .icon::after {
        background: var(--orange);
      }
    }
    time {
      display: block;
      text-align: center;
      font-family: var(--SVN-GilroyMedium);
      color: #69676a;
      line-height: 20px;
    }
    .icon {
      width: 64px;
      height: 64px;
      display: flex;
      margin: 20px auto 17px;
      background: #fff;
      border-radius: 50%;
      position: relative;
      z-index: 2;
      img {
        position: relative;
        z-index: 2;
      }
      &::after {
        content: "";
        width: 48px;
        height: 48px;
        background: #ffc99f;
        border-radius: 50%;
        @include center;
      }
    }
  }
  .class {
    justify-content: center;
    > * + *::before {
      content: "";
      width: 1px;
      height: 16px;
      background: #e2e2e2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
    }
    span {
      padding: 0 8px;
      font: 16px/24px var(--SVN-GilroyBold);
      color: #304858;
      position: relative;
    }
  }
  .statistical {
    justify-content: space-evenly;
    padding: 43px 0 33px 0;
    border-radius: 0px 0px 24px 24px;
    .text {
      color: #faf9f5;
    }
  }
  &--cs1 {
    padding: 96px 0;
    .header {
      padding: 0;
      background: none;
    }
    .timeline {
      padding: 0;
    }
    h2.title {
      font-family: var(--SVN-GilroyBold);
      max-width: 616px;
      margin: 0 auto 32px;
    }
    .plan {
      margin-top: 0;
      background: none;
      border-radius: 0;
      padding-top: 0;
    }
  }
}
.statistical {
  background: var(--orange);
  .icon {
    width: 48px;
    margin-right: 24px;
  }
  .number {
    margin-bottom: 4px;
    font: 36px/40px var(--SVN-GilroyBold);
    color: #fff;
  }
  .text {
    font: 20px/28px var(--SVN-GilroyBold);
  }
}
.hoc10-feature {
  &--1 {
    margin-top: 24px;
    margin-bottom: 24px;

    .feature {
      margin-right: 163px;
    }
  }
  &--2 {
    background: #fffbf7;
    padding: 98px 0 140px;
    .feature {
      padding-top: 122px;
      margin-left: 190px;
    }
  }
  .feature {
    width: 441px;
  }
  h2.title {
    margin-bottom: 28px;
    text-align: left;
    span {
      display: initial;
      position: relative;
    }
  }
  .btn-pr {
    width: 176px;
    height: 60px;
    margin-top: 36px;
    font-size: 16px;
  }
}
.feature__list {
  > * + * {
    margin-top: 16px;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    background: rgba(#ff7707, 0.1);
    border-radius: 50%;
    margin-right: 12px;
    img {
      width: 24px;
    }
  }
  .text {
    flex: 1;
  }
}
.hoc10-register {
  min-height: 480px;
  background: var(--orange) url("#{$img-path}/bg-line.png") no-repeat 0 0 /
    cover;
  .img-wrapper {
    background: #fff;
    border-radius: 12px;
    position: absolute;
    &.sm {
      padding: 12px;
      width: 307px;
      top: 55px;
      left: 425px;
    }
    &.lg {
      width: 435px;
      padding: 19.6px;
      right: -20px;
      bottom: -37px;
      img {
        z-index: 2;
        position: relative;
      }
      &::before {
        content: "";
        width: 129px;
        height: 129px;
        background: url("#{$img-path}/decor2.svg") no-repeat 0 0 / contain;
        position: absolute;
        top: -64px;
        left: -52px;
        z-index: 0;
      }
    }
  }
  .content {
    padding-top: 156px;
    max-width: 441px;
    h3 {
      margin-bottom: 27px;
      &::after {
        content: "";
        width: 266px;
        height: 12px;
        display: block;
        margin-top: -3px;
        background: url("#{$img-path}/line4.svg") no-repeat 0 0 / contain;
      }
    }
    .btn_register {
      width: 176px;
      height: 60px;
      background: #ffffff;
      border-radius: 12px;
      color: var(--orange);
      font: 16px var(--SVN-GilroyBold);
      &:hover {
        background: #fcfcfc;
      }
    }
  }
}
.hoc10-feedback {
  padding: 130px 0 100px;
  background: linear-gradient(0deg, #faf9f5, #faf9f5);
  .header {
    margin-bottom: 18px;
  }
  h2.title {
    max-width: 400px;
    margin: 0 auto;
    &::after {
      content: "";
      width: 156px;
      height: 14px;
      display: block;
      margin: -4px auto 0;
      background: url("#{$img-path}/line5.svg") no-repeat 0 0 / contain;
    }
  }
  .feedback-list {
    margin: 0 -15px;
  }
  .feedback {
    padding: 25px 15px;
    &__content {
      padding: 32px 24px 30px;
      background: #fff;
      border-radius: 12px;
      box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.02);
    }
    .text {
      margin-bottom: 16px;
      line-height: 22px;
      color: #69676a;
      &::before {
        @include quote;
      }
    }
    .author {
      &__img {
        width: 44px;
        border-radius: 50%;
        margin-right: 12px;
      }
      &__content {
        width: calc(100% - 56px);
      }
      h5 {
        line-height: 24px;
        font-family: var(--SVN-GilroyBold);
        color: #3e3962;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #69676a;
      }
    }
  }
  .slick-arrow {
    top: initial;
    transform: translateY(0);
    bottom: -12px;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    left: 15px;
    transform: translateY(0) rotate(180deg);
  }
  &--st {
    padding: 96px 0 128px;
    .slick-arrow {
      top: 42%;
      transform: translateY(-50%);
      bottom: initial;
    }
    .slick-next {
      right: -55px;
    }
    .slick-prev {
      left: -55px;
      transform: translateY(0) rotate(180deg);
    }
  }
}
.hoc10-posts {
  padding: 140px 0;
  &.bg {
    padding: 96px 0 87px;
    background: #fffbf7;
    h2.title {
      text-align: center;
    }
    .post-list::after {
      content: "";
      width: 65px;
      height: 65px;
      background: url("#{$img-path}/decor6.svg") no-repeat 0 0 / contain;
      position: absolute;
      right: 15px;
      top: 207px;
      z-index: 2;
    }
  }
  .header {
    margin-bottom: 50px;
  }
  h2.title {
    text-align: left;
    span {
      display: initial;
    }
  }
  .post-list::after {
    @include decor3;
    top: -32px;
    right: 317px;
    z-index: -1;
  }
  .btn_viewmore {
    width: 108px;
    height: 40px;
    margin-left: auto;
    border-radius: 8px;
    font: 13px/20px var(--SVN-GilroyMedium);
  }
  .btn_viewall {
    display: none;
  }
}
.hoc10-bookaccess,
.hoc10-banner-register {
  min-height: 478px;
  background: var(--orange) url("#{$img-path}/bg-line.png") no-repeat 0 0 /
    cover;
  overflow: hidden;
  .container {
    min-height: 478px;
    position: relative;
    z-index: 2;
  }
  .content {
    max-width: 440px;
    padding-top: 127px;
  }
  .img-decor {
    width: 540px;
    position: absolute;
    bottom: 0;
    left: 556px;
    img {
      width: 100%;
    }
    &::after {
      @include decor1;
      width: 93px;
      height: 93px;
      top: -64px;
      right: -17px;
    }
  }
  .img-decor--cs1 {
    left: 525px;
    width: 600px;
    &::after {
      top: 32px;
      right: 20px;
      z-index: -1;
    }
  }
  .img-decor--cs2 {
    width: 770px;
    left: 415px;
    bottom: -89px;
    &::after {
      display: none;
    }
  }
  .img-decor--cs3 {
    width: 621px;
    left: 500px;
    &::after {
      width: 129px;
      height: 129px;
      top: -70px;
      right: -83px;
    }
  }
  h3 {
    margin-bottom: 29px;
    font-size: 36px;
    line-height: 48px;
    color: #fff;
    position: relative;
    span {
      display: block;
      font: 44px/64px var(--SVN-GilroyBold);
    }
    &::after {
      @include line1;
      position: absolute;
      left: 0;
      top: 46px;
    }
  }
  .btn-access,
  .btn-login {
    width: 288px;
    height: 68px;
    background: #ffffff;
    border-radius: 12px;
    color: var(--orange);
    font-size: 16px;
    font-family: var(--SVN-GilroyBold);
    position: relative;
    &::after {
      @include arrowl;
    }
    &:hover {
      background: #fcfcfc;
    }
  }
  .btn-login {
    width: 156px;
    height: 60px;
    &::after {
      display: none;
    }
  }
  &--cs1 {
    background: var(--orange) url("#{$img-path}/bg-cta.svg") no-repeat bottom
      center/contain;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: url("#{$img-path}/bg-line.png") no-repeat 0 0 / cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .content {
      padding-top: 126px;
    }
    h3 {
      font: 36px/48px var(--SVN-GilroyBold);
      color: #fff;
    }
    .btn-access::after {
      top: 35px;
      right: -65px;
    }
  }
}
.hoc10-banner-register {
  min-height: 432px;
  .container {
    min-height: 432px;
  }
  .content {
    h3 {
      font-family: var(--SVN-GilroyBold);
    }
    .btn-access {
      width: 200px;
      height: 60px;
      &::after {
        top: 40px;
        right: -72px;
      }
    }
  }
  .img-decor--cs3::after {
    top: -20px;
    right: -102px;
  }
}

.post {
  &__thumb {
    display: block;
    overflow: hidden;
    z-index: 3;
    position: relative;
  }
  .space {
    width: 4px;
    height: 4px;
    display: block;
    margin: 0 8px;
    border-radius: 50%;
    background: var(--orange);
  }
  time {
    color: #69676a;
    line-height: 20px;
  }
  h3 a:hover {
    color: var(--orange);
  }
  p {
    color: #69676a;
    line-height: 22px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .time,
  .view {
    margin-right: 24px;
    font: 14px/22px var(--SVN-GilroyMedium);
    color: #80909c;
  }
  .icon-time {
    background: url("#{$img-path}/icon-time4.svg") no-repeat 0 0 / contain;
  }
  .icon-view {
    background: url("#{$img-path}/icon-view.svg") no-repeat 0 0 / contain;
  }
}
.post-ty1 {
  margin-bottom: 30px;
  .category {
    text-transform: uppercase;
    color: var(--orange);
  }
  .post__thumb {
    border-radius: 12px;
    margin-bottom: 20px;
    height: 240px;
  }
  h3 {
    margin: 8px 0;
  }
}
.post-ty2 {
  padding: 24px 0;
  border-bottom: 1px solid #eaeced;
  .post__thumb {
    width: 273px;
  }
  .post__content {
    width: calc(100% - 273px);
    padding-left: 20px;
  }
  h3 {
    margin: 0 0 8px;
  }
  h3 a {
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  h3 a:hover {
    color: var(--orange);
  }
  p {
    margin-bottom: 12px;
    line-height: 20px;
    color: #606e78;
  }
}
.post-ty3 {
  padding: 20px 0;
  border-bottom: 1px solid #eaeced;
  .post__thumb {
    width: 125px;
    height: 78px;
    border-radius: 4px;
    overflow: hidden;
  }
  .post__content {
    width: calc(100% - 125px);
    padding-left: 12px;
  }
  h3 {
    margin: 0;
  }
  h3 a {
    font: 16px/24px var(--SVN-GilroyBold);
    color: var(--gray);
  }
}
.post-ty4 {
  margin-bottom: 24px;
  .post__thumb {
    height: 359px;
    margin-bottom: 0;
    border-radius: 12px 12px 0 0;
  }
  .post__content {
    padding: 16px 90px 16px 16px;
    color: var(--gray);
    position: relative;
  }
  .post__tag {
    width: 72px;
    height: 60px;
    padding: 6px 0 0 30px;
    background: url("#{$img-path}/triger.svg") no-repeat 0 0 / contain;
    text-align: center;
    font: 14px/22px var(--SVN-GilroyBold);
    color: #fff;
    border-radius: 0 12px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }
  .post__category {
    width: 100%;
    padding: 7px 0;
    background: #eaeced;
    border-radius: 12px 12px 0px 0px;
    font: 14px/22px var(--SVN-GilroyBold);
    color: var(--gray);
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
  span {
    display: block;
    margin-bottom: 2px;
    font-size: 10px;
    line-height: 16px;
  }
  h4 {
    font: 12px/18px var(--SVN-GilroyBold);
    text-transform: capitalize;
    color: #22323e;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
  .btn-read,
  .btn-view {
    width: 70px;
    height: 32px;
    position: absolute;
    right: 16px;
    font-size: 12px;
    line-height: 18px;
  }
  .btn-read {
    top: 18px;
  }
  .btn-view {
    background: #2a404f;
    border-radius: 6px;
    top: 14px;
    color: #fff;
  }
  .block {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background: rgba(#19252e, 0.4) url("#{$img-path}/icon-lock.svg") no-repeat
      center center;
    position: absolute;
    right: 8px;
    top: 75%;
    z-index: 9;
  }
}
.post-training {
  .post__thumb {
    height: 192px;
  }
}
.post-blog {
  .post__thumb {
    height: 197px;
  }
  .post__content {
    padding: 20px 24px 24px;
  }
  .category {
    display: inline-block;
    margin-bottom: 10px;
    padding: 3px 8px;
    background: #f4f5f6;
    border-radius: 4px;
    font: 14px/22px var(--SVN-GilroyMedium);
    color: var(--gray);
  }
  h3 {
    margin-bottom: 8px;
  }
  h3 a {
    font: 18px/26px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  p {
    margin-bottom: 24px;
    color: #80909c;
    line-height: 22px;
  }
}

//intro
.hoc10-intro {
  &--author {
    &.bg-gradient::after {
      height: 505px;
    }
  }
  &.bg-gradient::after {
    height: 610px;
  }
  .box-author {
    padding: 40px 0 44px;
    h1 {
      margin-bottom: 100px;
      display: block;
      text-align: center;
      font: 54px/64px var(--SVN-GilroyBold);
      color: var(--orange);
      text-transform: capitalize;
    }
  }
  .intro {
    width: 100%;
    max-width: 916px;
    margin: 0 auto;
    &::after {
      content: "";
      width: 609px;
      height: 532px;
      background: url("#{$img-path}/decor-intro1.svg") no-repeat 0 0 / contain;
      position: absolute;
      right: -41px;
      top: -42px;
    }
    &__book {
      position: absolute;
      right: -108px;
      bottom: -112px;
      z-index: 2;
    }
    &__content {
      width: 375px;
      padding: 40px;
      box-shadow: 0px 0px 80px rgba(213, 245, 253, 0.5);
      position: absolute;
      left: -94px;
      bottom: -44px;
      z-index: 2;
    }
    &__img {
      filter: drop-shadow(24px 24px 80px rgba(0, 0, 0, 0.08));
      z-index: 2;
    }
    h4 {
      margin-bottom: 16px;
      color: var(--orange);
    }
    li {
      display: flex;
      align-items: flex-start;
      line-height: 22px;
      &::before {
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 50%;
        margin: 9px 9px 0 5px;
        background: #2a404f;
      }
      p {
        flex: 1;
      }
      span {
        margin-right: 3px;
        font-family: var(--SVN-GilroyMedium);
        color: #2a404f;
      }
    }
  }
  .decor-gt::after {
    content: "";
    width: 1217px;
    height: 562px;
    background: url("#{$img-path}/decor-gt1.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: -46px;
    left: -199px;
  }
  .text-intro {
    padding: 8px 0 64px;
    text-align: center;
    .title,
    p {
      position: relative;
      z-index: 2;
    }
    .title {
      margin-bottom: 20px;
    }
    p {
      max-width: 505px;
      margin: 0 auto;
      font: calc($fontSizeContent/22px) var(--SVN-GilroyMedium);
      font-size: 18px;
      text-align: center;
    }
  }
  &--2 {
    background: #fffbf7;
    .text-intro {
      p {
        max-width: 730px;
        &.new-text {
          max-width: 1100px;
        }
      }
    }
  }
}
.hoc10-newpaper {
  padding: 128px 0 100px;
  .container::after {
    content: "";
    width: 1122px;
    height: 141px;
    background: url("#{$img-path}/decor-intro2.svg") no-repeat 0 0 / contain;
    position: absolute;
    z-index: -1;
    top: -38px;
    left: 5px;
  }
  .header {
    max-width: 480px;
    margin: 0 auto;
  }
  .newpaper-list {
    padding-top: 35px;
    &::after {
      content: "";
      width: 100%;
      height: 4px;
      background: var(--orange);
      border-radius: 9999px;
      position: absolute;
      left: 0;
      top: 54px;
      z-index: -1;
    }
  }
  .year {
    width: 79px;
    height: 32px;
    margin-bottom: 22px;
    background: var(--orange);
    box-shadow: 0px 0px 0px 4px #fff2e5;
    font: 16px var(--SVN-GilroyBold);
    color: #fff;
    border-radius: 9999px;
  }
  .link {
    padding-right: 30px;
    > * + * {
      margin-top: 20px;
    }
    a {
      display: block;
      color: #2a404f;
      font-size: 16px;
      line-height: 24px;
    }
    a:hover {
      color: var(--orange);
    }
  }
  p {
    margin-top: 8px;
    padding-right: 25px;
    line-height: 22px;
    color: #606e78;
  }
  .slick-slide {
    padding-top: 4px;
    &:first-child .year {
      margin-left: 4px;
    }
    &:last-child .year {
      margin-left: 4px;
    }
  }
  .slick-arrow {
    transform: translateY(0);
    top: 35px;
  }
  .slick-next {
    right: -66px;
  }
  .slick-prev {
    transform: translateY(0) rotate(180deg);
    left: -66px;
  }
  .slick-dots {
    margin-top: 40px;
  }
  .line8::after {
    width: 178px;
    height: 9px;
    right: 30px;
    left: initial;
    transform: translateX(0);
  }
}
.hoc10-team {
  padding: 96px 0 128px;
  .header {
    text-align: center;
    margin-bottom: 40px;
    p {
      margin-top: 31px;
      font-family: var(--SVN-GilroyMedium);
      line-height: 24px;
    }
    span {
      color: var(--orange);
      font-family: var(--SVN-GilroyBold);
    }
  }
  .team-list {
    margin: 0 -15px;
  }
  .author-list {
    margin: 45px -15px 0;
  }
  .item {
    padding: 56px 15px 56px;
    position: relative;
  }
  .avatar {
    width: 114px;
    padding: 9px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -56px;
    img {
      border-radius: 50%;
    }
  }
  .bg-sh {
    padding: 68px 24px 24px;
    text-align: center;
  }
  .name {
    margin-bottom: 8px;
    font: 16px/24px var(--SVN-GilroyBold);
    color: var(--orange);
    text-transform: uppercase;
  }
  .position {
    margin-bottom: 12px;
    font: 12px/18px var(--SVN-GilroyBold);
  }
  .summary {
    margin-bottom: 20px;
    line-height: 22px;
  }
  .ps {
    display: inline-block;
    padding: 4px 12px;
    background: #f4f5f6;
    border-radius: 4px;
    color: #2a404f;
    line-height: 22px;
  }
  .btn-viewmore {
    display: block;
    margin-top: 24px;
    font: 16px/24px var(--SVN-GilroyBold);
    color: #2a404f;
    &:hover {
      color: var(--orange);
    }
  }
  .slick-next {
    right: -55px;
  }
  .slick-prev {
    left: -55px;
  }
  .slick-dots {
    margin-top: 0;
  }
}
.hoc10-team--ex {
  padding: 100px 0;
  h2.title {
    span {
      display: inline-block;
    }
    span::after {
      @include line5;
      display: block;
      margin: 5px auto 0;
    }
  }
  .slick-dots {
    display: none !important;
  }
  .btn-more {
    width: 200px;
    height: 48px;
    margin: 0 auto;
  }
}
.hoc10-team--exp {
  padding: 96px 0 120px;
  .header {
    &::after {
      content: "";
      width: 46px;
      height: 75px;
      background: url("#{$img-path}/decor14.svg") no-repeat 0 0 / contain;
      position: absolute;
      right: -90px;
      top: -45px;
    }
    p {
      font-size: 16px;
      margin-top: 12px;
      position: relative;
    }
  }
  .item {
    padding: 56px 0 40px;
  }
  .pagination {
    margin-top: 0;
  }
  .container > div {
    position: relative;
    z-index: 2;
  }
  .container::after {
    content: "";
    width: 1265px;
    height: 657px;
    background: url("#{$img-path}/decor-team.svg") no-repeat 0 0 / contain;
    position: absolute;
    left: -70px;
    top: 550px;
  }
}
.hoc10-intro-feature {
  padding: 80px 0 11px;
  .feature-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    li:nth-child(even) {
      margin-left: auto;
    }
    li {
      display: flex;
      align-items: center;
      width: 349px;
      height: 120px;
      padding: 24px;
      margin-bottom: 89px;
      border: 1.5px solid #eaeced;
      border-radius: 12px;
      position: relative;
      &::after {
        content: "";
        width: 105px;
        height: 69px;
        background: url("#{$img-path}/line7.svg") no-repeat 0 0 / contain;
        position: absolute;
        top: 32px;
        z-index: 2;
      }
      &:nth-child(1)::after {
        top: 30px;
        right: -99px;
        transform: rotateY(180deg);
      }
      &:nth-child(2)::after {
        left: -100px;
      }
      &:nth-child(3)::after {
        top: 18px;
        right: -99px;
        transform: rotate(180deg);
      }
      &:nth-child(4)::after {
        transform: rotateX(180deg);
        top: 16px;
        left: -100px;
      }
    }
    .img-wrapper {
      width: 72px;
    }
    .text {
      width: calc(100% - 72px);
      padding-left: 24px;
      font: 18px/26px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    .status {
      background: #fff;
      padding: 3px 6px;
      border: 1px solid #ff7707;
      border-radius: 6px;
      font-size: 10px;
      line-height: 18px;
      color: var(--orange);
      position: absolute;
      left: 24px;
      top: -12px;
    }
  }
  .logo-h10 {
    width: 277px;
    height: 277px;
    border: 1.5px solid #eaeced;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 106px;
  }
}
.hoc10-object {
  padding: 98px 0 23px;
  background: url("#{$img-path}/bg-object.svg") no-repeat 50% 0;
  background-size: cover;
  h2.title {
    span {
      display: inline-block;
    }
    span::after {
      @include line8;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }
  .object {
    width: 100%;
    margin: 65px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    &::after {
      content: "";
      width: 1336px;
      height: 562px;
      background: url("#{$img-path}/decor-object.svg") no-repeat 0 0 / contain;
      position: absolute;
      top: -137px;
      left: -110px;
    }
    .ob {
      padding: 3px 6px;
      background: #ff7707;
      box-shadow: 24px 24px 40px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      font: 14px/18px var(--SVN-GilroyMedium);
      color: #fff;
      position: absolute;
      left: 17px;
      top: -12px;
    }
    &__item {
      width: 100%;
      max-width: 376px;
      min-height: 144px;
      margin-bottom: 77px;
      padding: 20px 12px;
      background: #ffffff;
      box-shadow: 24px 24px 40px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      z-index: 2;
      &:nth-child(even) {
        margin-left: auto;
      }
    }
    &__avt {
      width: 274px;
      height: 274px;
      border: 1px solid #aab3b9;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 46px;
      z-index: 2;
      img {
        border-radius: 50%;
        background: #ffe9d6;
      }
      .avt:nth-child(1) {
        top: 10px;
        left: -24px;
      }
      .avt:nth-child(2) {
        top: 10px;
        right: -24px;
      }
      .avt:nth-child(3) {
        top: 176px;
        left: -24px;
      }
      .avt:nth-child(4) {
        top: 176px;
        right: -24px;
      }
    }
    .avt {
      width: 88px;
      height: 88px;
      padding: 8.8px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 24px 24px 40px rgba(0, 0, 0, 0.04);
      position: absolute;
    }
    ul {
      > * + * {
        margin-top: 8px;
      }
      li {
        display: flex;
        align-items: center;
        font: 16px/24px var(--SVN-GilroyMedium);
        color: var(--gray);
      }
      li::before {
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        margin: 0 10px;
        background: var(--gray);
        border-radius: 50%;
      }
      span {
        flex: 1;
        font-size: 20px/24px var(--SVN-GilroyMedium);
      }
    }
  }
}
.hoc10-vepic {
  padding: 100px 0;
  .header {
    margin-bottom: 24px;
    h2.title {
      max-width: 566px;
      margin: 0 auto 48px;
    }
    p {
      color: #606e78;
      text-align: center;
    }
  }
  .video {
    max-width: 790px;
    margin: 0 auto;
    padding: 24px;
    background: #ffffff;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
    }
    &::before {
      width: 63px;
      height: 67px;
      background: url("#{$img-path}/decor13.svg") no-repeat 0 0 / contain;
      bottom: -83px;
      left: 32px;
    }
    &::after {
      width: 10px;
      height: 38px;
      background: url("#{$img-path}/decor12.svg") no-repeat 0 0 / contain;
      bottom: 0;
      right: -112px;
    }
    &__play {
      width: 104px;
      @include center;
      z-index: 9;
    }
  }
  .intro-list {
    margin-top: 100px;
    padding-top: 12px;
    border-top: 1px solid #f4f5f6;
  }
  .btn-viewmore {
    width: 200px;
    height: 48px;
    margin: 40px auto 0;
  }
  .line8::after {
    width: 100px;
    background: url("#{$img-path}/line9.svg") no-repeat 0 0 / contain;
  }
}
.hoc10-onepercent {
  padding: 100px 0;
  .container::after {
    content: "";
    width: 1101px;
    height: 435px;
    background: url("#{$img-path}/decor-onepercent.svg") no-repeat 0 0 / contain;
    position: absolute;
    left: 25px;
    top: -60px;
    z-index: -1;
  }
  h2.title {
    text-align: left;
    span {
      display: inline-block;
    }
    span::after {
      @include line5;
      display: block;
    }
  }
  .intro {
    h2.title span::after {
      margin: 1px auto 15px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .team {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h2.title {
      &-pc {
        span::after {
          margin: 1px 0 0 160px;
        }
      }
      &-mobile {
        display: none;
      }
    }
  }
  .project {
    margin-top: 42px;
    &__monkey {
      padding: 8px 16px;
      &--mobile {
        display: none;
        a {
          max-width: 100%;
        }
      }
      &--pc {
        a {
          max-width: 50%;
        }
        a:nth-child(1),
        a:nth-child(2) {
          border-bottom: 1px solid #eaeced;
        }
        a:nth-child(1),
        a:nth-child(3) {
          border-right: 1px solid #eaeced;
        }
      }
      a {
        display: block;
        padding: 6px;
        text-align: center;
        width: 100%;
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .project > div {
    width: 255px;
    height: 145px;
    margin-right: 30px;
    background: #ffffff;
    border: 1px solid #eaeced;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .btn-viewmore {
    width: 200px;
    height: 48px;
    margin: 40px auto 0;
  }
}
.hoc10-intro-sgk {
  padding: 0 0 100px;
  &.bg-gradient::after {
    height: 552px;
  }
  .header {
    padding: 85px 0 35px;
  }
  h2.title {
    span {
      display: inline-block;
    }
  }
  .video {
    z-index: 2;
  }
  .container::after {
    content: "";
    width: 980px;
    height: 499px;
    background: url("#{$img-path}/decor-gt4.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: 48px;
    left: 94px;
    z-index: 1;
  }
  .intro-list {
    padding-top: 64px;
    margin-top: 72px;
    border-top: 1px solid #f4f5f6;
    &::after {
      content: "";
      width: 1255px;
      height: 395px;
      background: url("#{$img-path}/decor-gt5.svg") no-repeat 0 0 / contain;
      position: absolute;
      top: 30px;
      left: -100px;
      z-index: -1;
    }
  }
}
.hoc10-feature-sgk {
  padding: 100px 0 80px;
  .header {
    margin-bottom: 48px;
  }
  .featured {
    text-align: center;
    .img-wrapper {
      width: 96px;
      margin: 0 auto 32px;
    }
    h4 {
      font: 20px/28px var(--SVN-GilroyBold);
      margin-bottom: 8px;
      color: var(--gray);
    }
    p {
      line-height: 22px;
      font-size: 18px;
    }
  }
  .video {
    margin-bottom: 100px;
  }
  &.bg-gradient::after {
    height: 100%;
  }
}
.hoc10-type-sgk {
  padding: 0 0 45px;
  .header {
    margin-bottom: 77px;
    padding-top: 80px;
    border-top: 1px solid #feeecd;
    position: relative;
    z-index: 2;
  }
  .container::after {
    content: "";
    width: 1206px;
    height: 507px;
    background: url("#{$img-path}/decor-book-type.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: 105px;
    left: -20px;
    z-index: 1;
  }
  .sgk {
    z-index: 2;
    &__img {
      width: 679px;
    }
    &__type {
      width: 238px;
      height: 238px;
      margin: 77px 0 0 65px;
      border: 1px solid #aab3b9;
      border-radius: 50%;
      position: relative;
      span {
        width: 118px;
        height: 118px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--orange);
        border-radius: 50%;
        font: 18px/24px var(--SVN-GilroyMedium);
        color: #fff;
        text-align: center;
        position: absolute;
      }
      span:nth-child(1) {
        top: -76px;
        left: 50%;
        transform: translateX(-50%);
      }
      span:nth-child(2) {
        top: 117px;
        left: -54px;
      }
      span:nth-child(3) {
        top: 117px;
        right: -65px;
      }
    }
  }
}
.hoc10-program {
  padding: 100px 0;
  .header {
    margin-bottom: 36px;
  }
  h2.title {
    margin-bottom: 24px;
  }
  .hoc10-form {
    input {
      max-width: 598px;
      padding: 0 15px 0 40px;
      background: url("#{$img-path}/icon-search1.svg") no-repeat 12px center;
    }
    .btn-submit {
      margin-left: 12px;
    }
  }
  .subject {
    width: 25%;
    padding: 12px 15px;
    position: relative;
    &__img {
      width: 100%;
      height: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      background: #fffbf7;
      border-radius: 12px;
    }
    &__name {
      font: 16px/24px var(--SVN-GilroyBold);
      color: var(--gray);
      text-align: center;
    }
    &__pdf {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/icon-pdf.svg") no-repeat 0 0 / contain;
      position: absolute;
      top: 24px;
      right: 27px;
    }
  }
}
.hoc10-target {
  padding: 88px 0 96px;
  .header {
    margin-bottom: 56px;
  }
  .target-list::after {
    content: "";
    width: 240px;
    height: 545px;
    background: url("#{$img-path}/img-dc6.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: -20px;
    right: 60px;
  }
  .target {
    margin-top: 18px;
    h4 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--orange);
      margin-bottom: 12px;
    }
    p {
      font: 14px/22px var(--SVN-GilroyMedium);
    }
    .icon {
      width: 191px;
      display: flex;
      margin-right: 0;
      align-items: flex-start;
      &::after {
        content: "";
        width: 95px;
        height: 1px;
        background: #eaeced;
        display: block;
        margin: 18px 0 0 24px;
      }
    }
    .content {
      width: calc(100% - 191px);
      max-width: 540px;
    }
  }
}
.intro-list {
  .content {
    text-align: center;
  }
  .img-wrapper {
    width: 96px;
    margin: 0 auto 24px;
  }
  h4 {
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--orange);
    margin-bottom: 10px;
  }
  p {
    max-width: 330px;
    margin: 0 auto;
    font: calc($fontSizeContent/22px) var(--SVN-GilroyMedium);
    font-size: 18px;
  }
}
.hoc10-form {
  input {
    height: 44px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaeced;
    border-radius: 6px;
    &::placeholder {
      color: #aab3b9;
    }
    &:focus {
      outline: none;
    }
  }
  .btn-submit {
    height: 44px;
    width: 120px;
    border-radius: 8px;
  }
}
.hoc10-intro-video {
  padding: 104px 0 120px;
  .video {
    max-width: 608px;
    margin-left: auto;
    margin-right: initial;
    &::after {
      content: "";
      width: 688px;
      height: 447px;
      background: url("#{$img-path}/decor-vd.svg") no-repeat 0 0 / contain;
      position: absolute;
      top: -37px;
      left: -56px;
      z-index: -1;
    }
  }
  .text {
    margin-bottom: 24px;
    max-width: 444px;
    font: 18px/26px var(--SVN-GilroyMedium);
    color: #22323e;
    &::before {
      @include quote;
    }
  }
  .author {
    &__avt {
      width: 48px;
    }
    &__name {
      padding-left: 20px;
      font-family: var(--SVN-GilroyMedium);
      color: var(--gray);
    }
  }
}
.hoc10-sgk {
  margin-top: 70px;
  padding: 100px 0 120px;
  .header {
    max-width: 648px;
    margin: 0 auto;
  }
  .line8::after {
    width: 178px;
    height: 9px;
    left: initial;
    right: 22px;
    bottom: -10px;
  }
  .item {
    padding: 50px 15px;
    &__img {
      margin-bottom: 16px;
      border-radius: 6px;
      overflow: hidden;
    }
    &__name {
      color: #2a404f;
      font: 14px/20px var(--SVN-GilroyBold);
      text-align: center;
    }
    .bg-sh {
      padding: 24px;
    }
  }
  .slick-dots {
    margin-top: 0;
  }
  .slick-next {
    right: -55px;
  }
  .slick-prev {
    left: -55px;
  }
}

//onepercent
.onepercent-intro {
  .text {
    padding: 10px 0 80px;
    text-align: center;
    h1,
    p {
      position: relative;
      z-index: 2;
    }
    p {
      max-width: 583px;
      margin: 0 auto;
      font-size: 18px;
    }
    h1 {
      margin-bottom: 20px;
      color: var(--gray);
    }
    h1 span {
      color: var(--orange);
    }
  }
}
.onepercent-value {
  padding: 100px 0;
  .text {
    max-width: 502px;
    h3 {
      font: 36px/48px var(--SVN-GilroyBold);
      color: var(--gray);
      margin-bottom: 16px;
    }
    p {
      font: 18px/26px var(--SVN-GilroyMedium);
    }
  }
  .img-wrapper {
    border-radius: 8px;
    padding: 24px;
    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
    }
    &::before {
      width: 114px;
      height: 80px;
      background: url("#{$img-path}/decor7.svg") no-repeat 0 0 / contain;
      top: -22px;
      left: -45px;
    }
    &::after {
      @include decor6;
      bottom: -40px;
      right: -33px;
    }
    img {
      width: 100%;
    }
  }
}
.onepercent-develop {
  padding: 100px 0;
  .line8::after {
    width: 215px;
    height: 11px;
    bottom: -13px;
    transform: translateX(0);
    left: 140px;
  }
  .header {
    margin-bottom: 50px;
  }
  .project {
    &.monkey {
      margin-bottom: 76px;
      h4 {
        color: #e11d10;
        margin-bottom: 2px;
      }
    }
    &.hoc10 {
      flex-direction: row-reverse;
      justify-content: space-between;
      .project__content {
        padding-left: 0;
        max-width: 496px;
      }
      p {
        max-width: 100%;
      }
    }
    &__logo {
      width: 476px;
      height: 270px;
      background: #fff;
      box-shadow: 24px 24px 80px rgba(255, 119, 7, 0.08);
      border-radius: 8px;
      &--monkey {
        padding: 32px 38px;
        a {
          width: 50%;
          height: 50%;
          display: block;
        }
        a:nth-child(1) {
          border-right: 1px solid #eaeced;
          border-bottom: 1px solid #eaeced;
        }
        a:nth-child(2) {
          border-bottom: 1px solid #eaeced;
        }
        a:nth-child(3) {
          border-right: 1px solid #eaeced;
        }
        img {
          width: 131px;
          display: block;
          margin: 0 auto;
        }
      }
      &--hoc10 {
        img {
          width: 306px;
        }
      }
    }
    &__content {
      width: calc(100% - 476px);
      padding-left: 64px;
    }
    p {
      max-width: 474px;
      font-size: 18px;
      line-height: 26px;
    }
    h4 {
      font: 24px/32px var(--SVN-GilroyBold);
      margin-bottom: 12px;
      color: var(--orange);
    }
  }
}
.onepercent-office {
  padding: 100px 0;
  h2.title {
    text-align: left;
    margin-bottom: 24px;
  }
  .text {
    max-width: 474px;
  }
  .img-wrapper {
    border-radius: 8px;
    padding: 24px;
    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
    }
    &::before {
      width: 114px;
      height: 80px;
      background: url("#{$img-path}/decor7.svg") no-repeat 0 0 / contain;
      bottom: -32px;
      left: -45px;
    }
    &::after {
      width: 129px;
      height: 129px;
      background: url("#{$img-path}/decor6.svg") no-repeat 0 0 / contain;
      top: -40px;
      right: -37px;
    }
    img {
      width: 100%;
    }
  }
  .address,
  .phone {
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--gray);
    p,
    a {
      font-size: 18px;
      line-height: 26px;
      font-family: var(--SVN-GilroyRegular);
    }
  }
  .address {
    margin-bottom: 24px;
    h4 {
      margin-bottom: 8px;
    }
  }
  .phone {
    a {
      color: #606e78;
    }
  }
}

//contact
.hoc10-contact {
  padding-bottom: 120px;
  .tab-content {
    .phone,
    .email {
      padding: 12px;
      margin-right: 24px;
      background: #fffbf7;
      border-radius: 8px;
      font: 18px/26px var(--SVN-GilroyMedium);
      color: var(--orange);
    }
    .icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
  form {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #eaeced;
  }
  article {
    font-size: 16px;
    line-height: 24px;
    color: var(--gray);
    h4,
    h5 {
      margin-bottom: 8px;
      font-family: var(--SVN-GilroyBold);
    }
    h4 {
      font-size: 20px;
      line-height: 28px;
    }
    h5 {
      font-size: 18px;
      line-height: 26px;
    }
    p {
      margin-bottom: 24px;
      font-size: 17px;
    }
    a {
      color: var(--orange);
    }
    p strong {
      font-family: var(--SVN-GilroyBold);
    }
  }
}
.hoc10-dc {
  margin-top: 120px;
}

//register
.box-register {
  padding: 80px 0;
  h1.title {
    display: block;
    text-align: center;
    font: 36px/48px var(--SVN-GilroyBold);
    color: #19252e;
  }
  .box-choose {
    margin-top: 64px;
  }
  .object {
    margin: 0 8px;
    padding: 21px;
    position: relative;
    text-align: center;
    border: 3px solid transparent;
    &.active {
      border: 3px solid #ff7707;
    }
    span {
      display: block;
    }
    &__avt {
      width: 120px;
      height: 120px;
      padding: 12px;
      margin: 0 auto 12px;
      background: #f4f5f6;
      border-radius: 50%;
      img {
        width: 96px;
        background: #ffe9d6;
        border-radius: 50%;
      }
    }
    &__name {
      font: 16px/24px var(--SVN-GilroyBold);
      color: #2a404f;
      text-align: center;
    }
    .checked {
      width: 22px;
      height: 22px;
      display: block;
      margin: 9px auto 0;
      border: 1.5px solid #aab3b9;
      border-radius: 50%;
      position: relative;
      &::after {
        content: "";
        width: 22px;
        height: 22px;
        display: none;
        border: 7px solid var(--orange);
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    label {
      margin: 0 auto;
    }
    input:checked + .checked {
      border-width: 0px;
      &::after {
        display: block;
      }
    }
    input {
      position: absolute;
      opacity: 0;
    }
  }
  .btn-next {
    width: 356px;
    height: 48px;
    max-width: 100%;
    background: #eaeced;
    margin: 64px auto 0;
    border-radius: 6px;
    color: #aab3b9;
    &:hover {
      background: #eaeced;
      color: #aab3b9;
    }
    &.active {
      background: var(--orange);
      color: #fff;
    }
  }
}
.box-login {
  margin: 30px 0;
  h4 {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #80909c;
    span {
      padding: 0 21px;
      white-space: nowrap;
    }
    &::after,
    &::before {
      content: "";
      width: 96px;
      height: 1px;
      display: block;
      background: #f4f5f6;
      border-radius: 2px;
    }
  }
  .login-list {
    a {
      width: 168px;
      height: 44px;
      max-width: calc(33.33% - 16px);
      margin: 0 8px;
      border-radius: 4px;
      color: #fff;
    }
    a:hover {
      -webkit-filter: saturate(1.4);
      filter: saturate(1.4);
    }
    .icon {
      margin-right: 8px;
    }
    .facebook {
      background: #1877f2;
    }
    .google {
      background: #de4d3b;
    }
    .monkey {
      background: #ee202e;
    }
  }
}
.form-h10 {
  &.mw-535 {
    max-width: 535px;
    margin: 0 auto;
  }
  .form-group {
    margin-bottom: 24px;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-group {
      width: calc(50% - 10px);
    }
  }
  .box-btn {
    justify-content: space-between;
    a,
    button {
      width: 260px;
      height: 48px;
      border-radius: 6px;
    }
    .btn-back {
      border: 1px solid #ff7707;
      color: var(--orange);
      &:hover {
        background: var(--orange);
        color: #fff;
        border: none;
      }
    }
    .btn-next {
      margin: 0;
      background: var(--orange);
      color: #fff;
      font-size: 16px;
      &:hover {
        background: #e36b09;
        color: #fff;
      }
    }
  }
  .box-btn-fw {
    a,
    button {
      width: calc(50% - 15px);
    }
  }
  .text {
    max-width: 404px;
    margin: 24px auto 0;
    color: #2a404f;
    line-height: 22px;
    text-align: center;
    a {
      text-decoration: underline;
    }
  }
  label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: #2a404f;
    font: 14px/20px var(--SVN-GilroyRegular);
    font-size: 16px;
    sup {
      color: #c00;
      margin: 9px 0 0 4px;
    }
  }
  input,
  textarea,
  select {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaeced;
    border-radius: 6px;
    color: var(--gray1);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #aab3b9;
    }
  }
  input,
  select {
    padding: 0 16px;
    height: 48px;
  }
  textarea {
    padding: 8px 12px;
    height: 100px;
  }
  .notifi {
    margin-top: 6px;
    font-size: 12px;
    line-height: 18px;
    color: var(--orange);
  }
  .btn-submit {
    width: 318px;
    height: 48px;
    max-width: 100%;
    margin-left: auto;
    border-radius: 6px;
    font-size: 16px;
  }
  .btn-reset {
    border: 1px solid #ff7707;
    border-radius: 6px;
    color: var(--orange);
    &:hover {
      background: var(--orange);
      color: #fff;
    }
  }
}

.box-select {
  &.show {
    .select-dropdown {
      display: block;
    }
    .select-list {
      border: 1px solid #606e78;
      .dropdown {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
.select-list {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 11px 48px 11px 16px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  .placeholder {
    position: absolute;
    color: #aab3b9;
    display: block;
    width: 90%;
  }
  ul {
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    z-index: 2;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ul > * + * {
    margin-left: 12px;
  }
  .dropdown {
    width: 24px;
    height: 24px;
    background: url("#{$img-path}/caret-down.svg") no-repeat;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 1px 8px;
    border-radius: 9999px;
    background: #eaeced;
    font-size: 14px;
    color: #80909c;
    line-height: 22px;
    white-space: nowrap;
  }
  .close {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    background: url("#{$img-path}/icon-close2.svg") no-repeat 0 0 / contain;
    font-size: 0;
    opacity: 1;
  }
}
.select-dropdown {
  width: 100%;
  max-height: 205px;
  display: none;
  padding: 8px 0 0;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #eaeced;
  border-radius: 6px;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #aab3b9;
    border-radius: 9999px;
  }
  label {
    padding: 8px 17px;
    line-height: 22px;
    margin-bottom: 0;
    position: relative;
  }
  label:hover {
    background: #f4f5f6;
  }
  .checked {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 10px;
    background: #ffffff;
    border: 1.5px solid #aab3b9;
    box-sizing: border-box;
    border-radius: 4px;
  }
  input {
    opacity: 0;
    position: absolute;
  }
  input:checked + .checked {
    background: var(--orange) url("#{$img-path}/icon-check2.svg") no-repeat
      center center;
    border: none;
  }
}
.hoc10-tab {
  background: #ffffff;
  border-radius: 12px;
  > * + * {
    border-top: 1px solid #e9e9e9;
  }
  li {
    padding: 24px;
    width: 100%;
  }
  li a {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
  }
  span {
    flex: 1;
    font-size: 17px;
  }
  .active {
    color: var(--orange);
    font-family: var(--SVN-GilroyBold);
  }
  .icon {
    margin-right: 8px;
  }
}
.hoc10-tab-content {
  background: #fff;
  padding: 32px;
  border-radius: 12px;
  h2.title {
    color: var(--orange);
    margin-bottom: 24px;
    text-align: left;
  }
  .form-row {
    margin: 0;
  }
}

//thongtincanhan
.hoc10-information {
  padding-bottom: 80px;
  .box-upload {
    .image-holder {
      width: 160px;
      height: 160px;
      overflow: hidden;
      border-radius: 50%;
    }
  }
  .form-h10 {
    .form-group {
      margin-bottom: 16px;
    }
    .box-btn {
      margin-top: 24px;
    }
    .box-btn.mt-14 {
      margin-top: 14px;
    }
  }
  .upload {
    width: 120px;
    position: absolute;
    left: 185px;
    top: 55px;
    button {
      width: 120px;
      height: 48px;
      padding: 0;
      border: 1px solid #606e78;
      border-radius: 6px;
      color: #606e78;
      text-align: center;
      font: 12px var(--SVN-GilroyBold);
    }
    input {
      width: 100%;
      opacity: 0;
      position: absolute;
    }
  }
  .note {
    margin: 12px 0 24px;
    color: #606e78;
    line-height: 22px;
    > * + * {
      margin-top: 4px;
    }
  }
  .box-btn {
    > * + * {
      margin-left: 12px;
    }
    a,
    button {
      width: 120px;
      height: 48px;
      border-radius: 6px;
    }
  }
  .image-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.hoc10-setting {
  padding: 48px 0 80px;
  .title {
    display: block;
    padding: 32px 32px 16px 40px;
    border-bottom: 1px solid #eaeced;
    color: var(--orange);
    font: 36px/48px var(--SVN-GilroyBold);
    text-align: left;
  }
  .setting {
    width: calc(100% - 30px);
    max-width: 644px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 12px;
    h4 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--black);
    }
    .text {
    }
    &__email {
      padding: 24px 40px;
      border-bottom: 1px solid #eaeced;
      h4 {
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 24px;
        color: #aab3b9;
      }
    }
    &__password {
      padding: 24px 40px 32px;
      h4 {
        margin-bottom: 16px;
      }
      .btn-change {
        width: 100%;
        height: 48px;
        border-radius: 6px;
        font-size: 16px;
      }
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
    margin: 0 0 0 auto;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--orange);
  }

  input:focus + .slider {
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 28px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.password-setting {
  padding: 0 0 80px;
  .logo {
    width: 104px;
    margin: 0 auto;
    padding: 32px 0;
  }
  .box-reset {
    max-width: 600px;
    margin: 0 auto;
    padding: 48px 32px;
  }
  .img-wrapper {
    width: 96px;
    margin-bottom: 12px;
  }
  h2.title {
    margin-bottom: 16px;
    text-align: left;
  }
  p {
    max-width: 470px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: var(--gray);
  }
  p span {
    color: var(--orange);
  }
  .box-code {
    margin-bottom: 12px;
    h5 {
      margin-bottom: 8px;
      color: var(--gray);
      font-family: var(--SVN-GilroyBold);
    }
    .code {
      width: 100%;
      height: 56px;
      background: #fffbf7;
      border: 1px solid #ffd99b;
      border-radius: 6px;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--orange);
    }
  }
  .social {
    margin: 28px 0 32px;
    a {
      width: 134px;
      height: 48px;
      margin-right: 15px;
      border-radius: 6px;
      color: #fff;
    }
    .facebook {
      background: #024798;
    }
    .youtube {
      background: #e11d10;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .hotline,
  .mail {
    font-size: 20px;
    line-height: 28px;
    color: var(--black);
    a {
      font: 20px/28px var(--SVN-GilroyBold);
      color: var(--orange);
    }
    .icon {
      margin-right: 8px;
    }
  }
  .hotline {
    margin-bottom: 8px;
  }
}

//reader
.hoc10-reader {
  background: #f7f9fc;
  .header {
    width: 100%;
    height: 56px;
    padding: 16px;
    background: #f4f5f6;
    align-items: center;
    position: relative;
    .btn-back {
      color: var(--gray);
      font: 14px/20px var(--SVN-GilroyBold);
    }
    .name {
      text-align: center;
      color: var(--gray);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .time {
      margin: 0 16px 0 auto;
      .icon {
        margin-right: 0;
      }
      p {
        color: #fff;
        margin-right: 8px;
        line-height: 22px;
      }
      &.active {
        .icon-time {
          width: 24px;
          height: 24px;
          background: url("#{$img-path}/icon-time1.svg") no-repeat 0 0 / contain;
        }
      }
    }
    .guide {
      margin-left: auto;
      .icon {
        cursor: pointer;
        margin-right: 0;
      }
      a {
        width: 96px;
        height: 50px;
        padding-top: 12px;
        background: url("#{$img-path}/bg-tt.svg") no-repeat 0 0 / contain;
        color: var(--gray);
        white-space: nowrap;
        text-align: center;
        line-height: 38px;
        position: absolute;
        right: -8px;
        opacity: 0;
        visibility: hidden;
      }
      &:hover a {
        opacity: 1;
        visibility: visible;
      }
    }
    .btn-fullscreen {
      width: 24px;
      height: 24px;
      margin-left: 20px;
    }
  }
  .main {
    height: calc(100vh - 112px);
    align-items: flex-start;
  }
  .sidebar {
    width: 230px;
    height: 100%;
    background: #fff;
    padding: 20px 3px 0 0;
    position: relative;
    &::after {
      content: "";
      height: 100%;
      width: 1px;
      background: #f4f5f6;
      position: absolute;
      top: 0;
      right: 12px;
    }
    h3 {
      margin-bottom: 16px;
      padding-left: 12px;
    }
    h4 {
      display: flex;
      padding: 10px 10px 10px 32px;
      font: 14px/20px var(--SVN-GilroyBold);
      color: var(--gray);
      background: #f7f9fc;
      position: relative;
      cursor: pointer;
    }
    h4::before {
      content: "";
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/caret-down.svg") no-repeat;
      position: absolute;
      left: 0;
      top: 8px;
      transform: rotate(-90deg);
    }
  }
  .table-content {
    width: calc(100% - 0px);
    height: calc(100% - 44px);
    padding: 0 4px 0 12px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      padding-right: 3px;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background: #eaeced;
      border-radius: 9999px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    > li {
      margin-bottom: 2px;
    }
    a {
      display: block;
      line-height: 22px;
      position: relative;
    }
    a {
      padding: 1px 0 1px 32px;
      line-height: 22px;
      position: relative;
    }
    .sub-table {
      display: none;
    }
    .show {
      .sub-table {
        display: block;
      }
      h4::before {
        transform: rotate(0deg);
      }
    }
    .active {
      background: #fffbf7;
      font-family: var(--SVN-GilroyBold);
      color: var(--orange);
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        background: var(--orange);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .sub-table {
    margin: 4px 0;
    li {
      padding: 5.5px 0;
    }
    li {
      margin-left: -12px;
    }
  }
  .reader {
    width: calc(65% - 230px);
    height: 100%;
    padding: 66px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .box-act {
    margin: 66px 0;
    max-height: calc(100% - 132px);
  }
  .footer {
    height: 56px;
    justify-content: space-between;
    background: #f4f5f6;
    align-items: center;
    .act {
      align-items: center;
      height: 100%;
      button {
        padding: 0;
      }
      .icon {
        margin-right: 0;
      }
      .scale {
        .value {
          width: 64px;
          margin: 0 4px;
          color: var(--gray);
          text-align: center;
        }
      }
      &--1 {
        width: 218px;
        padding-left: 32px;
        border-right: 1px solid #eaeced;
        .btn-menu {
          margin-right: 24px;
          &.active .icon {
            background: url("#{$img-path}/icon-menu1.svg") no-repeat 0 0 /
              contain;
          }
        }
      }
      &--2 {
        width: calc(100% - 620px);
        justify-content: center;
        button {
          width: 40px;
          height: 40px;
          background: #eaeced;
          border: 1px solid #c4c4c4;
          border-radius: 50%;
        }
        .icon {
          filter: brightness(0);
        }
        .btn-prev {
          transform: rotate(180deg);
        }
        .page-number {
          width: 91px;
          height: 40px;
          margin: 0 16px;
          background: #fff;
          border-radius: 6px;
          color: var(--gray);
          border: 1px solid #c4c4c4;
        }
      }
      &--3 {
        padding: 0 24px;
        border-right: 1px solid #eaeced;
        border-left: 1px solid #eaeced;
        .btn-reset {
          margin-left: 24px;
        }
      }
      &--4 {
        padding: 0 24px 0 117px;
      }
    }
    .btn-setting {
      display: none;
      &:hover .icon-setting {
        background: url("#{$img-path}/icon-setting.svg") no-repeat 0 0 / contain;
      }
    }
    .icon-setting {
      width: 24px;
      height: 24px;
      margin-right: 0;
      background: url("#{$img-path}/icon-setting1.svg") no-repeat 0 0 / contain;
    }
    .icon-edit {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/icon-edit1.svg") no-repeat 0 0 / contain;
      margin-right: 0;
    }
  }
  .icon-menu {
    filter: brightness(0);
  }
}

//tusach
.hoc10-search-result {
  padding: 12px 0 80px;
  &--quiz {
    padding-top: 0;
  }
}
.hoc10-search {
  padding: 10px 0 52px;
  text-align: center;
  background: url("#{$img-path}/bg-intro2.svg") no-repeat center center;
  .header {
    max-width: 730px;
    margin: 0 auto 24px;
    &::after {
      @include decointro;
      top: -20px;
      left: -240px;
    }
  }
  h1 {
    font: 54px/64px var(--SVN-GilroyBold);
    color: var(--orange);
    margin-bottom: 8px;
  }
  p {
    color: var(--gray);
  }
}
.search-form {
  max-width: 730px;
  margin: 0 auto;
  .form-group {
    margin-bottom: 4px;
    position: relative;
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      background: url("#{$img-path}/icon-search1.svg") no-repeat;
      position: absolute;
      left: 14px;
      top: 14px;
    }
  }
  input {
    width: calc(100% - 132px);
    height: 44px;
    padding: 0 12px 0 40px;
    background: #fff;
    border-radius: 6px;
    line-height: 22px;
    color: #19252e;
    border: 1px solid #eaeced;
    &::placeholder {
      color: #aab3b9;
    }
    &:focus {
      outline: none;
      border: 1px solid #aab3b9;
    }
  }
  .btn_submit {
    width: 120px;
    height: 44px;
    border-radius: 8px;
    margin-left: 12px;
  }
  .btn_reset {
    width: 20px;
    height: 20px;
    display: none;
    background: url("#{$img-path}/close2.svg") no-repeat 0 0 / contain;
    position: absolute;
    right: 140px;
    top: 12px;
  }
  .search-popular {
    margin-top: 7px;
    h5,
    a {
      margin: 5px 0;
      line-height: 22px;
    }
    h5 {
      margin-right: 16px;
      color: #838285;
    }
    a {
      margin-right: 16px;
      color: #19252e;
    }
    a:hover {
      color: var(--orange);
    }
  }
  .search-suggest {
    display: none;
    width: 100%;
    max-width: 598px;
    padding: 4px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    p {
      color: #606e78;
      padding: 11px 15px 11px 42px;
      line-height: 22px;
      text-align: left;
      cursor: pointer;
      background: url("#{$img-path}/icon-search1.svg") no-repeat 16px center;
    }
    p span {
      font-family: var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    p:hover {
      background: #f9f9f9 url("#{$img-path}/icon-search1.svg") no-repeat 16px
        center;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}
.filter {
  padding: 24px 16px;
  background: #ffffff;
  filter: drop-shadow(24px 24px 80px rgba(0, 0, 0, 0.08));
  border-radius: 8px;

  &.filter-horizontal {
    background: transparent;
  }

  > * + * {
    // border-top: 1px solid #eaeced;
    // margin-top: 24px;
    // padding-top: 20px;
  }
  &__item__list {
    > * + * {
      margin-top: 12px;
    }
    &--select {
      margin-top: -12px;
      > * + * {
        margin-top: 12px;
      }
    }
    &.expand {
      height: 180px;
      overflow: hidden;
    }
  }
  &__title_horizontal {
    width: fit-content;
    text-align: center;
    min-width: 80px;
    padding: 8px;
    color: #fff;
    background-color: #ff7707;
    position: relative;
    text-transform: uppercase;

    &::after {
      content: "";
      width: 0; 
      height: 0;
      position: absolute;
      top: 0;
      right: -24px;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 24px solid #ff7707;
    }
  }
  &__item__list_horizontal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4px;
    background-color: #FFE9D6;
    border-radius: 8px;
    box-shadow: rgb(204, 204, 204) 5px 5px 10px 0px;

    & > label {
      margin-bottom: 0;
    }
  }
  label {
    font-family: var(--SVN-GilroyMedium);
    color: var(--black);
    line-height: 22px;
    position: relative;
    p {
      flex: 1;
    }
    input {
      opacity: 0;
      position: absolute;
    }
    input:checked + .checked {
      background: var(--orange) url("#{$img-path}/checked.svg") no-repeat center
        center;
      border: none;
    }
  }
  .checked {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 14px;
    border: 1.5px solid #aab3b9;
    border-radius: 4px;
  }
  h4 {
    // margin-bottom: 4px;
    font: 16px/24px var(--SVN-GilroyBold);
    // color: var(--gray);
  }
  .btn_filter_more,
  .btn_filter_coll {
    margin-top: 18px;
    i {
      margin-left: 6.5px;
      font-size: 13px;
      color: var(--red);
    }
  }
  .btn_filter_coll {
    display: none;
  }
}
.result {
  &__header {
    align-items: center;
    margin-bottom: 18px;
    h5 {
      margin-bottom: 14px;
      flex-basis: 100%;
      font: 16px/24px var(--SVN-GilroyMedium);
      color: var(--black);
    }
  }
  &__list {
    h4 {
      margin-bottom: 4px;
      font: 16px/24px var(--SVN-GilroyBold);
      color: var(--gray);
    }
  }
  .fil {
    &.fil--search {
      width: 65%;
    }
    &.fil--sort {
      width: 35%;
      justify-content: flex-end;
    }
    &__item {
      display: flex;
      align-items: center;
      margin: 5px 6px;
      padding: 2px 8px;
      background: #eaeced;
      border-radius: 9999px;
      font-weight: 500;
      line-height: 20px;
      color: #80909c;
    }
    .icon-close {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      background: url("#{$img-path}/close3.svg") no-repeat 0 0 / contain;
    }
    .btn_remove_all {
      margin-left: 6px;
      color: var(--orange);
      line-height: 22px;
      font-family: var(--SVN-GilroyMedium);
      &:hover {
        text-decoration: underline;
      }
    }
    h4 {
      margin-right: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    .select2-container {
      max-width: 145px;
    }
  }
  .post {
    margin-bottom: 24px;
    &__thumb {
      margin-bottom: 0;
      border-radius: 12px 12px 0 0;
    }
    &__content {
      padding: 16px 90px 16px 16px;
      color: var(--gray);
      position: relative;
    }
    &__tag {
      width: 72px;
      height: 60px;
      padding: 6px 0 0 30px;
      background: url("#{$img-path}/triger.svg") no-repeat 0 0 / contain;
      text-align: center;
      font: 14px/22px var(--SVN-GilroyBold);
      color: #fff;
      border-radius: 0 12px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }
    &__category {
      width: 100%;
      padding: 7px 0;
      background: #eaeced;
      border-radius: 12px 12px 0px 0px;
      font: 14px/22px var(--SVN-GilroyBold);
      color: var(--gray);
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }
    span {
      display: block;
      margin-bottom: 2px;
      font-size: 10px;
      line-height: 16px;
    }
    h4 {
      font: 12px/18px var(--SVN-GilroyBold);
      text-transform: capitalize;
      color: #22323e;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
    .btn-read,
    .btn-view {
      width: 70px;
      height: 32px;
      position: absolute;
      right: 16px;
      font-size: 12px;
      line-height: 18px;
    }
    .btn-read {
      top: 18px;
    }
    .btn-view {
      background: #2a404f;
      border-radius: 6px;
      top: 14px;
      color: #fff;
    }
  }
  .post-training {
    .post__thumb {
      height: 192px;
    }
    .post__content {
      padding-top: 12px;
    }
  }
  &__list--quiz {
    .post {
      &__content {
        padding: 14px 16px;
        border-bottom: none;
      }
      p {
        font-size: 13px;
        line-height: 18px;
        color: #838285;
      }
    }
  }
  .btn_filter_m {
    display: none;
  }
}
.filter-fix-left {
  display: none;
}

//hocsinh
.hoc10-ft {
  .feature {
    max-width: 445px;
    h2 span {
      margin-left: 12px;
      padding: 5px 7px;
      border: 1px solid #ff7707;
      border-radius: 6px;
      font: 12px/18px var(--SVN-GilroyMedium);
      color: var(--orange);
    }
  }
  .feature-list {
    li {
      width: 25%;
      padding: 0 20px;
    }
    .icon {
      width: 96px;
      margin: 0 auto 32px;
    }
    h4 {
      font: 20px/28px var(--SVN-GilroyBold);
      color: var(--gray);
      text-align: center;
    }
  }
  .text {
    color: #252527;
  }
  .img-wrapper {
    padding: 24px;
    max-width: 608px;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  h2.title {
    margin-bottom: 16px;
    text-align: left;
  }
  .btn-viewmore {
    width: 160px;
    height: 40px;
    margin-top: 32px;
    border-radius: 6px;
  }
  &--1,
  &--t1 {
    .video {
      margin-top: 105px;
      &::after {
        @include decor8;
      }
      &::before {
        @include decor7;
      }
    }
  }
  &--2,
  &--t2 {
    .img-wrapper {
      margin-left: auto;
    }
    .col-lg-7 {
      position: relative;
      &::before {
        @include decor6;
      }
    }
  }
  &--1 {
    padding: 80px 0 118px;
    .video {
      &::after {
        bottom: -36px;
        left: -55px;
        transform: rotate(-28deg);
      }
      &::before {
        top: -28px;
        right: -50px;
        transform: rotate(45deg);
      }
    }
  }
  &--2 {
    padding: 124px 0;
    .col-lg-7 {
      &::before {
        top: 57px;
        right: -50px;
      }
    }
  }
  &--3 {
    padding: 116px 0;
    background: url("#{$img-path}/bg-line1.png") no-repeat 0 0 / cover;
    .img-wrapper {
      background: #feeecd;
      &::before {
        @include decor8;
        top: -56px;
        right: -50px;
      }
      &::after {
        @include decor7;
        left: -27px;
        bottom: -64px;
        transform: rotate(45deg);
      }
    }
  }
  &--4,
  &--t4 {
    padding: 100px 0;
    .decor-vd::after {
      z-index: 1;
      right: -35px;
    }
    .video {
      max-width: 608px;
      margin-left: auto;
      position: relative;
      z-index: 2;
    }
    .box-video {
      /*&::after{@include decor7; left: -32px; bottom: -35px; transform: rotate(45deg); z-index: 1;}
      &::before{@include decor6; top: -40px; right: -42px;}*/
    }
    .statistical {
      border-radius: 24px;
      margin-top: 100px;
      padding: 38px 0;
      justify-content: space-around;
      .text {
        color: #fff;
      }
    }
  }
  &--t1 {
    padding: 80px 0 100px;
    .video {
      &::after {
        top: -36px;
        left: -45px;
        transform: rotate(-28deg);
      }
      &::before {
        bottom: 17px;
        right: -13px;
        transform: rotate(45deg);
      }
    }
  }
  &--t2 {
    padding: 100px 0;
    .col-lg-7 {
      &::before {
        top: -31px;
        right: -26px;
      }
    }
  }
  &--t3 {
    padding: 100px 0;
    .img-wrapper {
      &::before {
        @include decor8;
        bottom: -26px;
        right: -20px;
      }
      &::after {
        @include decor7;
        left: -22px;
        top: -14px;
        transform: rotate(45deg);
      }
    }
  }
}

//luyentap
.hoc10-overview {
  padding: 96px 0 120px;
  .box-video {
    margin-left: auto;
    max-width: 608px;
    .video {
      max-width: 608px;
    }
  }
  h2.title {
    max-width: 330px;
    text-align: left;
    margin-bottom: 16px;
  }
  h4 {
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--gray);
    margin-bottom: 16px;
  }
  p {
    max-width: 425px;
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 26px;
    padding-left: 36px;
    background: url("#{$img-path}/check1.svg") no-repeat top left;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.hoc10-library {
  padding: 93px 0 52px;
  .header {
    margin-bottom: 18px;
  }
  .hover {
    width: 233px;
    padding: 24px 24px 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.01);
    position: absolute;
    left: 50%;
    top: 130px;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 10px);
    transition: 0.4s;
    &::before {
      content: "";
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -14px;
    }
    h5 {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 4px;
      font: 14px/20px var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    p span {
      color: var(--orange);
    }
  }
  .slick-slide {
    position: relative;
    &:hover .hover {
      opacity: 1;
      transform: translate(-50%, 0);
      visibility: visible;
    }
  }
  .library-list {
    margin: 75px -39px 0;
    justify-content: center;
    .icon {
      margin-right: 0;
      display: flex;
    }
    a {
      margin: 0 38.8px 52px;
      display: block;
      text-align: center;
    }
    .icon {
      background: #feeecd;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin: 0 auto 24px;
    }
    .name {
      color: var(--gray);
      text-align: center;
      font-family: var(--SVN-GilroyBold);
    }
  }
  .slick-arrow {
    top: 60px;
    transform: translateY(0);
  }
  .slick-next {
    right: -25px;
  }
  .slick-prev {
    left: -25px;
    transform: translateY(0) rotate(180deg);
  }
}
.hoc10-practice-ex {
  background: #f4f5f6 url("#{$img-path}/bg-line1.png") no-repeat 0 0 / cover;
}
.hoc10-practice-assigned,
.hoc10-practice-ex {
  padding: 93px 0 80px;
  .header {
    margin-bottom: 48px;
  }
}
.practice {
  padding: 24px;
  margin-bottom: 40px;
  &__img {
    height: 168px;
    background: #feeecd;
    border-radius: 12px;
  }
  &__content {
    padding-top: 12px;
    color: var(--gray);
  }
  h3 {
    font: 24px/32px var(--SVN-GilroyBold);
  }
  p {
    font: 14px/20px var(--SVN-GilroyBold);
  }
  .progress {
    height: 8px;
    margin: 16px 0 20px;
    background: #f4f5f6;
    border-radius: 9999px;
    &-bar {
      background: #fcb400;
      border-radius: 9999px;
    }
    &.finish .progress-bar {
      background: #23bf2d;
    }
  }
  .time {
    margin: 8px 0 12px;
    padding-left: 20px;
    background: url("#{$img-path}/tick2.svg") no-repeat 0 3px;
    font-family: var(--SVN-GilroyMedium);
    line-height: 22px;
    span {
      color: var(--orange);
    }
  }
  .btn-pr {
    width: 120px;
    height: 40px;
    border-radius: 6px;
  }
}

.hoc10-practice-list {
  padding: 56px 0 80px;
  h2.title {
    text-align: left;
    margin-bottom: 48px;
  }
  .table-of-content {
    .header {
      padding: 16px 24px;
      border-bottom: 1px solid #eaeced;
      span {
        margin-left: auto;
        font: 14px/22px var(--SVN-GilroyMedium);
        color: var(--gray);
      }
    }
    h3 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--orange);
      &.sm {
        font-size: 20px;
        line-height: 24px;
      }
    }
    ul {
      > * + * {
        border-top: 1px solid #eaeced;
      }
      li {
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
      }
      h4,
      .percent {
        font: 16px/24px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      h4,
      .title {
        display: flex;
        align-items: center;
        color: var(--black);
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          border: 1.5px solid #eaeced;
          border-radius: 50%;
          display: block;
          margin-right: 12px;
        }
        span {
          flex: 1;
        }
      }
      .title {
        width: calc(100% - 40px);
        font-size: 16px;
        line-height: 24px;
        color: var(--gray);
        p {
          width: calc(100% - 32px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          margin-right: 4px;
          font-family: var(--SVN-GilroyBold);
        }
      }
      .percent {
        margin-left: auto;
      }
      .progress {
        margin-top: 12px;
        width: 100%;
        height: 4px;
        background: #eaeced;
        border-radius: 9999px;
      }
      .progress-bar {
        background: var(--orange);
        border-radius: 9999px;
      }
      .finish {
        h4::before,
        .title::before {
          border-color: var(--orange);
          background: url("#{$img-path}/tick3.svg") no-repeat center center;
        }
        p {
          color: var(--orange);
        }
      }
      .doing {
        h4::before,
        .title::before {
          border-color: var(--orange);
        }
      }
    }
  }
}
.hoc10-menu {
  padding: 64px 0;
  .tab {
    margin-bottom: 16px;
    justify-content: center;
    li {
      margin: 0 6px;
    }
    a {
      display: inline-block;
      padding: 0 22px;
      height: 32px;
      border: 1px solid #eaeced;
      border-radius: 9999px;
      font: 14px/32px var(--SVN-GilroyMedium);
    }
    .active {
      background: var(--orange);
      color: #fff;
      border: none;
      text-shadow: 0.1px 0 0 currentColor;
    }
  }
  .tab-content::after {
    content: "";
    width: 1191px;
    height: 599px;
    background: url("#{$img-path}/decor-ex.svg") no-repeat top left;
    position: absolute;
    top: -50px;
    left: -90px;
    z-index: -1;
  }
}
.chapter,
.lesson {
  margin-bottom: 24px;
  .header {
    h3 {
      display: flex;
      align-items: center;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    border-bottom: 1px solid #eaeced;
  }
}
.chapter {
  .header {
    padding: 20px 24px;
    .progress1 {
      margin-left: auto;
    }
  }
  .question {
    padding: 8px 0 8px 16px;
    justify-content: space-between;
    li {
      width: 45%;
      padding: 8px 0;
    }
    li a {
      display: block;
      padding: 0 0 0 32px;
      font: 14px/24px var(--SVN-GilroyMedium);
      color: var(--gray);
      position: relative;
      &::before {
        content: "";
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background: url("#{$img-path}/icon-doc.svg") no-repeat 0 0 / contain;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .done span {
      padding-right: 25px;
      background: url("#{$img-path}/tick4.svg") no-repeat right center;
    }
    span::after {
    }
  }
}
.lesson {
  h4 {
    margin-bottom: 10px;
    font-family: var(--SVN-GilroyBold);
    color: var(--gray);
  }
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
    .btn-result,
    .btn-play {
      width: 229px;
      height: 48px;
      background: #aab3b9;
      border-radius: 6px;
      margin-left: auto;
      font-size: 16px;
      &.active {
        background: var(--orange);
      }
    }
    .icon-chart {
      width: 21px;
      height: 21px;
      background: url("#{$img-path}/icon-chart1.svg") no-repeat 0 0 / contain;
    }
  }
  .Knowledge {
    width: 215px;
    padding: 24px;
    border-right: 1px solid #eaeced;
    a {
      margin-bottom: 12px;
      font: 14px/20px var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    span {
      text-decoration: underline;
    }
  }
  .excrise {
    padding: 24px 24px 34px;
    width: calc(100% - 215px);
    h4 {
      margin-bottom: 4px;
    }
    &__list {
      margin: 0 -7.5px;
    }
    li {
      width: 50%;
      padding: 6px 7.5px;
    }
    a {
      display: flex;
      align-items: center;
      padding: 12px 42px 12px 16px;
      background: #f7f9fc;
      border-radius: 8px;
      font: 14px/22px var(--SVN-GilroyMedium);
      color: var(--gray);
      position: relative;
    }
    span {
      flex: 1;
    }
    .icon-pen {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/icon-pen1.svg") no-repeat 0 0 / contain;
    }
    .finish::after {
      content: "";
      width: 20px;
      height: 20px;
      background: url("#{$img-path}/tick3.svg") no-repeat 0 0 / contain;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .statistic {
    padding: 24px;
    > * + * {
      margin-left: 89px;
    }
    > div {
      font-family: var(--SVN-GilroyBold);
      color: var(--gray);
    }
  }
}
.table-content {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  .item {
    margin-bottom: 12px;
  }
  .header {
    padding: 18px 50px 18px 24px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    h4 {
      color: #000;
    }
    span {
      margin-left: 12px;
      padding: 3px 12px;
      background: #eaeced;
      border-radius: 9999px;
      font: 14px/22px var(--SVN-GilroyMedium);
    }
    .btn-collapse {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/arr-r.svg") no-repeat;
      transform: rotate(-90deg);
      position: absolute;
      right: 24px;
      top: 20px;
    }
  }
  .list {
    display: none;
    > * + * {
      border-top: 1px solid #eaeced;
    }
    li {
      padding: 20px 24px;
      display: flex;
      align-items: center;
      &.active::before {
        border: none;
        background: url("#{$img-path}/tick3.svg") no-repeat 0 0 / contain;
      }
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
        border: 1.5px solid #eaeced;
        border-radius: 50%;
      }
    }
    a {
      margin-left: auto;
      padding-right: 20px;
      background: url("#{$img-path}/arr-r2.svg") no-repeat right center;
      font: 14px/20px var(--SVN-GilroyBold);
      color: var(--orange);
    }
    p {
      color: var(--gray);
    }
    p span {
      font-family: var(--SVN-GilroyBold);
    }
  }
  .active {
    .header {
      background: var(--orange);
      border-radius: 8px 8px 0 0;
      span {
        background: #fff;
        color: var(--orange);
      }
      h4 {
        color: #fff;
      }
      .btn-collapse {
        background: url("#{$img-path}/arr-down2.svg") no-repeat;
        transform: rotate(0);
      }
    }
    .list {
      display: block;
    }
  }
}

//test
.hoc10-test {
  min-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  .header {
    height: 80px;
    background: #eaeced;
    .container {
      height: 100%;
    }
    .text {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      p {
        color: #304858;
        line-height: 22px;
      }
    }
    .text-left {
      position: relative;
      text-align: left;
      left: 0;
      transform: translateX(0);
    }
    .btn {
      margin-left: auto;
      padding: 0;
    }
    .title {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    .btn-back {
      .icon {
        margin-right: 16px;
        background: url("#{$img-path}/icon-back1.svg") no-repeat 0 0 / contain;
      }
      span {
        margin-right: 11px;
        padding: 2.5px 8px;
        border: 1px solid var(--gray);
        border-radius: 6px;
        color: var(--gray);
        font: 14px/22px var(--SVN-GilroyBold);
      }
    }
    .time {
      margin: 0 24px 0 auto;
      font-family: var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    .icon-time {
      background: url("#{$img-path}/icon-time2.svg") no-repeat;
    }
    .btn-light,
    .btn-fullscreen {
      width: 32px;
      height: 32px;
      display: block;
      margin-left: 16px;
    }
    .btn-light {
      background: url("#{$img-path}/icon-light1.svg") no-repeat 0 0 / contain;
    }
    .btn-submit {
      width: 120px;
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
    }
  }
  .main {
    flex: 1 1 auto;
    .container {
      align-items: flex-start;
    }
  }
  .main-test {
    min-height: calc(100vh - 160px);
    .box-question {
      max-width: 855px;
      padding: 0;
    }
    .question {
      padding: 32px 30px;
      width: 59%;
    }
    .answer {
      width: 41%;
      padding-right: 0;
    }
  }
  .box-act {
    width: 56px;
    position: absolute;
    left: -94px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.06);
  }
  .box-question {
    max-width: 985px;
    width: 100%;
    padding: 24px 0 125px;
    background: #fff;
    border-radius: 12px;
    justify-content: flex-start;
    h4 {
      margin-bottom: 11px;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    h4 span {
      color: var(--gray1);
    }
    p {
      font: 16px/24px var(--SVN-GilroyMedium);
      color: var(--black);
    }
    .img-wrapper {
      display: none;
    }
  }
  .list-question {
    background: #f4f5f6;
    width: 255px;
    height: 100%;
    h3 {
      padding: 24px 16px;
      border-bottom: 4px solid #eaeced;
      color: var(--gray);
    }
    .list {
      padding: 16px;
      border-bottom: 4px solid #eaeced;
      > * + * {
        margin-top: 8px;
      }
      li {
        display: flex;
      }
      .icon {
        margin: 0 0 0 auto;
      }
    }
  }
  .question {
    width: 64%;
    padding: 24px;
    border-right: 1px solid #f4f5f6;
    &__content {
      max-width: 445px;
      margin-bottom: 88px;
    }
  }
  .answer {
    padding: 24px;
    width: 36%;
    display: flex;
    flex-direction: column;
    &__list {
      flex: 1 1 auto;
    }
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      width: 100%;
      height: 46px;
      padding: 0 12px;
      border: 1px solid #eaeced;
      border-radius: 8px;
      margin-bottom: 16px;
      color: #22323e;
      font: 14px/22px var(--SVN-GilroyBold);
      cursor: pointer;
      &.active {
        border: none;
        background: var(--orange);
        color: #fff;
        .checked {
          border: none;
        }
        .checked::after {
          display: block;
        }
      }
    }
    .checked {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1.5px solid #aab3b9;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: none;
        border: 7px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .img-wrapper {
      display: none;
    }
  }
  .footer {
    height: 80px;
    background: #eaeced;
    .container {
      height: 100%;
    }
    .btn-draft {
      font: 20px/28px var(--SVN-GilroyBold);
      .icon {
        filter: brightness(0);
      }
      &:hover {
        color: var(--orange);
      }
      &:hover .icon {
        filter: brightness(1);
      }
    }
    .icon-pen {
      width: 24px;
      height: 24px;
      background: url("#{$img-path}/icon-pen1.svg") no-repeat;
    }
    .pagination {
      margin: 0 0 0 auto;
      li {
        margin: 0 7px;
      }
      .page-link {
        width: 32px;
        height: 32px;
        background: #606e78;
        font: 14px/20px var(--SVN-GilroyBold);
        color: #fff;
        &:hover {
          border: none;
          background: var(--orange);
        }
      }
      .active .page-link {
        border: none;
        background: var(--orange);
      }
    }
    .box-check {
      margin-left: auto;
    }
    .btn-test {
      width: 108px;
      height: 40px;
      position: relative;
      border-radius: 8px;
      z-index: 99;
    }
    .btn-error {
      margin-left: auto;
      font-family: var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    .box-btn {
      width: 100%;
      justify-content: flex-end;
      > * + * {
        margin-left: 16px;
      }
      a,
      button {
        width: 120px;
        height: 48px;
        border-radius: 6px;
      }
    }
  }
  .check {
    width: 345px;
    display: none;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #eaeced;
    filter: drop-shadow(24px 24px 80px rgba(0, 0, 0, 0.06));
    position: absolute;
    right: 0;
    bottom: 55px;
    z-index: 99;
    p {
      margin-bottom: 6px;
      font: 20px/28px var(--SVN-GilroyBold);
      color: var(--orange);
    }
    &.active {
      display: block;
    }
    &::after {
      content: "";
      border-style: solid;
      border-width: 9px;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      right: 30px;
      bottom: -18px;
    }
    &__header {
      background: var(--orange);
      padding: 8px 16px;
      font: 14px/22px var(--SVN-GilroyBold);
      color: #fff;
      border-radius: 12px 12px 0 0;
    }
    &__content {
      padding: 18px 30px 18px 86px;
      font: 16px/24px var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    button,
    a {
      color: var(--orange);
      text-decoration: underline;
    }
    .btn-close,
    .btn-replay {
      position: absolute;
    }
    .btn-close {
      width: 16px;
      height: 16px;
      padding: 0;
      opacity: 1;
      background: url("#{$img-path}/close1.svg") no-repeat 0 0 / contain;
      top: 10px;
      right: 10px;
      filter: brightness(0);
      &:focus {
        box-shadow: none;
      }
    }
    .btn-replay {
      width: 48px;
      height: 48px;
      background: url("#{$img-path}/icon-replay.svg") no-repeat 0 0 / contain;
      left: 20px;
      top: 24px;
    }
  }
  .box-filter {
    padding: 16px;
    border-bottom: 4px solid #eaeced;
    &.show .dropdown-filter {
      display: block;
    }
    h4 {
      font: 14px/20px var(--SVN-GilroyBold);
      color: var(--black);
    }
    button {
      margin-left: auto;
      padding: 5px 4px 5px 8px;
      border: 1px solid #aab3b9;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        margin-left: 4px;
        background: url("#{$img-path}/arr-down3.svg") no-repeat 0 0 / contain;
      }
    }
    span {
      margin-left: auto;
      font-family: var(--SVN-GilroyMedium);
    }
    .icon-filter {
      width: 24px;
      height: 24px;
      border: 1px solid #aab3b9;
      border-radius: 4px;
      background: url("#{$img-path}/icon-filter.svg") no-repeat center center;
    }
  }
  .dropdown-filter {
    width: 160px;
    border-radius: 8px;
    display: none;
    border: 1px solid #eaeced;
    position: absolute;
    margin-top: 35px;
    right: 16px;
    li {
      display: flex;
      align-items: center;
      padding: 4px 16px;
      font: 14px/22px var(--SVN-GilroyMedium);
      color: var(--black);
      cursor: pointer;
      &:hover {
        background: #feeecd;
      }
    }
    .icon {
      width: 12px;
      height: 12px;
      margin: 0 0 0 auto;
    }
  }
  .main-result {
    padding: 16px 15px 100px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .sidebar {
    width: 255px;
    padding: 24px 0;
    background: #ffffff;
    border: 1px solid #eaeced;
    border-radius: 8px;
    h3 {
      margin-bottom: 16px;
      padding: 0 16px;
    }
    .result-bar {
      width: 100%;
      height: 18px;
      padding: 0 16px;
      margin-bottom: 16px;
      border-radius: 4px;
      overflow: hidden;
      span {
        height: 100%;
        padding: 0 8px;
        font: 12px/18px var(--SVN-GilroyBold);
        color: #fff;
      }
      .true {
        background: #23bf2d;
        border-radius: 4px 0 0 4px;
      }
      .false {
        background: #e11d10;
        border-radius: 0 4px 4px 0;
      }
    }
    .data {
      padding: 0 16px 16px;
      margin-bottom: 16px;
      border-bottom: 4px solid #eaeced;
      > * + * {
        margin-top: 12px;
      }
      p {
        font: 14px/20px var(--SVN-GilroyBold);
        color: var(--black);
      }
      span {
        margin-left: auto;
        font: 14px/20px var(--SVN-GilroyMedium);
        color: #2a404f;
      }
    }
  }
  .box-qs-list {
    padding: 0 16px;
    h3 {
      margin-bottom: 8px;
      color: var(--gray);
      padding: 0;
    }
    ul {
      margin: 0 -8px;
      li {
        width: 32px;
        height: 32px;
        margin: 7.7px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font: 14px/20px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      .true {
        background: #e2ffd6;
        border: 2px solid #23bf2d;
      }
      .false {
        background: #fde0cd;
        border: 2px solid #e11d10;
      }
    }
    .btn-save {
      width: 100%;
      height: 48px;
      margin-top: 16px;
      font-size: 16px;
      border-radius: 6px;
    }
  }
}
.answer-list {
  width: 825px;
  h3 span {
    padding: 2px 6px;
    margin-left: 8px;
    border: 1px solid #eaeced;
    border-radius: 4px;
    font: 14px/22px var(--SVN-GilroyRegular);
    color: var(--orange);
  }
  .time {
    font: 14px/22px var(--SVN-GilroyMedium);
    color: var(--gray);
    margin-left: auto;
    span {
      color: var(--orange);
    }
  }
  .item {
    margin-bottom: 24px;
    background: #ffffff;
    border: 1px solid #eaeced;
    border-radius: 8px;
    &__header {
      padding: 22px 24px;
      border-bottom: 1px solid #eaeced;
    }
    &__content {
      padding: 24px 24px 20px;
    }
    .content {
      margin-bottom: 8px;
      color: var(--black);
      font-family: var(--SVN-GilroyMedium);
    }
  }
  .box-answer {
    max-width: 682px;
    ul {
      justify-content: space-between;
    }
    li {
      width: 100%;
      max-width: 326px;
      list-style: none;
      margin: 4px 0;
    }
    h4,
    label {
      font: 14px/22px var(--SVN-GilroyBold);
      color: var(--black);
    }
    h4 {
      margin-bottom: 12px;
    }
    label {
      display: flex;
      align-items: center;
      height: 46px;
      padding: 11px;
      border: 1px solid #eaeced;
      border-radius: 8px;
      position: relative;
      &.true {
        background: #e2ffd6;
        border: 1.5px solid #23bf2d;
        .checked {
          border: none;
          background: #23bf2d url("#{$img-path}/checked.svg") no-repeat center
            center;
        }
      }
      &.false {
        background: #fde0cd;
        border: 1.5px solid #e11d10;
        .checked {
          border: 2px solid #e11d10;
        }
        .checked::after {
          content: "";
          @include center;
          width: 8px;
          height: 8px;
          background: #e11d10;
          border-radius: 50%;
        }
      }
    }
    input {
      opacity: 0;
      position: absolute;
    }
    .checked {
      width: 24px;
      height: 24px;
      display: block;
      margin-right: 8px;
      background: #fff;
      border: 2px solid #eaeced;
      border-radius: 50%;
      position: relative;
    }
    .txt {
      margin-top: 4px;
      font: 12px/18px var(--SVN-GilroyMedium);
      color: var(--gray);
      &.true {
        color: #23bf2d;
      }
    }
  }
}
.hoc10-library-test {
  padding: 93px 0 88px;
  background: #f4f5f6 url("#{$img-path}/bg-line1.png") no-repeat 0 0/100%;
  h2.title {
    color: var(--orange);
  }
  .header {
    margin-bottom: 48px;
  }
  .item {
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 32px;
  }
  h3 {
    margin-bottom: 4px;
    font: 24px/32px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  a {
    font: 20px/28px var(--SVN-GilroyMedium);
    color: var(--orange);
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .btn-viewmore {
    width: 32px;
    height: 32px;
    background: url("#{$img-path}/arr-r2.svg") no-repeat 0 0 / contain;
    text-indent: -9999em;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.hoc10-background-decor {
  padding: 83px 0 0;
  background: var(--orange);
  color: #fff;
  .content {
    position: relative;
    z-index: 2;
  }
  h2.title {
    margin-bottom: 4px;
    color: #fff;
  }
  p {
    line-height: 22px;
  }
  .btn-login {
    width: 156px;
    height: 60px;
    margin: 24px auto 0;
    color: var(--orange);
    background: #ffffff;
    border-radius: 12px;
    font: 16px/24px var(--SVN-GilroyBold);
  }
  .img-wrapper {
    width: 826px;
    margin: -45px auto 0;
    img {
      margin-left: 52px;
    }
  }
}
.hoc10-practice-recently {
  padding: 83px 0 80px;
  .header {
    margin-bottom: 48px;
  }
  .practice {
    &__img img {
      width: 96px;
    }
    h3 {
      color: var(--orange);
    }
    .ty {
      display: block;
      margin-bottom: 6px;
      font: 14px/20px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    p {
      font: 14px/22px var(--SVN-GilroyMedium);
    }
    p span {
      color: var(--orange);
    }
    .btn-pr {
      width: 100%;
      margin-top: 14px;
    }
  }
}
.hoc10-homework {
  padding: 32px 0 100px;
  .subject-type {
    background: #fff;
    border-radius: 8px;
    padding: 24px 16px;
    h4 {
      color: var(--gray);
      margin-bottom: 12px;
    }
  }
  .subjects {
    > * + * {
      margin-top: 14px;
    }
    label {
      display: flex;
      align-items: center;
      font: 14px/22px var(--SVN-GilroyMedium);
      color: var(--black);
      position: relative;
    }
    .checked {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 14px;
      background: #ffffff;
      border: 1.5px solid #aab3b9;
      border-radius: 4px;
    }
    p {
      flex: 1;
    }
    input:checked + .checked {
      background: var(--orange) url("#{$img-path}/checked.svg") no-repeat center
        center;
      border: none;
    }
    input {
      opacity: 0;
      position: absolute;
    }
  }
  .tab-content {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eaeced;
  }
  .tab {
    background: #eaeced;
    border-radius: 6px;
    li {
      width: 50%;
    }
    a {
      display: block;
      text-align: center;
      padding: 6px;
      font: 14px/20px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    .active {
      background: var(--orange);
      color: #fff;
      border-radius: 6px 0 0 6px;
    }
  }
  .box-test {
    margin-bottom: 16px;
    border-radius: 8px;
    .header {
      padding: 22px 24px;
      border-bottom: 1px solid #eaeced;
    }
  }
  .list-exam {
    padding: 24px;
    > * + * {
      margin-top: 12px;
    }
    li {
      padding: 16px 150px 16px 76px;
      border: 1px solid #eaeced;
      border-radius: 8px;
      position: relative;
    }
    li::before {
      content: "";
      width: 40px;
      height: 40px;
      background: url("#{$img-path}/icon-exam.svg") no-repeat 0 0 / contain;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    h4 {
      color: var(--gray);
    }
    p {
      font: 14px/22px var(--SVN-GilroyMedium);
      color: var(--black);
      span {
        position: relative;
      }
      > * + * {
        margin-left: 8px;
        padding-left: 8px;
      }
      > * + *::before {
        content: "";
        width: 4px;
        height: 4px;
        background: var(--orange);
        border-radius: 50%;
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .btn-pr {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

//taphuan
.hoc10-new-training {
  padding: 80px 0 96px;
  .header {
    margin-bottom: 32px;
  }
  h2.title span {
    font-family: var(--SVN-GilroyMedium);
    color: var(--gray);
  }
  .news {
    &__thumb {
      p {
        font: 18px/26px var(--SVN-GilroyMedium);
        text-align: center;
        color: var(--black);
      }
      img {
        width: 100%;
      }
      &::after {
        @include decor9;
        top: 205px;
        left: -32px;
      }
    }
    &__list {
      > * + * {
        margin-top: 12px;
      }
      .item {
        padding: 20px;
      }
      .active {
        padding: 20px 20px 20px 32px;
        &::before {
          content: "";
          width: 12px;
          height: 100%;
          border-radius: 12px 0px 0px 12px;
          background: var(--orange);
          position: absolute;
          top: 0;
          left: 0;
        }
        h3 a {
          font-size: 20px;
          line-height: 28px;
          color: var(--orange);
        }
        h3 {
          margin-bottom: 8px;
        }
        p {
          -webkit-line-clamp: 3;
        }
      }
      h3 {
        margin-bottom: 4px;
        font: 16px/24px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      p {
        margin-bottom: 12px;
        line-height: 22px;
        color: var(--gray);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn-viewmore {
        padding-right: 19px;
        background: url("#{$img-path}/arr-r2.svg") no-repeat right center;
        font: 14px/22px var(--SVN-GilroyMedium);
        color: var(--orange);
      }
    }
    .img-wrapper {
      padding: 21px;
      margin-bottom: 24px;
    }
    .btn-viewall {
      width: 120px;
      height: 32px;
      margin: 24px auto 0;
      font-size: 12px;
    }
  }
}
.hoc10-doc-training {
  padding: 90px 0 120px;
  .header {
    margin-bottom: 24px;
  }
  .box-search {
    background: #fff;
    border-radius: 8px;
    padding: 32px 32px 27px;
    margin-bottom: 24px;
  }
  .search-form {
    .search-popular {
      max-width: 598px;
      justify-content: center;
      h5 {
        line-height: 22px;
        font-family: var(--SVN-GilroyBold);
        color: var(--gray);
      }
      a {
        color: #606e78;
      }
    }
  }
}
.hoc10-check-training {
  padding: 96px 0 112px;
  .header {
    margin-bottom: 55px;
  }
  .bg-sh {
    padding: 20px;
  }
  .box-time {
    max-width: 424px;
    margin: 16px 0 0 70px;
    position: relative;
    &::after {
      content: "";
      width: 82px;
      height: 85px;
      background: url("#{$img-path}/arrow2.svg") no-repeat 0 0 / contain;
      position: absolute;
      left: 245px;
      bottom: -15px;
    }
    p {
      margin-bottom: 8px;
      padding-left: 12px;
      font: 16px/24px var(--SVN-GilroyMedium);
      color: var(--gray);
      position: relative;
    }
    p::before {
      content: "";
      width: 4px;
      height: 4px;
      background: #2a404f;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 11px;
    }
    p span {
      font-family: var(--SVN-GilroyBold);
    }
    .btn-play {
      width: 208px;
      height: 40px;
      margin-top: 16px;
      border-radius: 6px;
    }
  }
  .img-wrapper {
    width: 383px;
  }
  .content {
    max-width: 424px;
    margin-left: 70px;
    h2 {
      margin-bottom: 12px;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    p {
      padding: 8px 0 8px 34px;
      background: url("#{$img-path}/check1.svg") no-repeat left center;
    }
  }
}

//support
.hoc10-question {
  padding: 80px 0 68px;
  .header {
    margin-bottom: 4px;
  }
  .question-list {
    &::after {
      @include decor7;
      bottom: -25px;
      left: 54%;
      transform: rotate(54deg);
    }
    &::before {
      @include decor6;
      right: -38px;
      top: -27px;
    }
  }
  h2.title {
    font-size: 24px;
    line-height: 32px;
  }
  .item {
    width: calc(50% - 15px);
    margin: 12px 0;
    padding: 20px;
    position: relative;
  }
  .item:nth-child(1)::before {
    @include decor11;
    left: -24px;
    top: 4px;
  }
  h4 {
    margin-bottom: 8px;
    font: 16px/24px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  .btn-video {
    padding-left: 24px;
    background: url("#{$img-path}/icon-video1.svg") no-repeat left 3px;
    font: 16px/22px var(--SVN-GilroyMedium);
    color: var(--orange);
  }
}
.hoc10-guide {
  padding: 80px 0 120px;
  .header {
    margin-bottom: 20px;
  }
  h2.title {
    font-size: 24px;
    line-height: 32px;
    &.text-left {
      text-align: left;
    }
  }
  .support {
    align-items: flex-start;
    &__list {
      width: 350px;
      background: #fff;
      border-radius: 8px;
      h3 {
        padding: 24px;
        border-bottom: 1px solid #e9e9e9;
        font: 18px/26px var(--SVN-GilroyBold);
        color: var(--gray);
      }
    }
    &__content {
      width: 730px;
      background: #fff;
      border-radius: 8px;
    }
    &__page {
      width: 730px;
    }
  }
  .accordion {
    padding: 0 24px;
    > * + * {
      border-top: 1px solid #e9e9e9;
    }
    .header {
      margin-bottom: 0;
      padding: 20px 0;
      cursor: pointer;
      &::after {
        content: "";
        width: 24px;
        height: 24px;
        background: url("#{$img-path}/arr-down3.svg") no-repeat 0 0 / contain;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      span {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        background: #eaeced;
        border-radius: 50%;
        font: 12px/24px var(--SVN-GilroyBold);
        color: var(--gray);
        text-align: center;
      }
    }
    .active {
      .header {
        span {
          background: var(--orange);
          color: #fff;
        }
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .content {
        padding-bottom: 20px;
        display: block;
      }
      h4 {
        color: var(--orange);
        font-size: 18px;
      }
    }
    .content {
      display: none;
      > * + * {
        margin-top: 20px;
      }
      a {
        display: block;
        font-size: 18px;
        line-height: 22px;
        color: var(--gray);
      }
      .active {
        color: var(--orange);
        font-family: var(--SVN-GilroyBold);
      }
    }
    h4 {
      color: var(--gray);
    }
  }
  .tab {
    border-bottom: 1px solid #e9e9e9;
    li {
      width: 50%;
    }
    li a {
      display: block;
      text-align: center;
      font: 18px/26px var(--SVN-GilroyBold);
      color: #80909c;
      padding: 26px 0 20px;
      position: relative;
    }
    .active {
      color: var(--orange);
    }
    .active::after {
      content: "";
      width: 100%;
      height: 2px;
      background: var(--orange);
      left: 0;
      bottom: -1px;
      position: absolute;
    }
  }
  .tab-content {
    padding: 24px;
    h4 {
      margin-bottom: 24px;
      font: 18px/26px var(--SVN-GilroyBold);
      color: var(--gray);
    }
  }
  .guide-list {
    li {
      margin-bottom: 16px;
      align-items: flex-start;
      font-size: 17px;
    }
    a {
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);
      flex: 1;
    }
    span {
      display: inline-block;
      margin-right: 8px;
      background: var(--orange);
      padding: 3px 8px;
      border-radius: 9999px;
      color: #fff;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .pagedetail {
    background: #fff;
    border-radius: 8px;
    h3 {
      padding: 21px 24px;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
      border-bottom: 1px solid #eaeced;
    }
    article {
      padding: 24px 24px 10px;
      color: var(--gray);
      font-size: 16px;
      line-height: 24px;
      h4 {
        margin-bottom: 24px;
        font: 18px/26px var(--SVN-GilroyBold);
      }
      p {
        margin-bottom: 16px;
      }
      strong {
        font-family: var(--SVN-GilroyBold);
      }
    }
    .feedback {
      padding: 24px;
      border-top: 1px solid #eaeced;
      p {
        margin: 0 16px 0 0;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      .icon-like {
        background: url("#{$img-path}/icon-like.svg") no-repeat 0 0 / contain;
      }
      .icon-dislike {
        background: url("#{$img-path}/icon-dislike.svg") no-repeat 0 0 / contain;
      }
    }
    .like,
    .dislike {
      margin-right: 8px;
      cursor: pointer;
      input {
        width: 30px;
        border: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .like input {
      color: var(--orange);
    }
    .dislike input {
      color: #838285;
    }
  }
  .related {
    margin-top: 24px;
    background: #fff;
    border-radius: 8px;
    h3 {
      padding: 24px;
      border-bottom: 1px solid #e9e9e9;
      font: 18px/26px var(--SVN-GilroyBold);
      color: var(--gray);
      text-align: center;
    }
    ul {
      padding: 0 24px;
      > * + * {
        border-top: 1px solid #e9e9e9;
      }
      li {
        padding: 20px 0;
      }
      a {
        font-size: 16px;
        line-height: 24px;
        color: var(--gray);
      }
      a:hover {
        color: var(--orange);
      }
    }
  }
}
.hoc10-contactus {
  min-height: 284px;
  padding: 64px 0 80px;
  background: var(--orange) url("#{$img-path}/bg-line.png") no-repeat 0 0/100%;
  .btn-contact {
    width: 236px;
    height: 60px;
    margin: 0 auto 56px;
    background: #fff;
    border-radius: 12px;
    font: 18px var(--SVN-GilroyBold);
    color: var(--gray);
    position: relative;
    &::after {
      @include arrowl;
      right: -120px;
    }
    &::before {
      content: "";
      width: 78px;
      height: 81px;
      background: url("#{$img-path}/arrow3.svg") no-repeat 0 0 / contain;
      position: absolute;
      left: -111px;
      top: -15px;
    }
  }
  ul {
    justify-content: space-between;
    li {
      display: flex;
      align-items: flex-start;
    }
    a {
      font: 16px/24px var(--SVN-GilroyBold);
      color: #fff;
    }
    .icon {
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
    .icon-phone {
      background: url("#{$img-path}/icon-phone4.svg") no-repeat 0 0 / contain;
    }
    .icon-email {
      background: url("#{$img-path}/icon-mail2.svg") no-repeat 0 0 / contain;
    }
    .icon-chat {
      background: url("#{$img-path}/icon-chat.svg") no-repeat 0 0 / contain;
    }
  }
}

//news
.hoc10-news {
  padding: 48px 0 96px;
  .post--highlight {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeced;
    .post__thumb {
      height: 417px;
      margin-bottom: 24px;
      border-radius: 12px;
    }
    .post__content {
      h3 {
        margin-bottom: 8px;
      }
      h3 a {
        font: 24px/32px var(--SVN-GilroyBold);
        color: var(--black);
      }
      p {
        margin-bottom: 12px;
        line-height: 22px;
      }
    }
    .btn-viewmore {
      color: var(--orange);
      font-family: var(--SVN-GilroyBold);
    }
  }
  .readmany {
    h4 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: #4e4c50;
    }
  }
  .article {
    h1.title {
      margin-bottom: 38px;
      text-align: left;
      font-size: 36px;
      line-height: 48px;
      color: var(--black);
    }
    article {
      margin-top: 23px;
      color: var(--gray);
    }
    h4 {
      font: 20px/28px var(--SVN-GilroyBold);
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);
    }
    strong {
      font-family: var(--SVN-GilroyBold);
    }
    figure {
      margin-bottom: 24px;
      text-align: center;
    }
    figcaption {
      text-align: center;
      margin-top: 12px;
      color: var(--gray);
    }
  }
  .quote {
    margin-bottom: 24px;
    padding: 32px 24px 8px;
    background: #feeecd;
    border-radius: 12px;
    color: #2a404f;
    h4 {
      margin-bottom: 8px;
      font: 20px/28px var(--SVN-GilroyBold);
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .author {
    text-align: right;
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  .related {
    margin-top: 24px;
    padding-top: 60px;
    border-top: 1px solid #eaeced;
    h4 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
  }
  .post-ty2:last-child {
    border-bottom: none;
  }
}
.email-register {
  width: 350px;
  height: 248px;
  max-width: 100%;
  padding: 40px 24px;
  margin: 32px auto 0;
  background: url("#{$img-path}/bg-register.svg") no-repeat 0 0 / cover;
  box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  h4 {
    max-width: 207px;
    margin-bottom: 16px;
    color: var(--orange);
    font: 18px/26px var(--SVN-GilroyBold);
    position: relative;
    &::after {
      @include line8;
      width: 118px;
      position: absolute;
      top: 23px;
      left: -3px;
    }
  }
  .form-group {
    margin-bottom: 20px;
  }
  .btn-submit {
    width: 100%;
    height: 40px;
    border-radius: 6px;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border: 1px solid #eaeced;
    border-radius: 6px;
    color: var(--gray);
    &::placeholder {
      color: #aab3b9;
    }
  }
}

//blog
.hoc10-blog {
  padding: 48px 0 94px;
  .blog-highlight {
    padding-bottom: 45px;
    margin-bottom: 39px;
    border-bottom: 1px solid #eaeced;
    .post {
      &__thumb {
        width: 570px;
      }
      &__content {
        width: calc(100% - 570px);
        padding-left: 50px;
      }
      h3 {
        margin-bottom: 10px;
      }
      h3 a {
        font: 24px/32px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      p {
        margin-bottom: 12px;
        line-height: 22px;
      }
      .btn-viewmore {
        width: 120px;
        height: 44px;
        margin-top: 60px;
        font-size: 16px;
        border-radius: 8px;
      }
    }
  }
  .blog-list {
    padding: 32px 0 40px;
    border-bottom: 1px solid #eaeced;
    .header {
      margin-bottom: 32px;
      justify-content: space-between;
      h4 {
        font: 24px/32px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      .btn-view {
        display: flex;
        align-items: center;
        font: 18px/26px var(--SVN-GilroyBold);
        color: var(--orange);
        &::after {
          content: "";
          width: 24px;
          height: 24px;
          display: block;
          margin-top: 3px;
          background: url("#{$img-path}/arr-r2.svg") no-repeat 0 0 / contain;
        }
      }
    }
    .btn-viewmore {
      width: 152px;
      height: 44px;
      margin: 39px auto 0;
      font-size: 16px;
    }
  }
  .tab-slider-blog {
    margin: 0 -14px 0;
    padding-right: 14px;
    .item {
      padding: 0 14px;
    }
    a {
      padding-bottom: 12px;
      font: 18px/26px var(--SVN-GilroyBold);
      color: #80909c;
      position: relative;
    }
    .active {
      a {
        color: var(--orange);
      }
      a::after {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--orange);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .slick-list {
      padding-bottom: 12px;
    }
    .slick-arrow {
      top: -5px;
      transform: translateY(0);
    }
    .slick-next {
      right: -60px;
    }
    .slick-prev {
      left: -60px;
      transform: translateY(0) rotate(180deg);
    }
  }
}
.hoc10-blog-detail {
  .summary {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
    &__img {
      width: 530px;
      border-radius: 12px;
      overflow: hidden;
    }
    &__content {
      width: calc(100% - 530px);
      padding-left: 40px;
      h1 {
        display: block;
        margin-bottom: 16px;
        font: 36px/48px var(--SVN-GilroyBold);
        color: var(--gray);
      }
      .category {
        display: inline-block;
        margin-bottom: 8px;
        background: #f4f5f6;
        padding: 2px 8px;
        color: var(--gray);
        border-radius: 4px;
        font: 14px/22px var(--SVN-GilroyMedium);
        text-transform: uppercase;
      }
    }
    .icon {
      width: 20px;
      height: 20px;
    }
    .time,
    .view {
      margin-right: 24px;
      font: 14px/22px var(--SVN-GilroyMedium);
      color: #80909c;
    }
    .icon-time {
      background: url("#{$img-path}/icon-time4.svg") no-repeat 0 0 / contain;
    }
    .icon-view {
      background: url("#{$img-path}/icon-view.svg") no-repeat 0 0 / contain;
    }
  }
  .post-detail {
    padding-bottom: 48px;
    align-items: flex-start;
    .col-left,
    .col-right {
      max-width: 255px;
      width: 100%;
      position: sticky;
      top: 100px;
    }
    .col-middle {
      width: calc(100% - 510px);
      padding: 0 30px;
    }
    .author {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid #e9e9e9;
      align-items: flex-start;
      &__img {
        width: 72px;
        img {
          border-radius: 50%;
        }
      }
      &__info {
        width: calc(100% - 72px);
        padding-left: 12px;
      }
      .name,
      .position {
        margin-bottom: 4px;
        line-height: 20px;
      }
      .name {
        font-weight: 700;
      }
      .position {
        font-weight: 500;
      }
      p {
        color: #838285;
        margin-bottom: 12px;
        line-height: 20px;
      }
    }
  }
  .menu-post {
    h4 {
      padding: 0 0 24px 32px;
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeced;
      background: url("#{$img-path}/icon-mn.svg") no-repeat left 2px;
    }
    li {
      padding: 8px 0;
      display: flex;
    }
    li a {
      line-height: 22px;
      flex: 1;
    }
    li span {
      width: 20px;
      height: 20px;
      display: block;
      margin-top: 2px;
      margin-right: 8px;
      background: #fff;
      border: 1px solid #eaeced;
      border-radius: 50%;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
    }
    .active {
      span {
        background: var(--orange);
        color: #fff;
        border: none;
      }
      a {
        color: var(--gray);
        font-family: var(--SVN-GilroyMedium);
      }
    }
  }
  article {
    font-size: 16px;
    line-height: 24px;
    p {
      margin-bottom: 24px;
    }
    h4 {
      font: 20px/28px var(--SVN-GilroyBold);
      color: var(--gray);
      margin-bottom: 8px;
    }
  }
  .tag {
    margin-left: -6px;
    a {
      margin: 2px 6px;
      padding: 6px 12px;
      background: #f7f7f7;
      border-radius: 4px;
    }
  }
  .contact {
    > * + * {
      margin-left: 16px;
    }
    a {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #f7f7f7;
    }
  }
  .new-post {
    h4 {
      margin-bottom: 16px;
    }
    ul {
      border-top: 1px solid #eaeced;
    }
    ul > * + * {
      border-top: 1px solid #eaeced;
    }
    li {
      list-style: none;
      padding: 16px 0;
    }
    li a {
      font: 16px/24px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    li a:hover {
      color: var(--orange);
    }
  }
  .share {
    margin-left: auto;
  }
  .email-register {
    width: 255px;
    height: 248px;
  }
  .related-post {
    padding: 48px 0 120px;
    border-top: 1px solid #eaeced;
    h2.title {
      margin-bottom: 48px;
    }
  }
}

//bai giang
.hoc10-lesson {
  padding: 96px 0 40px;
  &.bg-gray {
    background: #f4f5f6 url("#{$img-path}/bg-line1.png") no-repeat 0 0 / cover;
  }
  .btn-viewall {
    margin-left: auto;
    width: 120px;
    height: 48px;
    border-radius: 6px;
    font: 16px var(--SVN-GilroyBold);
    &--cs1 {
      background: #fff;
      color: var(--orange);
      &:hover {
        background: var(--orange);
        color: #fff;
      }
    }
    &--cs2 {
      background: var(--orange);
      color: #fff;
      &:hover {
        background: #e36b09;
      }
    }
  }
  .lesson-list {
    margin: 0 -15px;
  }
  .item {
    padding: 32px 15px 80px;
  }
  .post {
    padding: 24px;
    background: #fff;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    color: var(--gray);
    h3 {
      margin: 12px 0 0;
    }
    h3 a {
      font: 24px/32px var(--SVN-GilroyBold);
    }
    h3 a:hover {
      color: var(--orange);
    }
    p {
      font: 14px/20px var(--SVN-GilroyMedium);
      color: var(--gray);
    }
    &__thumb {
      border-radius: 12px;
      overflow: hidden;
    }
    img {
      width: 100%;
    }
  }
  .addnew {
    width: 100%;
    height: 292px;
    background: #fff;
    flex-direction: column;
    border-radius: 8px;
    font: 24px/32px var(--SVN-GilroyBold);
    color: var(--orange);
    .icon {
      width: 64px;
      height: 64px;
      background: #feeecd url("#{$img-path}/icon-add1.svg") no-repeat center
        center;
      border-radius: 50%;
      margin: 0 auto 16px;
    }
  }
  .tag {
    margin-top: 8px;
    > * + * {
      margin-left: 8px;
    }
    span,
    a {
      padding: 1px 8px;
      background: #feeecd;
      border-radius: 9999px;
      line-height: 22px;
      color: var(--orange);
    }
  }
  .slick-arrow {
    top: 45%;
  }
  .slick-next {
    right: -45px;
  }
  .slick-prev {
    left: -45px;
  }
}
.hoc10-lesson-library {
  // padding: 16px 0 120px;
  .header {
    margin-bottom: 40px;
    h2.title {
      margin-bottom: 32px;
    }
    select {
      width: 160px;
    }
  }
  .post {
    margin-bottom: 24px;
    background: #ffffff;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    color: var(--gray);
    &__thumb {
      width: 100%;
      height: 192px;
      display: block;
      padding: 5px 5px 0;
      border-radius: 12px;
      overflow: hidden;
    }
    &__content {
      padding: 12px 24px 16px;
      border-bottom: 1px solid #eaeced;
      text-align: justify;
    }
    .block {
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.5);
      border-radius: 8px;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      p {
        color: #fff;
        font: 16px var(--SVN-GilroyMedium);
        margin-top: 10px;
      }
    }
    .icon-block {
      width: 46px;
      height: 58px;
      background: url("#{$img-path}/lock.png") no-repeat 0 0 / contain;
      margin-right: 0;
    }
    h3 {
      margin-bottom: 4px;
      min-height: 56px;
    }
    h3 a {
      font: 18px/28px var(--SVN-GilroyBold);
    }
    .btn-view {
      width: 144px;
      height: 32px;
      border-radius: 6px;
    }
  }
  .tag {
    margin-bottom: 16px;
    > * + * {
      margin-left: 8px;
    }
    a {
      padding: 1px 8px;
      background: #f4f5f6;
      border-radius: 9999px;
      color: #80909c;
      line-height: 22px;
    }
  }
  .data {
    padding: 16px 24px;
    > * + * {
      margin-left: 12px;
      margin-right: 0;
    }
    > div {
      font: 14px/22px var(--SVN-GilroyRegular);
      color: #80909c;
    }
    .icon {
      margin-right: 4px;
    }
  }
  .box-select {
    width: 160px;
    margin-right: 30px;
  }
  .hoc10-form {
    width: calc(100% - 380px);
    input {
      max-width: 598px;
      padding: 0 15px 0 40px;
      background: url("#{$img-path}/icon-search1.svg") no-repeat 12px center;
    }
  }
  .search-form {
    width: calc(100% - 280px);
  }
  .loading {
    margin-top: 40px;
  }
  .select2-container .select2-selection--single {
    height: 44px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 44px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 44px;
  }
}
.hoc10-slideshow {
  .header {
    height: 72px;
    background: #f7f9fc;
    border-bottom: 1px solid #eaeced;
  }
  .box-btn {
    margin-left: auto;
    > * + * {
      margin-left: 15px;
    }
    button {
      width: 124px;
      height: 40px;
      border: 1px solid #2a404f;
      border-radius: 6px;
      font: 16px/24px var(--SVN-GilroyMedium);
    }
    .btn-save {
      background: var(--orange);
      border: none;
      color: #fff;
    }
    .btn-share {
      width: 40px;
    }
    .icon-share,
    .icon-download {
      filter: brightness(0);
    }
    .icon-share {
      margin-right: 0;
    }
    .icon-download,
    .icon-play {
      width: 20px;
      height: 20px;
    }
    .icon-play {
      background: url("#{$img-path}/icon-play2.svg") no-repeat 0 0 / contain;
    }
  }
  .container {
    height: 100%;
  }
  .btn-back {
    font: 24px/32px var(--SVN-GilroyBold);
    color: var(--gray);
    .tag {
      margin-left: 12px;
      padding: 2px 8px;
      border: 1px solid #2a404f;
      border-radius: 6px;
      font: 14px/22px var(--SVN-GilroyBold);
    }
  }
  .main {
    height: calc(100vh - 72px);
    align-items: flex-start;
    &.is-hide {
      width: 100%;
      max-width: 1110px;
      margin: 0 auto;
      .sidebar {
        display: none;
      }
      .content {
        width: 736px;
        padding: 24px 0;
      }
      .box-act {
        margin-left: 30px;
      }
      .btn-show {
        display: flex;
      }
      .box-video {
        display: block;
      }
    }
    .sidebar {
      width: 373px;
      height: 100%;
      background: #eaeced;
      padding-right: 3px;
      border-left: 1px solid #eaeced;
      border-right: 1px solid #eaeced;
      &::after {
        content: "";
        height: 100%;
        width: 9px;
        background: #eaeced;
        position: absolute;
        right: 3px;
        top: 0;
        z-index: 2;
      }
    }
    .content {
      width: calc(100% - 373px);
      height: calc(100% - 50px);
      padding: 24px 24px 24px 82px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .box-act {
      margin: 88px 0 0 15px;
      padding: 0;
      button {
        width: 56px;
        height: 56px;
        border: none;
        border-radius: 0;
        border-top: 1px solid #eaeced;
      }
      button:first-child {
        border-top: none;
      }
      > * + * {
        margin-top: 0;
      }
    }
    .box-video {
      width: 255px;
      height: 100%;
      display: none;
      padding: 24px;
      margin-left: 30px;
      border: 1px solid #eaeced;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ccc;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
    }
    .box-select {
      margin-bottom: 8px;
      width: 100%;
    }
    h3 {
      margin-bottom: 33px;
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    h4 {
      margin-bottom: 8px;
      font: 20px/28px var(--SVN-GilroyBold);
      color: var(--gray);
    }
  }
  .list-slide {
    height: 100%;
    overflow: auto;
    padding: 0 0;
    background: #eaeced;
    z-index: 3;
    &::after {
      content: "";
      width: calc(100% - 4px);
      height: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &::-webkit-scrollbar {
      width: 6px;
      position: relative;
      z-index: 2;
    }
    &::-webkit-scrollbar-thumb {
      background: #aab3b9;
      border-radius: 99px;
    }
    &::-webkit-scrollbar-track {
      background: #eaeced;
    }
    li {
      width: calc(100% - 4px);
      padding: 12px 0;
      display: flex;
      align-items: center;
      background: #fff;
      position: relative;
    }
    li:first-child {
      padding-top: 24px;
    }
    .img-wrapper {
      width: 238px;
      border-radius: 8px;
      overflow: hidden;
    }
    .active {
      &::before {
        content: "";
        width: 5px;
        height: 135px;
        background: var(--orange);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .img-wrapper {
        border: 1px solid var(--orange);
      }
    }
  }
  .search-form {
    margin-bottom: 12px;
    input {
      width: 100%;
      height: 40px;
    }
  }
  .slide-wrapper {
    max-width: 895px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 23.4783px 23.4783px 78.2609px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
  .video-list {
    .video {
      padding: 6px 0;
      overflow: hidden;
      position: relative;
      img {
        border-radius: 8px;
      }
      &::after {
        @include center;
        content: "";
        width: 32px;
        height: 32px;
        background: url("#{$img-path}/icon-play3.svg") no-repeat 0 0 / contain;
      }
    }
  }
  .dropdown {
    display: none;
  }
  .number {
    width: 31px;
    height: 31px;
    margin: 0 10px;
    color: var(--gray);
  }
  .btn-hide {
    height: 44px;
    width: 357px;
    border-radius: 0;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }
  .btn-show {
    width: 255px;
    height: 44px;
    display: none;
    border-radius: 0;
    font-size: 16px;
    position: absolute;
    left: 15px;
    bottom: 5px;
  }
}

//timkiem
.hoc10-page-search {
  .header {
    margin-bottom: 20px;
    .btn-viewmore {
      margin-left: auto;
      width: 120px;
      height: 32px;
      border-radius: 6px;
      font-size: 12px;
    }
  }
  .text {
    padding: 24px 0;
    font: 18px/26px var(--SVN-GilroyMedium);
    color: var(--black);
    span {
      color: var(--orange);
    }
  }
  .search-result {
    margin-bottom: 16px;
    h3 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: var(--black);
    }
  }
  .box-404 {
    padding: 64px 0 96px;
    .img-wrapper {
      margin: 0 auto 56px;
      width: 100%;
      max-width: 428px;
    }
    .content {
      text-align: center;
      color: var(--black);
    }
    p {
      margin: 0 auto 16px;
      font: 36px/48px var(--SVN-GilroyBold);
    }
    p strong {
      color: var(--orange);
    }
    span {
      font: 18px/26px var(--SVN-GilroyMedium);
    }
  }
}

//decor
.decor-h1::after {
  @include decointro;
  top: -15px;
}
.decor-h2::after {
  content: "";
  width: 930px;
  height: 200px;
  background: url("#{$img-path}/bg-intro1.svg") no-repeat 0 0 / contain;
  position: absolute;
  top: 0;
  left: 70px;
}
.decor-h3::after {
  @include decointro;
}
.decor-vd::after {
  content: "";
  width: 707px;
  height: 463px;
  background: url("#{$img-path}/decor-video.svg") no-repeat 0 0 / contain;
  position: absolute;
  z-index: -1;
  top: -41px;
  right: -60px;
}

//modal
.modal::-webkit-scrollbar {
  width: 0;
}
.modal-video {
  .modal-dialog {
    max-width: 80vw;
    margin: 2rem auto;
  }
  .close {
    padding: 2px 5px;
    background: var(--red);
    color: #fff;
    font-size: 15px;
    opacity: 1;
    position: absolute;
    top: -1px;
    right: -1px;
  }
  .modal-content {
    width: 100%;
    height: 40vw;
    background: #000;
    border-radius: 0;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
.modal-login {
  .modal-dialog {
    max-width: 560px;
  }
  .modal-content {
    width: 100%;
    padding: 32px 32px 42px;
    background: #ffffff;
    border-radius: 12px;
    .title {
      margin-bottom: 20px;
      color: var(--black);
      text-align: center;
    }
  }
  .note {
    margin-bottom: 16px;
    padding: 12px;
    background: #fffbf7;
    border-radius: 4px;
    line-height: 22px;
    span {
      color: #e11d10;
    }
    strong {
      color: var(--orange);
    }
  }
  form {
    .form-group {
      margin-bottom: 16px;
    }
    .forgot-password {
      margin-left: auto;
      text-decoration: underline;
      line-height: 22px;
      color: #2a404f;
      &:hover {
        color: var(--orange);
      }
    }
    .checkbox {
      margin-bottom: 0;
    }
    .btn-submit {
      width: 100%;
      margin-top: 105px;
    }
    .register {
      margin: 24px 0 16px;
      text-align: center;
      color: var(--black);
      a {
        color: var(--orange);
        font-family: var(--SVN-GilroyBold);
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .text {
      max-width: 100%;
      margin: 0 0 24px;
      text-align: center;
      line-height: 22px;
      color: #2a404f;
    }
  }
  .box-login {
    margin: 0;
    h4 {
      flex-wrap: nowrap;
      color: #aab3b9;
      &::before,
      &::after {
        width: 100%;
        background: #aab3b9;
      }
    }
    h4 span {
      white-space: nowrap;
      padding: 0 7px;
    }
    .login-list {
      justify-content: space-between;
      a {
        max-width: calc(50% - 8px);
        width: 100%;
        height: 48px;
        margin: 0;
      }
      .facebook {
        background: #475993;
      }
    }
  }
  .close {
    width: 24px;
    height: 24px;
    background: url("#{$img-path}/icon-close1.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: 1;
    font-size: 0;
  }
}
.modal-success {
  .modal-dialog {
    max-width: 560px;
  }
  .modal-content {
    background: #ffffff;
    border-radius: 12px;
    padding: 62px 0 48px;
    .img-wrapper {
      width: 397px;
      max-width: 100%;
      margin: 0 auto 16px;
    }
    .text {
      margin-bottom: 32px;
      text-align: center;
      font: 36px/48px var(--SVN-GilroyBold);
      color: #2a404f;
    }
    .btn-access {
      width: 320px;
      height: 48px;
      margin: 0 auto;
      border-radius: 6px;
      font-size: 16px;
    }
  }
}
.modal-select-time {
  .modal-dialog {
    max-width: 560px;
  }
  .modal-content {
    background: #fff;
    border-radius: 12px;
    border: none;
  }
  .modal-header {
    padding: 16px 24px;
    background: #2a404f;
    border-radius: 12px 12px 0px 0px;
    h5 {
      font: 24px/32px var(--SVN-GilroyBold);
      color: #fff;
    }
    .close {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0;
      background: url("#{$img-path}/icon-close3.svg") no-repeat 0 0 / contain;
      position: absolute;
      right: 24px;
      top: 20px;
      opacity: 1;
    }
  }
  .modal-body {
    padding: 20px 24px 24px;
    p {
      color: var(--gray);
    }
  }
  .box-select-time {
    margin-top: 16px;
    background: #f4f5f6;
    border-radius: 12px;
    overflow: hidden;
    .item {
      &__header {
        padding-left: 5px;
        background: #606e78;
        height: 32px;
        color: #fff;
        line-height: 32px;
      }
      &__content {
        padding: 16px;
        border-right: 1px solid #fff;
      }
      &:first-child .item__header {
        padding-left: 24px;
      }
      &:last-child {
        .item__content {
          border-right: none;
        }
      }
    }
    .icon {
      margin-right: 0;
    }
    input {
      width: 126px;
      height: 64px;
      padding: 0;
      border-radius: 6px;
      background: #fff;
      border: none;
      text-align: center;
      font: 36px/48px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    button {
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 6px;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      opacity: 1;
      margin-right: 10px;
      border-radius: 5px;
      background: transparent;
    }
  }
}
.modal-submit {
  .modal-dialog {
    max-width: 350px;
  }
  .modal-content {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #eaeced;
    box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .modal-title {
    margin-bottom: 12px;
    text-align: center;
    font: 20px/28px var(--SVN-GilroyBold);
    color: var(--gray);
  }
  .content {
    margin: 0 auto 12px;
    width: 100%;
    max-width: 252px;
    font: 14px/20px var(--SVN-GilroyMedium);
    color: #22323e;
    .item {
      margin-bottom: 8px;
      justify-content: space-between;
    }
    p {
      font-family: var(--SVN-GilroyBold);
    }
    p span {
      color: var(--orange);
    }
  }
  .box-btn {
    justify-content: center;
    a,
    button {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      margin: 0 6px;
      font: 14px/22px var(--SVN-GilroyMedium);
    }
  }
  .close {
    width: 24px;
    height: 24px;
    background: url("#{$img-path}/icon-close1.svg") no-repeat 0 0 / contain;
    position: absolute;
    top: 16px;
    right: 16px;
    filter: brightness(0);
  }
}
.modal-note {
  .modal-dialog {
    max-width: 560px;
  }
  .modal-content {
    border-radius: 12px;
  }
  .modal-header {
    padding: 24px;
    background: #f7f9fc;
    border-radius: 12px 12px 0px 0px;
    display: block;
    color: var(--gray);
    border-bottom: 1px solid #eaeced;
    text-align: center;
    h3 {
      font: 24px/32px var(--SVN-GilroyBold);
      margin-bottom: 4px;
    }
    p {
      font: 20px/28px var(--SVN-GilroyMedium);
    }
  }
  .modal-body {
    padding: 24px 24px 8px;
    h4 {
      color: var(--gray);
    }
    .number {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border: 1px solid #2a404f;
      border-radius: 9999px;
      text-align: center;
      font: 14px/24px var(--SVN-GilroyBold);
      color: var(--gray);
    }
    .note-list {
      > li {
        margin-bottom: 16px;
        color: var(--gray);
      }
    }
    .note-sub {
      padding: 4px 0 0 60px;
      li {
        width: 50%;
        padding: 4px 0;
        font: 14px/22px var(--SVN-GilroyBold);
        color: var(--orange);
        list-style: disc;
      }
    }
    p {
      font: 16px/24px var(--SVN-GilroyMedium);
      flex: 1;
    }
    p strong {
      color: var(--orange);
    }
  }
  .modal-footer {
    display: block;
    padding: 24px;
    text-align: center;
    h4 {
      color: var(--orange);
    }
    .box-btn {
      justify-content: center;
      a,
      button {
        width: 120px;
        height: 48px;
        margin: 0 8px;
        border-radius: 8px;
        font-size: 16px;
      }
      .btn-sub {
        border: 1px solid #aab3b9;
        color: #606e78;
        &:hover {
          background: #606e78;
          color: #fff;
        }
      }
    }
  }
  .close {
    width: 24px;
    height: 24px;
    background: url("#{$img-path}/close1.svg") no-repeat 0 0 / contain;
    position: absolute;
    right: 24px;
    top: 24px;
    opacity: 1;
  }
  h4 {
    margin: 0 0 16px;
    font: 20px/28px var(--SVN-GilroyBold);
  }
}

//icon
.icon {
  margin-right: 8px;
}
.icon-policy {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-policy.svg") no-repeat 0 0 / contain;
}
.icon-term {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-term.svg") no-repeat 0 0 / contain;
}
.icon-phone {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-phone3.svg") no-repeat 0 0 / contain;
}
.icon-phone1 {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-phone3.svg") no-repeat 0 0 / contain;
}
.icon-email {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-email.svg") no-repeat 0 0 / contain;
}
.icon-email1 {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-mail1.svg") no-repeat 0 0 / contain;
}
.icon-facebook {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-facebook.svg") no-repeat 0 0 / contain;
}
.icon-gg {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-gg.svg") no-repeat 0 0 / contain;
}
.icon-class {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-class.svg") no-repeat 0 0 / contain;
}
.icon-school {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-school1.svg") no-repeat 0 0 / contain;
}
.icon-avt {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-avt.svg") no-repeat 0 0 / contain;
}
.icon-user {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-user1.svg") no-repeat 0 0 / contain;
}
.icon-facebook1 {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-facebook1.svg") no-repeat 0 0 / contain;
}
.icon-youtube {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-youtube.svg") no-repeat 0 0 / contain;
}
.icon-back {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-back1.svg") no-repeat 0 0 / contain;
}
.icon-time {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-time.svg") no-repeat 0 0 / contain;
}
.icon-guide {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-question1.svg") no-repeat 0 0 / contain;
}
.icon-menu {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-menu.svg") no-repeat 0 0 / contain;
}
.icon-pen {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-pen.svg") no-repeat 0 0 / contain;
}
.icon-pen1 {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-pen1.svg") no-repeat 0 0 / contain;
}
.icon-arr {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-next1.svg") no-repeat 0 0 / contain;
}
.icon-down {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-sub.svg") no-repeat 0 0 / contain;
}
.icon-up {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-add.svg") no-repeat 0 0 / contain;
}
.icon-reset {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-reset.svg") no-repeat 0 0 / contain;
}
.icon-turnoff {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-turnoff.svg") no-repeat 0 0 / contain;
}
.icon-video {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-video.svg") no-repeat 0 0 / contain;
}
.icon-doc {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-doc.svg") no-repeat 0 0 / contain;
}
.icon-star {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-star1.svg") no-repeat 0 0 / contain;
}
.icon-error {
  width: 24px;
  height: 24px;
  background: url("#{$img-path}/icon-warning.svg") no-repeat 0 0 / contain;
}
.icon-play1 {
  width: 32px;
  height: 32px;
  background: url("#{$img-path}/icon-play1.svg") no-repeat 0 0 / contain;
}
.icon-reset1 {
  width: 32px;
  height: 32px;
  background: url("#{$img-path}/icon-reset1.svg") no-repeat 0 0 / contain;
}
.icon-file {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-term1.svg") no-repeat 0 0 / contain;
}
.icon-password {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-pass.svg") no-repeat 0 0 / contain;
}
.icon-question {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-file1.svg") no-repeat 0 0 / contain;
}
.icon-hourglass {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-hour.svg") no-repeat 0 0 / contain;
}
.icon-save {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-save.svg") no-repeat 0 0 / contain;
}
.icon-y {
  width: 16px;
  height: 16px;
  background: #fc5b23;
  border-radius: 50%;
}
.icon-false {
  width: 16px;
  height: 16px;
  background: #fc5b23;
  border-radius: 50%;
}
.icon-n {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #aab3b9;
}
.icon-download {
  background: url("#{$img-path}/icon-download.svg") no-repeat 0 0 / contain;
}
.icon-share {
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/icon-share.svg") no-repeat 0 0 / contain;
}

.active {
  .icon-avt {
    background: url("#{$img-path}/icon-avt1.svg") no-repeat 0 0 / contain;
  }
  .icon-user {
    background: url("#{$img-path}/icon-user.svg") no-repeat 0 0 / contain;
  }
  .icon-policy {
    background: url("#{$img-path}/icon-policy1.svg") no-repeat 0 0 / contain;
  }
  .icon-term {
    background: url("#{$img-path}/icon-term2.svg") no-repeat 0 0 / contain;
  }
  .icon-phone {
    background: url("#{$img-path}/icon-phone.svg") no-repeat 0 0 / contain;
  }
  .icon-password {
    background: url("#{$img-path}/icon-pass1.svg") no-repeat 0 0 / contain;
  }
}

//footer
footer {
  background: #1d2b35;
  .container {
    max-width: 1200px;
  }
  .info {
    padding: 80px 0 64px;
    border-bottom: 1px solid rgba(#fff, 0.2);
    color: #fff;
    h2 {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 32px;
    }
    h2 span {
      font-family: var(--SVN-GilroyBold);
    }
    .col-left {
      width: 285px;
    }
    .col-right {
      width: calc(100% - 285px);
      padding-left: 125px;
      > * + * {
        margin-top: 8px;
      }
      p {
        font-size: 15px;
        color: #fff;
        line-height: 22px;
      }
      p span {
        font-family: var(--SVN-GilroyBold);
      }
    }
    .hotline {
      a {
        font: 24px/32px var(--SVN-GilroyMedium);
        color: var(--orange);
      }
    }
    .icon-phone {
      width: 24px;
      height: 24px;
      margin: 0 17px 0 0;
    }
  }
  .icon-phone {
    background: url("#{$img-path}/icon-phone1.svg") no-repeat 0 0 / contain;
  }
  .logo-white {
    width: 245px;
    margin: 64px auto;
  }
  .language {
    width: auto;
    box-shadow: none;
    margin-right: 0;
    padding: 0;
    button,
    > a {
      color: #fff;
    }
    .dropdown-menu {
      top: -100px;
      left: 0;
    }
  }
  .middle {
    h3,
    h5,
    li a {
      line-height: 20px;
    }
    h3 {
      margin-bottom: 16px;
      color: #fff;
      font: 16px/20px var(--SVN-GilroyBold);
      text-transform: uppercase;
    }
    h5 {
      font-weight: 500;
      color: #fff;
    }
    li a {
      display: inline-block;
      color: rgba(212, 229, 247, 0.7);
    }
    li a:hover {
      color: #fff;
    }
    .menu {
      margin-bottom: 48px;
      > * + * {
        margin-top: 20px;
      }
      span {
        font-family: var(--SVN-GilroyBold);
      }
    }
    .col {
      padding: 0;
      flex-basis: inherit;
      flex-grow: inherit;
    }
    .col1 {
      width: 145px;
      margin-right: 150px;
    }
    .col2 {
      width: 115px;
      margin-right: 100px;
    }
    .col3 {
      width: 170px;
      margin-right: 114px;
    }
    .col4 {
      width: 372px;
    }
  }
  .service {
    p {
      margin-bottom: 11px;
      font: $fontSizeContent var(--SVN-GilroyMedium);
      color: #fff;
    }
    span {
      font-size: 13px;
      color: #caf8fd;
      line-height: 18px;
    }
    .box-btn {
      margin-bottom: 20px;
      > * + * {
        margin-left: 16px;
      }
      a {
        display: flex;
        align-items: center;
        background: #2f4550;
        padding: 11px 15px;
        border-radius: 6px;
        font: 14px/18px var(--SVN-GilroyMedium);
        color: #fff;
      }
      .icon {
        margin-right: 9px;
        display: inline-block;
        width: 16px;
        height: 16px;
      }
      .icon-mail {
        margin-top: 2px;
        background: url("#{$img-path}/icon-email.svg") no-repeat 0 0 / contain;
      }
    }
  }
  .social {
    margin-top: 40px;
    p {
      margin-bottom: 16px;
      line-height: 20px;
      color: #fff;
    }
    p span {
      font-family: var(--SVN-GilroyBold);
    }
    a {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      font-size: 0;
      border: 1px solid rgba(#fff, 0.2);
      border-radius: 50%;
    }
    a:hover {
      background: rgba(#fff, 0.2);
    }
  }
  .bottom {
    margin-top: 16px;
    .container {
      padding: 40px 15px;
      border-top: 1px solid #16426f;
    }
    .navbar {
      height: auto;
    }
  }
  .copyright {
    line-height: 20px;
    color: rgba(212, 229, 247, 0.7);
    font-size: 18px;
    span {
      font-family: var(--SVN-GilroyBold);
    }
  }
    .fs-ftr {
        display: flex;
        margin-top: 20px;
    }
  .fs-ftr-cthuong {
    width: 132px;
    height: 50px;
    margin-top: -50px;
    background: url("#{$img-path}/bocongthuong.png") no-repeat 0 0 / contain;
    font-size: 0;
    position: absolute;
    right: 5px;
  }

  .fs-ftr-dmca {
    margin-top: 5px;
  }
}

//uikit
.container {
  padding: 0 15px;
}
.header.title {
  padding: 10px 0 64px;
  text-align: center;
}
img {
  max-width: 100%;
}
a {
  transition: 0.25s;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.pr {
  position: relative;
}
input:focus {
  outline: none;
}

.bg-gray {
  background: #f4f5f6;
}
.icon {
  display: block;
}
.m-show-flex {
  display: none;
}
.mt-100 {
  margin-top: 100px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-64 {
  margin-top: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
button {
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  transition: 0.25s;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
h2.title {
  font: 36px/48px var(--SVN-GilroyBold);
  color: var(--gray);
  text-align: center;
  span {
    display: block;
    color: var(--orange);
    position: relative;
    &.inline {
      display: inline-block;
    }
  }
  span.block {
    display: block;
  }
}
h1.title {
  font: 54px/64px var(--SVN-GilroyBold);
  color: var(--orange);
  text-align: center;
}
.fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.txt-1 {
  font-size: 18px;
  line-height: 28px;
}
.txt-5 {
  font-size: 18px;
  line-height: 24px;
}
.txt-2 {
  font: 20px/28px var(--SVN-GilroyBold);
  color: var(--gray);
}
.txt-3 {
  font: 36px/48px var(--SVN-GilroyBold);
  color: #fff7f2;
}
.txt-4 {
  font: 16px/24px var(--SVN-GilroyBold);
}
.bg-sh {
  background: #ffffff;
  box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.btn-pr {
  background: #ff7707;
  border-radius: 6px;
  color: #fff;
  font-family: var(--SVN-GilroyBold);
  &:hover {
    background: #e36b09;
    color: #fff;
  }
}
.btn-sub {
  border: 1px solid #ff7707;
  border-radius: 6px;
  color: var(--orange);
  font: 16px var(--SVN-GilroyBold);
  &:hover {
    background: var(--orange);
    color: #fff;
  }
}
.btn-fullscreen {
  background: url("#{$img-path}/icon-full.svg") no-repeat 0 0 / contain;
}
.slick-arrow {
  width: 40px;
  height: 40px;
  background: #ffffff url("#{$img-path}/icon-next.svg") no-repeat center center;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  &:hover {
    border: 1px solid #ff7707;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
}
.slick-prev {
  transform: translateY(-50%) rotate(180deg);
}
.checkbox {
  margin-bottom: 0;
  color: #2a404f;
  line-height: 22px;
  position: relative;
  .checked {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1.5px solid #aab3b9;
    border-radius: 4px;
  }
  input {
    opacity: 0;
    position: absolute;
  }
  input:checked + .checked {
    background: var(--orange) url("#{$img-path}/icon-check2.svg") no-repeat
      center center;
    border: none;
  }
}

.line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line3 {
  -webkit-line-clamp: 3;
}
.line2 {
  -webkit-line-clamp: 2;
}
.line4 {
  -webkit-line-clamp: 4;
}

.video {
  cursor: pointer;
  &:hover {
    .video__play {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
  &__play {
    @include center;
    transition: 0.3s;
    top: 40%;
    left: 50%;
  }
  &__thumb img {
    width: 100%;
  }
}
.video-ty1 {
  max-width: 790px;
  margin: 0 auto;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.08);
}
.bg-gradient {
  &::after {
    content: "";
    width: 100%;
    background: url("#{$img-path}/bg-gradient.svg") no-repeat 0 0/100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}
.bg-gr {
  background: url("#{$img-path}/bg-gradient.svg") no-repeat 0 0 / cover;
}
.line8::after {
  content: "";
  width: 154px;
  height: 8px;
  background: url("#{$img-path}/line8.svg") no-repeat 0 0 / contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  background: none;
  padding: 16px 0;
  margin: 0 auto;
  line-height: 22px;
  &-item {
    display: inline;
    &.active {
      color: #606e78;
    }
  }
  li {
    display: flex;
    align-items: center;
  }
  a {
    display: inline-block;
    color: #22323e;
    line-height: 22px;
  }
  a:hover {
    color: var(--orange);
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("#{$img-path}/arr-r1.svg") no-repeat 0 0 / contain;
  padding-right: 0;
  margin-right: 5px;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 4px;
}
.slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  li {
    margin: 0 6px;
    line-height: 0;
  }
  button {
    width: 10px;
    height: 10px;
    padding: 0;
    background: rgba(#b5b5b5, 0.2);
    border-radius: 50%;
    font-size: 0;
  }
  .slick-active button {
    background: var(--orange);
  }
}
.pagination {
  margin-top: 18px;
  align-items: center;
  justify-content: center;
  li {
    margin: 0 8px;
  }
  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid #eaeced;
    font-size: 14px;
    border-radius: 50% !important;
    color: #606e78;
    margin-left: 0;
    &:hover {
      border: 1px solid var(--orange);
    }
  }
  .active .page-link {
    background: #fff;
    color: var(--orange);
    border: 1px solid var(--orange);
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 0;
    background: url("#{$img-path}/arr-r.svg") no-repeat;
  }
  .icon-prev {
    transform: rotate(180deg);
  }
}
.tab-pr {
  justify-content: center;
  a {
    display: block;
    width: 88px;
    padding: 0 0 12px;
    border-bottom: 2px solid transparent;
    text-align: center;
    color: #80909c;
    font: 18px/26px var(--SVN-GilroyBold);
    position: relative;
  }
  .active {
    color: var(--orange);
    border-bottom: 2px solid var(--orange);
  }
}
.tab-pr--cs1 {
  border-bottom: 1px solid #eaeced;
  a {
    padding: 12px 0;
  }
  .active {
    border-bottom: 2px solid #fdd13c;
    background: #ff7707;
    color: #fffbf7;
  }
}
.tab-sub {
  justify-content: center;
  margin: 32px 0 56px;
  > * + * {
    margin-left: 16px;
  }
  a {
    display: inline-block;
    padding: 5.5px 21.5px;
    margin-bottom: 5px;
    border: 1px solid #eaeced;
    border-radius: 9999px;
    font-family: var(--SVN-GilroyMedium);
  }
  .active {
    color: #fff;
    border: 1px solid transparent;
    background: var(--orange);
    text-shadow: 0 0 1px currentColor;
  }
}
.tab-sub--cs1 {
  .active {
    border: 1px solid var(--orange);
    background: transparent;
    color: var(--orange);
  }
}
.box-act {
  background: #fff;
  padding: 8px;
  border-radius: 12px;
  overflow: auto;
  filter: drop-shadow(24px 24px 80px rgba(0, 0, 0, 0.08));
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #eaeced;
  }
  > * + * {
    margin-top: 8px;
  }
  button {
    width: 40px;
    height: 40px;
    border: 1px solid #eaeced;
    border-radius: 6px;
    &.active {
      background: var(--orange);
    }
  }
  .icon {
    margin-right: 0;
    width: 24px;
    height: 24px;
  }
  .icon-act1 {
    background: url("#{$img-path}/icon-st1.svg") no-repeat;
  }
  .icon-act2 {
    background: url("#{$img-path}/icon-st2.svg") no-repeat;
  }
  .icon-act3 {
    background: url("#{$img-path}/icon-st3.svg") no-repeat;
  }
  .icon-act4 {
    background: url("#{$img-path}/icon-st5.svg") no-repeat;
  }
  .icon-act5 {
    background: url("#{$img-path}/icon-st6.svg") no-repeat;
  }
  .icon-act6 {
    background: url("#{$img-path}/icon-st7.svg") no-repeat;
  }
  .icon-act7 {
    background: url("#{$img-path}/icon-st8.svg") no-repeat;
  }
  .icon-act8 {
    background: url("#{$img-path}/icon-st9.svg") no-repeat;
  }
  .icon-act9 {
    background: url("#{$img-path}/icon-st10.svg") no-repeat;
  }
  .icon-act10 {
    background: url("#{$img-path}/icon-st11.svg") no-repeat;
  }
  .icon-act11 {
    background: url("#{$img-path}/icon-st12.svg") no-repeat;
  }
  .icon-act12 {
    background: url("#{$img-path}/icon-st13.svg") no-repeat;
  }
  .icon-text {
    background: url("#{$img-path}/icon-st14.svg") no-repeat;
  }
  .icon-image {
    background: url("#{$img-path}/icon-st15.svg") no-repeat;
  }
  .icon-music {
    background: url("#{$img-path}/icon-music.svg") no-repeat;
  }
  .icon-media {
    background: url("#{$img-path}/icon-st16.svg") no-repeat;
  }
}

.progress1 {
  position: relative;
  width: 40px;
  height: 40px;
  canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  span {
    display: block;
    text-align: center;
    width: 40px;
    font: 12px/40px var(--SVN-GilroyBold);
    color: var(--gray);
  }
}
@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.chapter div[role="progressbar"] {
  --size: 40px;
  --fg: #ff7707;
  --bg: #eaeced;
  --pgPercentage: var(--value);
  margin-left: auto;
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
}

.chapter div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  font: 12px var(--SVN-GilroyBold);
  color: var(--gray);
}

//custom select2.min.js
.select2-container {
  width: 100% !important;
  border: none;
  background: #fff;
  border-radius: 6px;
  &:focus {
    outline: none;
  }
}
.selection,
.select2-selection {
  &:focus {
    outline: none;
  }
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 16px;
  color: #19252e;
  font-size: 14px;
}
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-radius: 6px;
}
.select2-container .select2-selection--single {
  height: 40px;
  width: 100%;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #eaeced;
  border-radius: 6px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
}
.select2-search--dropdown {
  display: none;
}
.select2-container--default .select2-results__option--selected {
  background: #e0e0e0 !important;
  color: var(--blue) !important;
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: none;
  color: #212121;
}
.select2-container--default .select2-results__option:hover {
  background: #efefef;
  color: #212121;
}
.select2-container--open .select2-dropdown--below {
  padding: 16px;
  margin-top: 4px;
  background: #fff;
  box-shadow: 1px 4px 24px rgba(209, 209, 209, 0.25);
  border-radius: 8px;
  border: none;
}
.select2-container--default .select2-results > .select2-results__options {
  padding: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 0;
  width: 20px;
  height: 20px;
  background: url("#{$img-path}/arr-r.svg") no-repeat 0 0 / contain;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: 0;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 0;
  transform: translateY(-50%) rotate(-90deg);
}
.select2-container--default .select2-results > .select2-results__options {
  padding: 0;
  background: #fff;
  border-radius: 8px;
  > * + * {
    margin-top: 12px;
  }
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #fcfcfc;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}
.select2-results__option {
  padding: 0;
  line-height: 22px;
  color: #2a404f;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background: none;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  padding-right: 18px;
  background: url("#{$img-path}/tick.svg") no-repeat right center;
  color: #4e4c50;
  text-shadow: 0.5px 0 0 currentColor;
}

//animated
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.js-animation {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
  visibility: hidden;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes flash {
  0% {
    -webkit-filter: saturate(1);
    filter: saturate(1);
  }
  50% {
    -webkit-filter: saturate(2.1);
    filter: saturate(2.1);
  }
  100% {
    -webkit-filter: saturate(1);
    filter: saturate(1);
  }
}
@-webkit-keyframes flash {
  0% {
    -webkit-filter: saturate(1);
    filter: saturate(1);
  }
  50% {
    -webkit-filter: saturate(2.1);
    filter: saturate(2.1);
  }
  100% {
    -webkit-filter: saturate(1);
    filter: saturate(1);
  }
}
.flash {
  animation-name: flash;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes moveheader {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(0px);
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-activate-book {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #ff7707;
  border-radius: 4px;
  color: var(--orange);
  font: calc($fontSizeContent/20px) var(--SVN-GilroyBold);
  cursor: pointer;

  .icon {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: url("#{$img-path}/icon-key.svg") no-repeat 0 0 / contain;
  }
}
