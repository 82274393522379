.buttonAddClass {
  // width: 130px;
  padding-right: 5px;
  padding-left: 5px;
  height: 36px;
  border-radius: 5px;
  background: #ff7707;
  color: #fff;
}
@media (max-width: 668px) {
  .buttonAddClass {
    width: 200px;
    // margin-left: 16px;
  }
}
.schoolYearSelect {
  width: 200px;
  // margin-right: 30px;
  // width: 50%;
}
.itemClass {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  line-height: 2;
}
.fontSize20 {
  font-size: 20px;
}
.setting {
  top: 35px;
  right: 35px;
}
.menuSetting {
  border-radius: 12px;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
}
.colorOrange {
  color: #ff7707;
}
.iconOrange {
  color: #ff7707;
  min-width: 20px;
}
.modalDialog {
  border-radius: 12px;
  padding: 0;
}
.width65 {
  width: 65%;
}
.width35 {
  width: 35%;
}
.iconSearch {
  top: 10px;
  left: 8px;
}
.fontSize18 {
  font-size: 18px;
}
.tabActive {
  color: #ff7707;
  border-bottom: 2px solid #ff7707;
  padding-bottom: 5px;
}
.buttonOrange {
  padding-right: 8px;
  padding-left: 8px;
  height: 36px;
  border-radius: 5px;
  background: #ff7707;
  color: #fff;
  min-width: 100px;
}
.emailItem {
  font-size: 14px;
  width: 95%;
}
.btnCoppy:hover {
  color: #ff7707;
}
.linkWrapper {
  border: 1px solid #eaeced;
  border-radius: 5px;
  width: 220px;
  height: 36px;
}
.uploadFile {
  width: 310px;
  height: 100px;
  border: 0.5px dashed #2a404f;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 30px;
}
.iconUpload {
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
}
.input {
  height: 36px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #eaeced;
}
.bgrColorGray {
  background-color: #c4c4c4;
}
.tdDelete {
  width: 25px;
}
.table tbody tr .btnDelete {
  color: #ff7707;
}
.table tbody tr:hover .btnDelete {
  display: inline-block;
  color: #ff7707;
  opacity: 0.6;
}
.listEmail {
  max-height: 200px;
}
.btnBack {
  left: -12px;
  top: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 9px;
}
.input:disabled {
  background-color: #fff;
  border: 1px solid #ced4da;
  height: 38px;
}
.backButton {
  width: 52px;
  height: 47px;
  background: #ffffff;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 9px;
  position: absolute;
  left: -30px;
  img {
    position: relative;
    top: 10px;
    left: 10px;
  }
}
.tabExercise {
  height: 45px;
  text-align: center;
  justify-content: center;
  ul {
    li {
      width: auto;
      background-color: #ffffff;
      padding: 10px 40px;
    }
    li:first-child {
      box-sizing: border-box;
      box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
      border-radius: 12px 0px 0px 12px;
      position: relative;
    }
    li:first-child::after {
      content: "|";
      color: #ff7707;
      position: absolute;
      left: 100%;
    }
    li:last-child {
      box-sizing: border-box;
      box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
      border-radius: 0px 12px 12px 0px;
    }
  }
}
.activeTab {
  span {
    color: #ff7707;
  }
}
.optionSelect {
  width: 150px;
}
.btnAddExercise {
  height: 40px;
  line-height: 40px;
  background-color: #ff7707;
  color: #ffffff;
  font-style: normal;
  font-size: 16px;
  padding: 0px 22px;
  border-radius: 8px;
  position: relative;
  &:hover {
    opacity: 0.9;
  }
  img {
    margin-top: 8px;
    position: absolute;
    left: 10px;
  }
  span {
    line-height: 45px;
    margin-left: 20px;
  }
}
.excerciesItem {
  background-color: #ffffff;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  .mainContent {
    line-height: 60px;
  }
  span:not(:last-child)::after {
    content: "|";
    padding: 0 10px;
    color: #ff7707;
  }
}
.btnAssignment {
  margin-top: 10px;
  height: 45px;
  background-color: #ff7707;
  border-radius: 8px;
  position: relative;
  line-height: 45px;
  img {
    padding-left: 30px;
    padding-right: 15px;
    margin-top: 11px;
  }
  span {
    color: #ffffff;
    padding-right: 30px;
    font-weight: 700;
    font-size: 16px;
  }
}
.btnShare {
  margin-top: 10px;
  height: 45px;
  width: 45px;
  border: 1px solid #ff7707;
  margin-left: 15px;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  background-color: #ffffff;
  position: relative;
  font-size: 20px;
  color: #ff7707;
}
.report div:hover {
  opacity: 0.6;
  transition: 0.3s ease-in-out;
}
