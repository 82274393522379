.SQC_001__wrapper {
  ol {
    li {
      margin-left: 20px;
    }
  }

  .header-question__wrapper {
    ol {
      margin-left: 20px;
    }
  }

  .content-answer__wrapper {
    display: flex;
    justify-content: center;
  }
  .content-answer__wrapper.view-only {
    pointer-events: none;
  }
}