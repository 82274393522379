.questionWrapper {
  border-radius: 10px;
}

.borderRad7 {
  border-radius: 7px;
}

.imgQuestion {
  flex: 1;
  img {
    z-index: 1;
  }
}

.videoQuestion {
  flex: 1;
}

.audioAnswer {
  top: 5px;
  left: 18px;
  z-index: 1;
}

.iconAudio {
  top: 5px;
  left: 10px;
  &:hover {
    color: #00c2f3;
  }
  i {
    font-size: 14px;
  }
}

.imgAnswer {
  img {
    max-width: 100%;
    height: 160px;
    &:hover {
      transform: scale(1.5);
      transition: 1s;
    }
  }
}

.answerSelected:hover,
.answerDefault:hover,
.answerCorrect:hover,
.answerIncorrect:hover {
  border: 2px solid #f70;
}
.answerSelected {
  border: 2px solid #f70;
}
.answerDefault {
  border: 2px solid #eaeced;
}
.answerCorrect {
  border: 2px solid #23bf2d;
}
.answerIncorrect {
  border: 2px solid red;
}
