.ExerciseDetails button {
  padding: 10px 20px;
  line-height: 25px;
}
.table {
  width: 100%;
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  thead tr {
    th:not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }
  }
  tr {
    th,
    td {
      border-bottom: 1px solid #c4c4c4;
      line-height: 50px;
    }
  }
  th {
    border-top: 0px !important;
    line-height: 50px;
  }
  tbody {
    tr:last-child {
      th,
      td {
        border-bottom: 0px solid #ffffff !important;
      }
    }
  }
}
.page {
  width: 100%;
  text-align: center;
}
.btnBack {
  left: -12px;
  top: 0;
  background-color: #fff;
  padding: 10px;
  border-radius: 9px;
}
@media screen and (max-width: 800px) {
  .table {
    tr {
      th,
      td {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
  .ExerciseDetails {
    button {
      padding: 6px 12px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 600px) {
  .table {
    tr {
      th,
      td {
        font-size: 12px;
        line-height: 25px;
      }
    }
  }
  .ExerciseDetails {
    button {
      padding: 3px 6px;
      font-size: 12px;
    }
  }
}
.total {
  font-size: 12px;
  color: #ff7707;
  margin-left: 5px;
}
@media screen and (max-width: 600px) {
  .total {
    position: absolute;
    bottom: 8px;
    width: 100%;
    left: 0;
  }
}
