.typography__latex-wrapper {
  div {
    display: inline !important;
  }
  div:global(#preview) {
    padding: 8px !important;
  }
  ol {
    margin-left: 65px;
    display: inline-block;
  }
}

.z-index-game-button {
  z-index: 20;
}
