.doing-exercise__result__wrapper {
  .graph-board__graph002 {
    position: relative !important;
    margin-top: 40px;
  }

  .SROI_001__container {
    padding-left: 10px !important;

    .content {
      flex-direction: column;
      .col-left, .col-right {
        width: 100%;
      }
      .col-left {
        margin-bottom: 20px;
      }
    }
  }
}