.report {
  button {
    padding-right: 8px;
    padding-left: 8px;
    height: 36px;
    border-radius: 5px;
    background: #ff7707;
    color: #fff;
  }
  i:last-child {
    color: #ff7707;
  }
}
.headerExercises {
  padding-bottom: 15px;
  top: 50px;
  background: #ffffff;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.school {
  position: absolute;
  left: 40px;
}
.student {
  display: flex;
  span:not(:last-child)::after {
    content: "|";
    color: #ff7707;
    padding: 0 10px;
  }
}
.main .active_exer,
.detail {
  color: #ff7707 !important;
}
.detail {
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
}
.table {
  width: 100%;
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  thead tr {
    th:not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }
  }
  tr {
    th,
    td {
      border-bottom: 1px solid #c4c4c4;
    }
  }
  th {
    border-top: 0px !important;
  }
  tbody {
    tr:last-child {
      th,
      td {
        border-bottom: 0px solid #ffffff !important;
      }
    }
  }
}
.select {
  div:first-child {
    min-width: 80px;
  }
}
.main {
  .active {
    position: relative;
    i,
    span {
      color: #ff7707;
    }
  }
  .active::before {
    content: "";
    background-color: #ff7707;
    width: 120%;
    height: 2px;
    position: absolute;
    bottom: -10px;
    right: -10%;
  }
}
.back {
  position: absolute;
  left: -50px;
  top: -20%;
  padding: 15px;
  background-color: #ffffff;
}
.now {
  text-align: -webkit-center;
  button {
    padding: 10px 20px;
  }
}
.page {
  width: 100%;
  text-align: center;
}
