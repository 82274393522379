.option {
  position: absolute;
  right: 0;
  bottom: 0;
  div:first-child {
    min-width: 140px;
  }
}
.setting {
  position: absolute;
  top: 0;
  right: 0;
  button i {
    color: #ff7707;
  }
}
.addClass {
  p {
    font-size: 30px;
    line-height: 24px;
    color: #2a404f;
    margin-bottom: 30px;
  }
  button {
    padding: 15px 50px;
    margin-left: 65px;
  }
}
.menu {
  border-radius: 12px;
  box-shadow: 12px 12px 40px rgb(0 0 0 / 6%);
}
.listClass {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 12px 12px 40px rgb(0 0 0 / 6%);
  line-height: 2;
}
