.wordFinding__wrapper {
  .audio-title__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .question-audio__wrapper {
    display: inline-block;
    margin-right: 1rem;
  }

  .wordFinding__answer {
    .textItem {
      border-style: solid;
      border-width: 2px;
      border-radius: 50rem;
      line-height: 1.5;
      cursor: pointer;
      padding: 0 0.25rem;
    }
    .textItem:hover {
      color: #00c2f3;
    }
  }
  .wordFinding__answer.view-only {
    pointer-events: none;
  }
}
