.FIB_001__wrapper {
  height: 85%;
  overflow-y: auto;

  .answer-items__wrapper {
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .answer-items__wrapper.hasMedia {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
  }

  .content-editable {
    display: inline-block;
    width: auto;
    padding: 8px 16px;
    margin: 8px;
    border-radius: 5px;
    border: 2px solid #939598;
  }

  .content-editable:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
  }
}