.textLeftv1 {
  margin: 10px 10px;
  line-height: 1.5em;
}
.textLeftv1 > p:nth-child(1) {
  text-align: center;
  margin-bottom: 25px;
  width: 400px;
  margin-left: 40px;
}
.textLeftv1 > p:nth-child(2) {
  margin-bottom: 25px;
  width: 400px;
  margin-left: 10px;
}
.titlePopup {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.itemContact {
  display: inline-block;
  width: 20%;
}
