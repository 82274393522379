.game-container {
  height: 92%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title-wrapper {
  min-height: 68px;
  width: 95%;
}

.text {
  div:first-child div {
    padding-left: 10px;
  }

  ol li:first-child {
    margin-left: 20px;
    margin-right: 25px;
  }
}
