.InputDate{
    input{
        width:186%;
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 34px;
    }
    img{
        position:absolute;
        pointer-events: none;
        top: 38px;
        right: 20px;
        }

    @media screen and (max-width: 992px){
        input {
            width: 120%;
        }
        img {
            left: 212px
        }
    }

}
.label-teacher {
    background: #F9CB9C;
}
.label-parent {
    background: #EAD1DC;
}
.label-sharing {
    background: #b2c4d7;
}
.height-table {
    min-height: 400px;
}
