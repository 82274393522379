@media screen and (max-width: 767px) {
    .table {
        tbody td {
            padding: 0px;
            vertical-align: middle;
        }
        thead th {
        font-size:14px;
        }
    }

    .title p {
        font-size: 18px;
    }

    .titleGradeAndSubject div {
        font-size: 18px;
    }
    
  }