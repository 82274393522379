.textLeftv1 {
  margin: 10px 10px;
  margin-bottom: 80px;
  line-height: 1.5em;
}
.textLeftv1 > p:nth-child(1) {
  text-align: center;
  margin-bottom: 25px;
  width: 400px;
  margin-left: 40px;
}
.textLeftv1 > p:nth-child(2) {
  margin-bottom: 25px;
  width: 400px;
  margin-left: 10px;
}
.textLeftv1 > button {
  margin-top: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.buttonLogin {
  width: 128px;
  height: 48px;
}
.textWarning {
  color: #2a404f;
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
}
.buttonClose {
  position: absolute;
  top: 10px;
  right: 10px;
}
