.tableMenu {
    border: 1px solid;
    padding: 0 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 10px;

    h3 {
        border-bottom: 1px solid #212222;
        text-align: center;
        font-size: 18px;
        color: black;

        p {
            padding: 10px;
            font-family: 'SVN-GilroyMedium';
        }
    }

    li {
        border-top: 1px solid #dee2e6;
        padding-top: 10px;
    }
}