:root {
  --COLOR-ORIGIN: #ff7707;
  --COLOR-CONTENT: #19252e;
  --COLOR-TIMER: #80909c;
  --COLOR-TITLE: #2a404f;
  --COLOR-BODER: #eaeced;
  --COLOR-WHITE: #fff;
  --SVN-GilroyBold: SVN-GilroyBold, sans-serif;
}

td {
  color: #000000;
}
.contentDetail {
  td {
    padding: 0.4rem;
  }
  .contentItem {
    .contentItemViewDetail {
      color: var(--COLOR-TITLE);
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        font-weight: bold;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      h1 {
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
      }
      h2 {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
      }
      h3 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
      h4 {
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
      }
      h5 {
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
      }
      h6 {
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
      }
      ol {
        padding-left: 40px;
      }
    }
  }
}

.knowledgeSubject {
  color: var(--COLOR-TITLE);
  font: 20px/28px var(--SVN-GilroyBold);
}

.sharing {
  max-width: 431px !important;
  font-size: 54px;
  font-family: var(--SVN-GilroyBold);
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  align-items: center;
  color: var(--COLOR-TITLE);
  padding-top: 20px;
}

.height100 {
  height: 100vh;
  div {
    margin-top: 100px;
  }
}

.newPostItem {
  margin-top: 35px;
}

.newPostItem:hover,
.schoolLevel:hover {
  transform: scale(1.05);
  transition: 0.3s ease-in-out;
}

.subjectFilter {
  padding: 4px 8px;
  gap: 10px;
  background: var(--COLOR-BODER);
  border-radius: 32px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: auto;
  cursor: context-menu !important;
}

.iconClose {
  color: var(--COLOR-TIMER);
  padding-left: 8px;
  width: 40px;
  cursor: pointer;
}

.iconClose:hover {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.headerContent {
  padding-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #606e78;
  font-family: SVN-GilroyRegular, sans-serif;
}

.inforGrade {
  display: flex;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.thumb {
  position: absolute;
  left: 55.14%;
  right: 15.75%;
  top: 9.93%;
  bottom: 11.91%;
}

.filter {
  display: none;
}

.filterContainer {
  width: auto;
  display: inline;
  p {
    margin-bottom: 25px;
  }
}

.containerGradeChild {
  padding-top: 80px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--COLOR-ORIGIN);
  padding-bottom: 40px;
  padding-top: 10px;
  font-family: SVN-GilroyBold, sans-serif;
}

.contentItem {
  i {
    font-size: 12px;
  }
}

.schoolLevel {
  background: #fffbf7;
  border-radius: 8px;
  height: 210px;
  justify-content: center;
  align-items: center;
  font-family: SVN-GilroyBold, sans-serif;
  margin-top: 25px;
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: var(--COLOR-TITLE);
    padding-top: 15px;
  }

  img {
    margin: auto;
  }
}

.router {
  top: 0;
  padding-left: 20px;
}

.iconFilter {
  color: var(--COLOR-ORIGIN);
}

.containerSchoolLevel {
  .schoolLevel {
    padding-top: 22%;
  }
}
// New post
.newPostContainer {
  padding-top: 160px;
  .img {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    padding-bottom: 30px;
    height: 195px;
    cursor: pointer;
  }
  .title {
    font-family: SVN-GilroyBold, sans-serif;
  }

  .timeCreate {
    color: var(--COLOR-TIMER);
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 15px;
  }

  .newPostIcon {
    padding-right: 10px;
  }
}

.newPostDetail {
  width: 100%;
  box-shadow: 24px 24px 80px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: var(--COLOR-WHITE);
  height: 415px;
  .img {
    display: flex;
    justify-content: center;
  }
}
.otherKnowContainer {
  .img {
    height: 195px;
  }
  .newPostDetail {
    height: 100%;
  }
  .timeCreate {
    color: var(--COLOR-TIMER);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 15px;
  }
  .newPostIcon {
    padding-right: 10px;
  }
  .description {
    font-family: SVN-GilroyRegular, sans-serif;
    color: var(--COLOR-CONTENT);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    opacity: 0.9;
  }
  .label {
    line-height: 50px;
  }
  .news {
    padding-top: 80px;
  }
  .newPostItem {
    margin-top: 20px;
  }
}

.newPostDetail:hover {
  box-shadow: 3px 5px 6px 10px #f1ebeb;
  transition: 0.3s ease-in-out;
}

.subject,
.class {
  justify-content: center;
  align-items: center;
  padding: 6px 8px 4px;
  width: 54px;
  height: 28px;
  background: #f4f5f6;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.titleContent {
  font-family: SVN-GilroyMedium, sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  color: var(--COLOR-CONTENT);
  margin-bottom: 10px;
  margin-top: 10px;
  height: 56px;
}

.titleContent:hover,
.description:hover,
.relatedPostsTile:hover {
  opacity: 0.7;
  transition: 0.3s ease-in-out;
}

.inforMation {
  padding-left: 25px;
  padding-right: 25px;
  margin: auto;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-family: SVN-GilroyRegular, sans-serif;
  color: var(--COLOR-CONTENT);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  opacity: 0.9;
  height: auto;
  min-height: 70px;
  overflow: hidden;
}

.containerReferences {
  .description {
    min-height: 20px;
  }
}

.pagination {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 160px;
}

.containerDetail {
  //ViewDetail
  p span {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
  }
  .contentDetail {
    font-family: SVN-GilroyLight, sans-serif;
    font-size: 16px;
    line-height: 1.7;
    font-weight: 500;
    h2 {
      font-weight: 700;
      font-size: 26px;
    }
  }
  .titleViewDetail {
    .timeCreate {
      font-size: 14px;
    }
  }
  .heading {
    padding-left: 20px;
    li,
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 35px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .postNews {
    display: none;
    line-height: 1.4;
  }
  .detailAuthor {
    width: 90%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .share {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px 4px;
    gap: 10px;
    width: 68px;
    height: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background: rgb(230, 228, 228);
    border-radius: 4px;
    font-family: SVN-GilroyLight, sans-serif;
  }
  .content {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    overflow: hidden;
    color: var(--COLOR-TITLE);
    font-family: SVN-GilroyBold, sans-serif;
  }
  .timeCreate {
    font-family: SVN-GilroyLight, sans-serif;
    .newPostIcon {
      margin-right: 10px;
    }
    .newPostIcon:last-child {
      margin-left: 25px;
    }
  }
  .inforAuthor {
    position: relative;
    display: flex;
    div:first-child {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      overflow: hidden;
    }
    .authorName {
      padding-left: 10px;
      margin: auto 0;
    }
    strong {
      color: var(--COLOR-TITLE);
      font-size: 14px;
      line-height: 22px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      font-family: SVN-GilroyLight, sans-serif;
    }
  }
  .documentRefer {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--COLOR-ORIGIN);
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #f4f5f6;
    border-radius: 8px;
    padding-bottom: 25px;
    word-wrap: break-word;
    div {
      li,
      p {
        padding-top: 20px;
        padding-left: 35px;
        position: relative;
        a {
          color: var(--COLOR-ORIGIN);
        }
      }
      p:before {
        content: ".";
        color: var(--COLOR-ORIGIN);
        font-size: 30px;
        position: absolute;
        top: 12px;
        left: 20px;
      }
    }
  }
  .shareBlock {
    position: relative;
    justify-content: flex-end;
    right: 0;
    padding-top: 25px;
    .headerText {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--COLOR-TITLE);
      justify-content: end;
      cursor: pointer;
      display: flex;
    }
    span {
      display: flex;
      margin-top: 3px;
      padding-left: 15px;
      line-height: 30px;
    }
    .headerText:hover,
    button:hover {
      color: var(--COLOR-ORIGIN);
      transition: 0.3s ease-in-out;
      opacity: 0.8;
    }
  }
  //share
  .triangle {
    width: 0px;
    height: 0px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 14px solid var(--COLOR-WHITE);
    margin: 0 auto;
    position: absolute;
    right: 15%;
    top: -12px;
  }
  .shareContent {
    margin-top: 10px;
    position: relative;
    padding: 10px 14px 18px;
    width: 340px;
    position: absolute;
    top: 50px;
    background: var(--COLOR-WHITE);
    border-radius: 5px;
    box-shadow: 4px 8px 20px rgb(0 0 0 / 40%);
    z-index: 10;
    right: 0;
  }
  .copyText {
    position: relative;
    padding: 6px;
    background: var(--COLOR-WHITE);
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    margin-top: 8px;
    input {
      font-size: 14px;
      border: none;
      outline: none;
      width: 88%;
    }
  }
}

.tableContainer {
  .folderHeader {
    position: relative;
    font-family: SVN-GilroyBold, sans-serif;
    font-size: 20px;
    line-height: 28px;
    color: var(--COLOR-TITLE);
    img {
      margin-bottom: 3px;
      padding-right: 10px;
    }
  }
}

.relatedPostsTile {
  font-size: 14px !important;
  cursor: pointer;
}

//LatestNews
.latestNewsContainer {
  padding-top: 85px;

  .description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0;
    p {
      font-size: 14px !important;
    }
  }
  .timeCreate {
    font-weight: 500;
    font-size: 14px;
    color: var(--COLOR-TIMER);
    padding-top: 35px;
  }
  .newPostIcon {
    padding-right: 10px;
  }
  .LatestNewsTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: var(--COLOR-CONTENT);
    font-family: SVN-GilroyMedium, sans-serif;
    padding-top: 15px;
  }
  .LatestNewsTitle:hover {
    opacity: 0.7;
    transition: 0.3s ease-in-out;
  }
}

.subjectContainer {
  .news {
    display: flex;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: var(--COLOR-CONTENT);
    }
    .label {
      line-height: 50px;
    }
  }
  .subjectTop {
    margin-top: 95px;
  }
  p {
    background: none;
    padding-left: 0;
  }
  .newPostContainer {
    padding-top: 0;
    .description {
      margin-bottom: 35px;
    }
  }
}

.options {
  width: 50%;
  max-width: 150px;
  margin-left: 15px;
  margin-top: 5px;
}
.otherKnowContainer {
  .fontSizeTitle {
    padding-right: 40px;
  }
  .sharing {
    max-width: 100% !important;
    span {
      width: 200px;
    }
  }
  .headerContent {
    max-width: 410px;
  }
  .thumb {
    position: initial;
    left: 37%;
    top: 0;
  }
  .router {
    padding-left: 0;
  }
  .sliderBlog {
    padding-top: 50px;
    min-height: 384px;
    background: #fffbf7;
  }
  .contentBanner {
    padding-top: 120px;
  }
}
.hr {
  display: none;
}
.containerKnowledge {
  padding-left: 10px;
}
//responsive
@media screen and (max-width: 1300px) and (min-width: 992px) {
  .thumb {
    position: absolute;
    width: auto;
    height: 40%;
    left: 55.14%;
    right: 25.75%;
    top: 2%;
    bottom: 11.91%;
    img {
      width: 53%;
    }
  }
  .description {
    max-height: 95px;
  }
  .title {
    padding-top: 0;
  }
  .titleViewDetail {
    padding-left: 20px;
    padding-right: 20px;
  }
  .containerKnowledge {
    padding-left: 50px;
  }
  .otherKnowContainer {
    .thumb {
      right: 20%;
      img {
        width: 100%;
      }
    }
    .containerKnowledge {
      padding-left: 15px;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .containerDetail .postNews div:first-child {
    position: sticky;
    top: 60px;
  }
  .schoolLevel {
    height: 175px;
  }
  .title {
    padding-top: 0;
  }
  .viewOtherTilte {
    .inforAuthor {
      margin-top: 30px;
    }
  }
  .otherKnowContainer {
    .router {
      padding-bottom: 0 !important;
    }
    .thumb {
      right: 0;
      img {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
    }
    .contentBanner {
      padding-top: 60px;
    }
    .sharing {
      span {
        width: 180px;
        font-size: 54px;
      }
    }
  }
  .container {
    max-width: 768px;
    margin: 0 auto;
  }
  .description {
    max-height: 80px;
  }
  .sharing,
  .headerContent {
    margin: 0 !important;
    text-align: left;
  }

  .headerContent {
    padding-top: 35px;
  }

  .containerKnowledge {
    width: 100% !important;
  }

  .itemKnowledge {
    padding-left: 20px;
  }

  .thumb {
    img {
      margin-top: 5%;
      width: 30%;
      margin-left: 10%;
    }
  }

  .title {
    padding-bottom: 30px;
  }

  .newPostContainer {
    padding-top: 80px;
  }

  .pagination {
    text-align: center;
    padding-top: 17px;
    padding-bottom: 85px;
  }
  //ViewDetail
  .containerDetail {
    .titleViewDetail {
      margin-left: 20px;
      margin-right: 20px;
    }

    .detailAuthor {
      display: block;
      width: 100%;
    }
    .banner {
      border-radius: 12px;
      .boxImg {
        border-radius: 12px;
        max-width: 350px;
        max-height: 253px;
        overflow: hidden;
        img {
          width: auto;
        }
      }
    }
    .postNews {
      display: block;
      .postNewsTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4;
        color: var(--COLOR-TITLE);
        font-family: SVN-GilroyBold, sans-serif;
      }
    }

    .register {
      // width: 255px;
      height: 248px;
      background: var(--COLOR-WHITE);
      border-radius: 12px;
      position: relative;
      .header {
        padding: 43px 15px 15px 15px;
        input {
          margin-top: 15px;
          padding: 7px 10px;
          background: var(--COLOR-WHITE);
          border: 1px solid var(--COLOR-BODER);
          border-radius: 6px;
          width: 100%;
        }
        input::placeholder {
          color: #aab3b9;
          font-weight: 500;
          font-size: 14px;
          font-family: SVN-GilroyLight, sans-serif;
        }
        .buttonSubmit {
          background: var(--COLOR-ORIGIN);
          border-radius: 6px;
          width: 100%;
          padding: 7px 10px;
          margin-top: 15px;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: var(--COLOR-WHITE);
        }
      }
      .headerPostNews {
        font-size: 18px;
        line-height: 26px;
        color: var(--COLOR-ORIGIN);
        font-weight: 700;
        font-family: SVN-GilroyBold, sans-serif;
        position: relative;
        img {
          position: absolute;
          top: 20px;
        }
      }
      .registerBackground {
        position: absolute;
        right: 0;
        top: 10%;
      }
    }
    .shareBlock {
      padding-bottom: 20px;
      p {
        justify-content: center;
      }
    }

    .newPostContainer {
      padding-top: 0;
    }
  }

  //latestNews
  .latestNewsContainer {
    .img {
      padding-bottom: 35px;
    }
    .LatestNewsTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 10px;
    }
    .description {
      padding: 0;
    }
    .timeCreate {
      padding-top: 25px;
    }
    .inforMation {
      margin: 0;
    }
  }
  .itemSubject {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .subjectContainer {
    .subjectTop {
      margin-top: 0;
      padding-top: 15px;
      padding-bottom: 0;
      border: 1px solid #d7d7d7;
      margin-bottom: 40px;
      h4 {
        margin: 0;
      }
    }
    .itemSubject {
      margin-top: 0;
      border-top: 0;
      padding: 0;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 821px) {
  .thumb {
    img {
      margin-top: -20px;
      width: 60%;
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .containerDetail .shareBlock {
    .headerText {
      margin-bottom: 0;
    }
  }
  .description {
    margin-bottom: 0;
  }
  .hr {
    display: block;
  }
  .subjectFilter {
    margin-left: 0;
  }
  .documentRefer {
    p {
      padding-top: 20px;
      padding-left: 25px;
      position: relative;
      font-size: 14px !important;
    }
    p:before {
      content: ".";
      color: var(--COLOR-ORIGIN);
      font-size: 30px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }
  .sliderBlog {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    padding: 0 !important;
    background: snow;
    border-radius: 0;
  }
  .container {
    max-width: 500px;
    margin: 0 auto;
  }
  .register {
    height: 248px;
    background: var(--COLOR-WHITE);
    border-radius: 12px;
    position: relative;
    .header {
      padding: 43px 15px 15px 15px;
      input {
        margin-top: 15px;
        padding: 7px 10px;
        background: var(--COLOR-WHITE);
        border: 1px solid var(--COLOR-BODER);
        border-radius: 6px;
        width: 100%;
      }
      input::placeholder {
        color: #aab3b9;
        font-weight: 500;
        font-size: 14px;
        font-family: SVN-GilroyLight, sans-serif;
      }
      .buttonSubmit {
        background: var(--COLOR-ORIGIN);
        border-radius: 6px;
        width: 100%;
        padding: 7px 10px;
        margin-top: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: var(--COLOR-WHITE);
      }
    }
    .headerPostNews {
      font-size: 18px;
      line-height: 26px;
      color: var(--COLOR-ORIGIN);
      font-weight: 700;
      font-family: SVN-GilroyBold, sans-serif;
      position: relative;
      img {
        position: absolute;
        top: 20px;
      }
    }
    .registerBackground {
      position: absolute;
      right: 0;
      top: 10%;
    }
  }
  .filter {
    display: block;
    color: var(--COLOR-CONTENT);
    font-size: 16px;
    p:first-child {
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }
  .selectContainer {
    margin: 0;
    border: none;
  }
  .subjectContainer .news {
    display: block;
    .options {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
      .btnFilter {
        justify-content: end;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .btnFilterSubject {
        padding: 8px 15px;
        width: 100%;
        display: flex !important;
        justify-content: space-between;
        background: var(--COLOR-WHITE);
        border: 1px solid var(--COLOR-BODER);
        color: var(--COLOR-ORIGIN);
      }
      .selectOption {
        max-width: 150px;
      }
    }
  }
  .subjectTop {
    display: none;
  }
  .itemSubject {
    display: grid;
    grid-template-columns: auto auto;
    font-size: 13px;
  }
  .containerKnowledge {
    width: 100%;
    padding-top: 0 !important;
    .thumb {
      position: relative;
      left: 3%;
    }
    img {
      width: 70%;
      margin-left: 10%;
    }
  }

  .headerContent {
    padding-top: 0;
  }
  .containerSchoolLevel {
    .schoolLevel {
      margin-top: 30px;
    }
  }

  .containerGradeChild {
    .title {
      padding-bottom: 35px;
    }
  }
  .newPostItem {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .pagination {
    text-align: center;
    padding-top: 0;
    padding-bottom: 40px;
  }
  .label {
    line-height: 0 !important;
  }
  .latestNewsContainer {
    padding-bottom: 0;
    .latestNewsContent {
      flex-direction: column-reverse;
    }
    .img {
      padding-bottom: 35px;
    }
    .LatestNewsTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 10px;
    }
    .description {
      padding: 0;
    }
    .timeCreate {
      padding-top: 25px;
    }
  }
  .newPostContainer {
    padding-top: 0;
    .title {
      padding-top: 100px;
      padding-bottom: 10px;
    }
  }
}

@media (max-width: 668px) {
  .sliderBlog {
    padding-left: 0;
    padding-right: 0;
    background: snow;
  }
  .sharing {
    padding-top: 50px;
  }
}

@media (min-width: 993px) {
  .tableContainer {
    display: block;
    position: sticky;
    max-height: calc(100vh + 40px);
    top: 100px;
    border-radius: 0;
  }
  .containerDetail {
    max-width: 1140px;
    margin: 0 auto;
    .banner {
      .boxImg {
        border-radius: 12px;
        overflow: hidden;
        max-width: 530px;
        max-height: 299px;
        img {
          width: auto;
        }
      }
    }
    .detailAuthor {
      width: 100%;
      padding-left: 20px;
    }
    .content {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
    }
    .timeCreate {
      padding-bottom: 35px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .postNews {
      display: block;
      position: sticky;
      max-height: calc(100vh + 40px);
      top: 100px;
      padding-left: 20px;
      .postNewsTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.4;
        color: var(--COLOR-TITLE);
        font-family: SVN-GilroyBold, sans-serif;
      }
    }
    .register {
      height: 248px;
      background: var(--COLOR-WHITE);
      border-radius: 12px;
      position: relative;
      .header {
        padding: 43px 15px 15px 15px;
        input {
          margin-top: 15px;
          padding: 7px 10px;
          background: var(--COLOR-WHITE);
          border: 1px solid var(--COLOR-BODER);
          border-radius: 6px;
          width: 100%;
        }
        input::placeholder {
          color: #aab3b9;
          font-weight: 500;
          font-size: 14px;
          font-family: SVN-GilroyLight, sans-serif;
        }
        .buttonSubmit {
          background: var(--COLOR-ORIGIN);
          border-radius: 6px;
          width: 100%;
          padding: 7px 10px;
          margin-top: 15px;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: var(--COLOR-WHITE);
        }
      }
      .headerPostNews {
        font-size: 18px;
        line-height: 26px;
        color: var(--COLOR-ORIGIN);
        font-weight: 700;
        font-family: SVN-GilroyBold, sans-serif;
        position: relative;
        img {
          position: absolute;
          top: 20px;
        }
      }
      .registerBackground {
        position: absolute;
        right: 0;
        top: 10%;
      }
    }
    .shareBlock {
      padding-bottom: 20px;
      p {
        justify-content: center;
      }
    }
    .newPostContainer {
      padding-top: 0;
    }
    .folderContent {
      padding-top: 25px;
      display: grid;
      grid-template-columns: 15% 70%;
      cursor: pointer;
      p {
        color: #606e78;
      }
    }
    .folder {
      border: 1px solid var(--COLOR-BODER);
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 20px;
      color: #606e78;
      margin-right: 10px;
      text-align: center;
    }
    .folderContent:hover > .folder {
      border: 1px solid var(--COLOR-ORIGIN);
      transition: 0.3s ease-in-out;
    }
    .folderContent:hover {
      color: var(--COLOR-TITLE);
      transition: 0.3s ease-in-out;
    }
    .success {
      color: var(--COLOR-TITLE);
      .folder {
        background-color: var(--COLOR-ORIGIN);
        color: var(--COLOR-WHITE);
      }
    }
  }
}

@media screen and (max-width: 576px) and (min-width: 320px) {
  .latestNewsContainer {
    padding-top: 32px;
  }
  .containerDetail {
    .banner .boxImg {
      height: 237px;
    }
    .banner {
      img {
        max-height: 100%;
      }
    }
    .timeCreate {
      margin-bottom: 15px;
    }
  }
  .containerKnowledge {
    .itemKnowledge div:first-child {
      margin-left: 12px;
      margin-right: 12px;
    }
    width: 100%;
    padding-top: 0 !important;
    .thumb {
      img {
        width: 100%;
        margin-left: 0;
      }
      left: 0 !important;
    }
  }
  .containerPostOther {
    max-width: 400px;
    margin: 0 auto;
  }
  .headerContent {
    padding-left: 12px;
  }
  .otherKnowContainer {
    .label {
      line-height: 50px !important;
    }
    .router {
      padding-left: 20px;
    }
    .sharing {
      padding-left: 0;
      padding-top: 30px;
      span {
        width: 135px;
      }
    }
    .contentBanner {
      padding-top: 50px;
    }
    .container {
      max-width: fit-content;
      margin: 0;
      justify-content: center;
    }
    .headerContent {
      max-width: initial;
    }
  }

  .sliderBlog {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
    padding: 0 !important;
    background: snow;
    border-radius: 0;
  }
  .sharing {
    font-size: 36px;
    line-height: 48px;
    margin-left: 0 !important;
    padding-left: 20px;
  }
  .schoolLevel {
    margin-top: 0;
    height: 175px;
  }
  .containerGradeChild .title {
    padding-bottom: 10px;
  }
  .thumb {
    img {
      width: 70%;
      margin-left: 10%;
    }
  }
  .itemKnowledge div:first-child {
    margin-bottom: 20px;
  }
  .container {
    max-width: 375px;
    margin: auto;
    justify-content: center;
  }
  .otherKnowContainer .news {
    padding-top: 60px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .latestNewsContainer {
    padding-top: 32px;
  }
  .viewOtherTilte {
    padding-left: 20px;
    .banner img {
      height: 235px;
      border-radius: 12px;
    }
    .inforAuthor {
      margin-top: 30px;
    }
  }
  .tableContainer {
    .contentDetail {
      padding-right: 0 !important;
    }
  }
  .containerDetail {
    .detailAuthor {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .banner {
    padding-left: 15px;
  }
  .containerKnowledge {
    .itemKnowledge div:first-child {
      margin-left: 12px;
      margin-right: 12px;
    }
    width: 100%;
    padding-top: 0 !important;
    .thumb {
      img {
        width: 100%;
        margin-left: 0;
      }
      left: 0 !important;
    }
  }
  .otherKnowContainer {
    .headerContent {
      max-width: 350px;
    }
    img {
      margin-left: 0;
    }
    .label {
      line-height: 50px !important;
    }
    .itemKnowledge {
      position: relative;
    }
    .thumb {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      img {
        width: 70%;
        position: absolute;
        width: 45%;
        right: 22px;
      }
    }
    .router {
      padding-left: 20px;
    }
    .sharing {
      padding-left: 0;
      padding-top: 30px;
      span {
        width: 135px;
      }
    }
    .contentBanner {
      padding-top: 50px;
    }
    .container {
      max-width: fit-content;
      margin: 0;
      justify-content: center;
    }
  }
  .sharing,
  .headerContent {
    margin: 0 !important;
    text-align: left;
  }
  .postNews {
    display: block;
    display: block;
    position: sticky;
    max-height: calc(100vh + 40px);
    top: 100px;
    padding-left: 20px;
    .postNewsTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      color: var(--COLOR-TITLE);
      font-family: SVN-GilroyBold, sans-serif;
    }
  }
  .containerDetail .postNews {
    display: block;
    .postNewsTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      color: var(--COLOR-TITLE);
      font-family: SVN-GilroyBold, sans-serif;
    }
  }

  .sharing {
    font-size: 36px;
    line-height: 30px;
    padding-left: 20px;
  }
  .thumb img {
    margin-top: 2%;
    width: 70%;
    margin-left: 10%;
  }
  .headerContent {
    padding-left: 12px;
  }
  .itemKnowledge {
    flex-wrap: nowrap;
  }
  .containerKnowledge .fontSizeTitle {
    width: 195px;
    font-size: 36px;
  }
  .otherKnowContainer {
    .container {
      p {
        padding-bottom: 30px;
      }
    }
    .label {
      line-height: 50px !important;
    }
  }
}
@media screen and (min-width: 578px) and (max-width: 668px) {
  .thumb {
    width: 70% !important;
  }
}

@media screen and (min-width: 769px) and (min-width: 820px) {
}

@media (width: 768px) {
  .contentItem {
    padding-left: 15px;
  }
}

@media (max-width: 992px) {
  .containerSchoolLevel {
    .schoolLevel {
      margin-top: 35px;
    }
  }
  .GradeChildTitle {
    .title {
      padding-bottom: 5px;
    }
  }
  .detailAuthor {
    .tableContainer {
      hr {
        display: none;
      }
    }
    .folderContent {
      display: flex;
      padding-top: 0.75rem;
      align-items: center;
      span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        margin-right: 30px;
        border: 1px solid var(--COLOR-BODER);
      }
      p {
        flex: 1;
        opacity: 0.8;
      }
    }
    .folderContent:last-child {
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--COLOR-BODER);
    }
    .contentDetail,
    .postNews {
      padding-top: 1rem;
    }
  }
}
