#test-preparation {
  .filter-result {
    margin-top: -25px;
    color: black;
    padding-left: 15px;
    display: none;

    @media (max-width: 668px) {
      display: block;
    }

    .icon {
      color: #ff7707;
      margin-right: 5px;
      display: inline;
    }
  }

  .filter-condition {
    margin-top: 24px;
    padding: 24px 16px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;

    >*+* {
      border-top: 1px solid #eaeced;
      margin-top: 24px;
      padding-top: 20px;
    }

    .filter__item {
      h4 {
        margin-bottom: 4px;
        font: 16px/24px var(--SVN-GilroyBold);
        color: var(--gray);
      }

      label {
        font-family: var(--SVN-GilroyMedium);
        color: var(--black);
        line-height: 22px;
        position: relative;

        input {
          opacity: 0;
          position: absolute;
        }

        input:checked+.checked {
          background: var(--orange) url("../../../edu_lms_v2/assets/img/checked.svg") no-repeat 50%;
          border: none;
        }

        .checked {
          width: 20px;
          height: 20px;
          display: block;
          margin-right: 14px;
          border: 1.5px solid #aab3b9;
          border-radius: 4px;
        }
      }
    }
  }
}