.ModalExcercise {
  background-color: rgba(#000000, 0.7);
  position: fixed;
  inset: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    border: none;
    border-radius: 20px;

    .modal-title {
      font-weight: 700;
      color: #2a404f;
    }

    .modal-body {
      border-bottom: 1px solid #eceeef;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      .body-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .input-title {
          font-weight: 700;
          font-size: 18px;
          color: #2a404f;
        }
        .left-checkbox {
          display: flex;
          flex-direction: column;
          gap: 15px;
          border: 1px solid #eceeef;
          border-radius: 0.25rem;
        }
      }
      .body-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        .right-item {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .input-title {
            font-weight: 700;
            font-size: 18px;
            color: #2a404f;
          }
          //   .input-update {
          //     height: 50px;
          //     width: 85%;
          //     padding: 10px;
          //     border: 1px solid #eceeef;
          //     border-radius: 0.25rem;
          //   }
          .right-checkbox {
            display: flex;
            align-items: center;
            gap: 40px;
            // .form-check {
            //   display: flex;
            //   align-items: center;
            //   gap: 12px;
            //   .form-check-input {
            //     width: 20px;
            //     height: 20px;
            //   }
            // }
          }
        }
        .body-select {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 20px;
          .input-title {
            font-weight: 700;
            font-size: 18px;
            color: #2a404f;
          }
          .select-time {
            width: 120px;
          }
        }
      }
    }
    .modal-footer {
      border: none;
      padding: 36px 0;
      justify-content: center;
      .submit-btn {
        background-color: #ff7707;
        border: none;
        padding: 10px 36px;
        font-weight: 700;
        border-radius: 10px;
      }
    }
  }
}

.modal-lg,
.modal-xl {
  justify-content: center;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
  .ModalExcercise
    .modal-content
    .modal-body
    .body-right
    .right-item
    .right-checkbox {
    display: block;
  }
}
body {
  .checkbox-tree {
    .rct-node-icon {
      display: none;
      padding: 0 !important;
    }
    .rct-icons-fa4 .rct-icon-check::before {
      content: "\f046";
      font-size: 18px;
    }
    .rct-icons-fa4 .rct-icon-uncheck::before {
      content: "\f096";
      font-size: 18px;
    }
  }
  .react-checkbox-tree ol {
    max-height: 200px;
    overflow-y: auto;
  }
}

// .datepicker {
//   width: 80%;
//   padding: 6px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   color: #2a404f;
// }

.react-datepicker-wrapper input {
  width: 70%;
}

.calender-icon {
  pointer-events: none;
  position: absolute;
  top: 55px;
  right: 130px;
  border-left: 1px solid #ccc;
  height: 22px;
}
