.popup_pdf {
  .pagination {
    li {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #d7d7d7;
      margin: 0 3px;
      cursor: pointer;
      overflow: hidden;
      a {
        outline: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    > .active {
      background-color: #ff7707;
      color: #fff;
    }
  }
  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  .modal.show .modal-dialog,
  .popup_question .modal-content {
    height: 100%;
    margin: 0 auto;
  }

  .modal.show .modal-dialog {
    transform: none;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translateY(-50px);
  }
  .modal-dialog.popup_pdf {
    margin: auto;
    display: inherit;
    align-items: inherit;
    height: 100%;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
  }
  .modal-dialog.popup_pdf .modal-content {
    height: 100%;
    overflow-y: scroll;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .react-pdf__Page__textContent {
    max-width: 100%;
  }
  .react-pdf__Page__canvas {
    display: block;
    user-select: none;
    width: 841px;
    height: 595px;
  }
  .pagination li {
    margin: 0 3px;
  }
  h5 {
    font-size: 1.25rem;
    color: #ff7707;
    .monkey-f-header {
      color: #ff7707;
    }
  }
  .modal-body {
    padding: 0;
  }

  @media screen and (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
    .popup_pdf {
      max-width: 100%;
    }
  }
  .pagination .previous.disabled {
    background-color: #d7d7d7;

    i {
      color: #151515;
    }
  }
  .monkey-color-blue {
    color: #ff7707;
  }
}
@media screen and (min-width: 576px) {
  .popup_pdf {
    max-width: 100% !important;
  }
}
.modal-dialog.popup_pdf .modal-content {
  height: 100%;
  overflow-y: scroll;
}
.modal-content::-webkit-scrollbar {
  width: 8px;
}
.modal-content::-webkit-scrollbar-thumb {
  background-color: #ff7707 !important;
  border-radius: 5px;
}
.totalbook {
  font-size: 18px;
  color: black;
}
