.text_hidden {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
@media(max-width:668px){
    .text_hidden {
        display: block;
    }
}