.SROI_001__container {
  font-size: 20px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 15px;
  padding-bottom: 30px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
  }

  .content__wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    .col-left, .col-right {
      width: 50%;
    }
  }
}
