.header__text-wrapper {
  ol li {
    margin-left: 40px;
  }
}

.header__audio-wrapper {
  width: 35%;
  margin: auto;
  text-align: center;
}