.input-points__description {
  max-width: 450px;
}

.point {
  padding-top: 15px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  label {
    margin-right: 5px;
  }
  .symbol {
    font-size: 30px;
  }
  input {
    width: 100px;
    height: 50%;
    margin: 0 10px;
    margin-bottom: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}