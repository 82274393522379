.DAD_wrapper {
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;

  .game-content__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;

    .answer-content__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: auto;
      margin-bottom: 20px;

      @media screen and (min-width: 992px) {
        padding: 0 30px;
      }
    }

    .question-content__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      flex: 1;
    }
  }
}
