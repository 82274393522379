.text-component__latex-wrapper {
    display: inline;

    div {
        display: inline !important;
    }
    div:global(#preview) {
        padding: 8px !important;
    }
    ol {
        margin-left: 20px;
        display: inline-block;
    }
}
