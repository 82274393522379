.bGRNotSeen {
  background-color: #fff5ee;
}
.fs20 {
  font-size: 20px;
}
.widthTitle {
  width: 65%;
}
.widthTime {
  width: 25%;
}
.seenIcon {
  color: #ff7707;
  width: 5%;
  font-size: 12px;
}
.contentNoti {
  padding: 1.2rem 3rem 1rem 4rem;
  background-color: #f3f4f5;
}
.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  z-index: 999;
}
