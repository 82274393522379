.math-input__container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .answer__wrapper {
    padding-left: 10px;

    .list-answer-item__wrapper {
      .answer-input {
        display: inline-block;
        border-radius: 5px;
        padding: 10px 10px;
        background: #ffff;
        margin-right: 10px;
      }
    
      :global(#content) {
        display: inline;
    
        div {
          display: inline !important;
        }
      }
    }
  }
}

.math-input__container.read-only {
  pointer-events: none;
}