.graph-002-container {
  .header-title {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 10px;
    }
  }

  .content {
    position: relative;
    margin-top: 48px;
    margin-left: 48px;
  }
  .content.read-only {
    pointer-events: none;
  }
  .content.has-board {
    min-height: 400px;
  }
}
