.animated {
  padding-bottom: 15px;
}

.header__audio-wrapper {
  margin-right: 20px;
}

.header__text-wrapper {
  display: flex;
  align-items: center;

  ol {
    padding-left: 20px;
    li:first-child {
      margin-left: 20px;
    }
  }
  ul {
    margin-left: 35px;
  }
}

.flex-column-revert {
  flex-direction: column-reverse;
}

.label-answer-index__wrapper {
  padding: 6px;
}