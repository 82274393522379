.present-modal {
  width: 100vw !important;
  max-width: 100vw !important;
  overflow: hidden;
}

@media (max-width: 668px) {
  .hoc10-slideshow .btn-back .text {
    display: block !important;
  }
}
