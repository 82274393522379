.FIB_003__wrapper {
  height: 85%;
  overflow-y: auto;

  .sentence__wrapper {
    align-items: center;

    .select__value-container {
      min-height: 35px
    }
    .select__input {
      color: transparent;
    }
    .select-custom-option {
      cursor: pointer;
      :hover {
        font-weight: bold;
        color: black;
      }
    }
  }
}

.FIB_003__wrapper.is-view {
  pointer-events: none;
}