.FIB_BG__wrapper {
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;

  .question-audio__wrapper {
    margin: 0 auto;
    width: max-content;
  }

  .image__wrapper {
    height: auto;
    position: relative;

    .form-inputs {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5;
    }
    .form-inputs.view-only {
      pointer-events: none;
    }
  }
}
