@media screen and (min-width:768px) and (max-width:1024px){
 .loginList a {
        width: 230px !important;
        height: 44px;
        margin: 0 8px;
        border-radius: 4px;
        color: #fff;
    }
}
.loginList a {
    width: 260px;
    height: 44px;
    max-width: calc(33.33% - -30px);
    margin: 0 8px;
    border-radius: 4px;
    color: #fff;
}
.facebook{
    background: #1877f2
}
.google{
    background: #de4d3b
}

