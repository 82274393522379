$header-height: 72px;

.page-container {
  min-height: 100vh;
  padding-top: $header-height;
  max-width: 1326px;
  margin: auto;
}

#hoc10-test-game {
  padding: 20px;
  padding-bottom: 0;

  .title {
    font-weight: bold;
    font: 54px/64px var(--SVN-GilroyBold);
    text-align: center;
    color: var(--orange);
    margin-bottom: 20px;
  }

  .filter-game {
    position: relative;
    border: 3px solid var(--orange);
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 0 0 20px;
    background-color: #fffbf7;

    .wrapper-item {
      display: flex;
      flex-direction: row;
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 30px;
      .form-label {
        margin-right: 10px;
        margin-bottom: 0;
        color: black;
        font-weight: bold;
      }
    }

    .select-game, .select-school-level, .select-subject {
      width: 350px;
    }
    .select-grade {
      width: 150px;
    }
    .select-amount-acts {
      width: 100px;
    }
    .select-book {
      width: 300px;
    }
    .is-public {
      font-weight: bold;
      color: black;
      #public {
        margin-top: 3px;
      }
    }
    .input-name {
      width: 455px;
    }
    .mode-view__note {
      color: red;
      font-style: italic;
      opacity: 0.7;
    }

    button {
      min-width: 150px;
      position: absolute;
      top: 10px;
      left: 790px;
      background-color: var(--orange);
      border-color: var(--orange);
    }
  }

  .view-games {
    height: calc(100vh - $header-height);
    overflow: auto;
    background-color: #ffeede;
    padding-top: 20px;
    border: 3px solid var(--orange);
    border-top: none;

    .game-wrapper.book {
      max-width: 1106px;
    }
    .game-wrapper.question-set {
      max-width: 866px;
    }
    .game-wrapper {
      position: relative;
      border-radius: 0.5rem;
      height: calc(100% - 20px);
      margin: auto;
      padding-top: 30px;
      margin-bottom: 20px;
      background-color: white;

      .number-order {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100px;
        height: 30px;
        padding: 0 5px;
        background-color: var(--orange);
        color: white;
        opacity: 0.5;
        border-radius: 0 0 10px 0;
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          margin-right: 3px;
        }
        .copy-icon {
          cursor: pointer;
          margin-top: -1px;
        }
      }
    }
  }
}
