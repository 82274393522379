.Intro {
  p {
    display: block;
    padding: 6px;
    text-align: center;
    width: 100%;
    max-width: 50%;
  }

  p:nth-child(1),
  p:nth-child(2) {
    border-bottom: 1px solid #eaeced;
  }

  p:nth-child(1),
  p:nth-child(3) {
    border-right: 1px solid #eaeced;
  }
}
